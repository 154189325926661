/**
 * @file validationSchema.ts Yup validation schema for SignIn
 * @author Harry Rhodes
 * @exports Yup.object
 */
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  username: Yup.string()
    .email("Must be a valid email address")
    .required("Required"),
  password: Yup.string()
    .required("Required"),
});
