/**
 * @file Copyright.tsx
 * @description Copyright component
 * @author Harry Rhodes
 * @exports React.Component
 */
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
/**
 * Renders Copyright component
 * @returns {React.Component} Copyright component
 */
export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"v" + process.env.REACT_APP_VERSION + " | Copyright © "}
      <Link color="inherit" href="https://vodafone.com/">
        Vodafone Group
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
