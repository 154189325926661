/**
 * @file validationSchema.ts Form input validation for the reports form
 * @author Christian Vasold
 * @exports validationSchema
 */
import * as Yup from 'yup';
import { yupForReportEndDate, yupForRequiredStartDate } from '../../../utils/validationUtils';

export const validationSchema = Yup.object().shape({
  from: yupForRequiredStartDate,
  to: yupForReportEndDate,
  type: Yup
    .string()
    .oneOf(["raw", "aggregate"])
    .required("Please select type"),
  levelOfDetail: Yup
    .string()
    .oneOf(["global", "partners", "brands", "campaigns", "numbers"])
    .required("Please select the level of detail"),
  callAnalytics: Yup
    .boolean()
    .required()
});