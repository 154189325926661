/**
 * @file OnLoad.tsx Component for displaying page loading
 * @author Harry Rhodes
 * @exports React.Component
 */
import WithNav from "..";
import OnLoad from "../../feedback/OnLoad";
/**
 * Renders OnLoad wrapper component
 * @returns {React.Component} OnLoad wrapper component
 */
export default function OnLoadWithNav() {
  return (
    <WithNav>
      <OnLoad/>
    </WithNav>
  );
}
