
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  exportChartButton: {
    textAlign: "right",
  }
}));

export default useStyles;
