/**
 * @file NoRolesAssignedRoutes.tsx
 * @description Defines routes for an User with no Cognito roles assigned.
 * @requires react-router-dom
 * @author Rafael Mello
 * @exports NoRolesAssigned
 */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OnErrorWithNav from "../../../templates/WithNav/OnErrorWithNav";
import NotFound from "../../../../../pages/error/NotFound";

export default function NoRolesAssignedRoutes() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route 
          path="/" 
          element={
            <OnErrorWithNav 
              error={
                new Error("You do not have permission to access CallerID anymore. All roles have been revoked from your user account.")
              } 
            />
          }
        />
        <Route path="*" element={<NotFound authenticated={true} />} />
      </Routes>
    </BrowserRouter>
  );
}
