/**
 * @file useUserInfo.ts Custom hook to parse AWS cognito permissions into useable front-end attributes
 * @requires aws-amplify
 * @author Harry Rhodes
 */
import { useEffect, useState } from "react";
import { Analytics, Auth } from "aws-amplify";
import opcoService from "../services/opcoService";
import partnerService from "../services/partnerService";
import brandService from "../services/brandService";
import { parseGroupName } from "../utils/utils";

/**
 * Checks if a user can assume a certain role
 * @param {string} group - group name which the user is trying to assume.
 * @param {string[]} groups - List of allowed groups retrieved from AWS Amplify.
 * @returns {boolean} whether the group is allowed
 */
function checkGroup(group: string, groups: string[]): boolean {
  return groups.includes(group);
}

/**
 * @params None
 * @returns {number, number, number, string, string, string, string, function, string, boolean}
 * ids of opco, partner, brand. Alias' of opco, partner, brand. Set role function. Loading boolean
 */
export function useUserInfo() {
  const [opcoId, setOPCOId] = useState<string>();
  const [partnerId, setPartnerId] = useState<string>();
  const [brandId, setBrandId] = useState<string>();
  const [opcoAlias, setOPCOAlias] = useState<string>();
  const [partnerAlias, setPartnerAlias] = useState<string>();
  const [brandAlias, setBrandAlias] = useState<string>();
  const [username, setUsername] = useState<string>("");
  const [role, setRole] = useState<string>("null");
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>();
  /**
   * Sets current role in memory as well as local storage
   * @param {string} group - group name to be set as current role
   */
  function setCurrentRole(group: string) {
    let role = parseGroupName(group);
    setRole(role); //Set role in state

    // Set group in local storage.
    // We use local storage (and not credentials storage) here,
    // because we deliberately want this to survive logout and session closure.
    // If the user is not eligible again for this group after login, the select group will be ignored.
    localStorage.setItem("selectedGroup", group);
  }
  useEffect(() => {
    if (role === "null") {
      getUserInfo();
    }
    async function getUserInfo() {
      const { signInUserSession } = await Auth.currentAuthenticatedUser();
      const idTokenPayload = signInUserSession.idToken.payload;
      const groups = idTokenPayload["cognito:groups"] as string[];

      // Try to pick the currently selected group
      const selectedGroup = localStorage.getItem("selectedGroup");
      const group =
        (selectedGroup !== null && groups && checkGroup(selectedGroup, groups))
          ? selectedGroup
          // If the selected group is not acceptable, pick the first available group.
          // TODO If there are multiple groups, let the user select one.
          : groups ? groups[0] : "";

      const role = parseGroupName(group);
      const username = idTokenPayload["cognito:username"];
      const email = idTokenPayload["email"];

      let opcoId, partnerId, brandId;

      const split = group.split("-");
      switch (role) {
        case "opco-admin":
          setOPCOAlias(split[0]);
          opcoId = await opcoService.getId(split[0]);
          break;
        case "aggregator-admin":
          //TODO: Implement aggregator role
          break;
        case "partner-manager":
          setPartnerAlias(split[0]);
          partnerId = await partnerService.getId(split[0]);
          break;
        case "brand-manager":
        case "brand-reporter":
          setPartnerAlias(split[0]);
          setBrandAlias(split[1]);
          [partnerId, brandId] = await Promise.all([
            partnerService.getId(split[0]),
            brandService.getId(split[1])]);
          break;
      }

      setOPCOId(opcoId);
      setPartnerId(partnerId);
      setBrandId(brandId);
      setCurrentRole(group);
      setUsername(username);
      setEmail(email);
      setLoading(false);

      // record event for every page visit with cognito details
      Analytics.record({
        name: "cognitoEvent",
        attributes: {
          cognitoUsername: username,
          group: group,
          href: window.location.href,
        },
        immediate: true,
      });
    }
  }, []);
  return {
    opcoId,
    partnerId,
    brandId,
    opcoAlias,
    partnerAlias,
    brandAlias,
    role,
    setCurrentRole,
    username,
    loading,
    email,
  };
}
