/**
 * @file reportService.ts client for interacting with backend API to retrieve reports
 * @requries ./axiosClient
 * @author Christian Vasold
 */

import { ApiCallParams } from "../components/Reports/ReportsForm/ReportsForm";
import { client } from "./axiosClient";
import { Headers } from "react-csv/lib/core";

/**
 * Model class for a Report returned by the backend
 */
export interface ReportData {
    headers: Headers,
    rows: ReportRow[]
}

/**
 * Model class for a Report line returned by the backend
 */
export interface ReportRow {
    partnerAlias?: string,
    partner?: string,
    brandAlias?: string,
    brand?: string,
    campaign?: string,
    callerNumber?: string,
    date?: string,
    count?: string,
    pickUpRate?: string,
    averageCallDuration?: string,
    answered?: string,
    busy?: string,
    notAnswered?: string,
    cancelled?: string,
    durationDB0Count?: string,
    durationDB0Percentage?: string,
    durationDB2Count?: string,
    durationDB2Percentage?: string,
    durationDB10Count?: string,
    durationDB10Percentage?: string,
    durationDB30Count?: string,
    durationDB30Percentage?: string,
    durationDB60Count?: string,
    durationDB60Percentage?: string,
    durationDB120Count?: string,
    durationDB120Percentage?: string,
    durationDB180Count?: string,
    durationDB180Percentage?: string,
    durationDB300Count?: string,
    durationDB300Percentage?: string,
    durationDB600Count?: string,
    durationDB600Percentage?: string,
    durationDB1200Count?: string,
    durationDB1200Percentage?: string,
}

const service = {
    getAll: async (params: ApiCallParams): Promise<ReportData> => {
        let res = await client.get("/reports/all", {
            params: {
                from: params.from,
                to: params.to,
                type: params.type,
                levelOfDetail: params.levelOfDetail,
                callAnalytics: params.callAnalytics
            }
        });
        return res.data;
    },
    getByPartnerId: async (params: ApiCallParams): Promise<ReportData> => {
        let res = await client.get("/reports/partner/" + params.id , {
            params: {
                from: params.from,
                to: params.to,
                type: params.type,
                levelOfDetail: params.levelOfDetail,
                callAnalytics: params.callAnalytics
            },
        });
        return res.data;
    },
    getByBrandId: async (params: ApiCallParams): Promise<ReportData> => {
        let res = await client.get("/reports/brand/" + params.id, {
            params: {
                from: params.from,
                to: params.to,
                type: params.type,
                levelOfDetail: params.levelOfDetail,
                callAnalytics: params.callAnalytics,
                excludeReferenceCampaigns: params.excludeReferenceCampaigns
            }
        });
        return res.data;
    },
};

export default service;