/**
 * @file styles.ts Defines CSS styles for AssetCard
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 2.5px 5px 0px #949698",
    [theme.breakpoints.between("xs", "md")]: {
      maxWidth: "550px",
      maxHeight: "400px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      maxWidth: "300px",
      maxHeight: "300px",
    },
    [theme.breakpoints.between("md", "xl")]: {
      maxWidth: "500px",
      maxHeight: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "500px",
      maxHeight: "250px",
    },
  },
  cardImageWrapper: {
    height: "100%",
    width: "100%",
    alignSelf: "center",
    overflow: "hidden",
  },
  cardImage: {
    height: "100%",
    width: "100%",
    alignSelf: "center",
    overflow: "hidden",
    objectFit: "cover",
  },
  cardContent: {
    flexGrow: 1,
  },
}));

export default useStyles;
