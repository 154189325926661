/**
 * @file Title.tsx Title component
 * @author Harry Rhodes
 * @exports React.Component
 */
import Typography from "@mui/material/Typography";
/**
 * @typedef Props
 * @property {string} children - Text for title
 **/
interface Props {
  children: string;
}
/**
 * Renders Title component
 * @param children {string}
 * @returns {React.Component} Title component
 */
const Title = (props: Props) => (
  <Typography component="h2" variant="h6" color="primary" gutterBottom>
    {props.children}
  </Typography>
);

export default Title;
