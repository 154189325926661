import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import BulkUploadProgress, { BulkUploadError, BulkUploadSummary } from "../../../../MSISDNs/BulkUploadProgress/BulkUploadProgress";
import { RotatingLines } from "react-loader-spinner";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  bulkUploadSummary: BulkUploadSummary;
  bulkUploadErrors: BulkUploadError[];
  bulkUploadFinalMessage: string;
}

export default function BulkUploadDialog(props: Props) {
  const { open, setOpen, bulkUploadSummary, bulkUploadErrors, bulkUploadFinalMessage } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Bulk Upload Progress`}
      </DialogTitle>
      <DialogContent>
        <BulkUploadProgress summary={bulkUploadSummary} errors={bulkUploadErrors} />
        { bulkUploadFinalMessage
        ? <Typography marginTop="1em">
            {bulkUploadFinalMessage}
          </Typography>
        : <Typography marginTop="1em">
            <RotatingLines
              strokeColor="green"
              strokeWidth="5"
              animationDuration="0.75"
              width="25"
              visible={true}
            /> Upload in progress...
          </Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
