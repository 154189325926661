/**
 * @file BulkUploadProgress.tsx
 * @description BulkUploadProgress component to show the results of a Bulk Upload operation
 * @author Rafael Mello
 */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import useStyles from "../common/style";

export type BulkUploadSummary = {
  newNumbers: number,
  updatedNumbers: number,
  unchangedNumbers: number,
  newCampaigns: number;
  updatedCampaigns: number;
  unchangedCampaigns: number
}

export type BulkUploadRow = {
  campaignName?: string,
  campaignDisplayName?: string,
  campaignDescription?: string,
  campaignReferenceCampaign?: string,
  pai?: string,
  from?: string,
  excelRowNumber?: number
}

export type BulkUploadError = {
  row: BulkUploadRow
  errorMsg: string
}

/**
 * Creates a generic BulkUploadError with all the defined fields:
 * BulkUploadRow and error message.
 */
export function newBulkUploadError(errorMsg: string, row: BulkUploadRow): BulkUploadError {
  return {
    errorMsg: errorMsg,
    row: row
  }
}

/**
 * Creates a BulkUploadError derived from invalid (merged) Campaign properties.
 * Number fields and row number are not defined in this type of error,
 * since there is no way to know which row is causing the issue.
 */
export function newCampaignValidationError(errorMsg: string, campaignName: string, campaignProperties: CampaignProperties): BulkUploadError {
  return {
    errorMsg: errorMsg,
    row: {
      campaignName: campaignName,
      campaignDisplayName: campaignProperties.campaignDisplayName,
      campaignDescription: campaignProperties.campaignDescription,
      campaignReferenceCampaign: campaignProperties.campaignReferenceCampaign,
    }
  }
}

export type CampaignProperties = {
  campaignDisplayName?: string,
  campaignDescription?: string,
  campaignReferenceCampaign?: string
}

/**
 * Set the value of campaign properties contained in a row to existing properties.
 * 
 * A field can only change value if it is undefined.
 * Throw error if there is a new value for an already defined field.
 */
export function setCampaignProperties(properties: CampaignProperties, row: BulkUploadRow) {
  for (const key of Object.keys(properties)) {
    const propKey = key as keyof CampaignProperties;
    const property = properties[propKey];
    const newProperty = row[propKey];
    if (newProperty) {
      if (property && property !== newProperty) {
        throw new Error(
          "Campaign named “" + row.campaignName + "” cannot have a property with two different non-empty values: “" + 
          property + "” and “" + newProperty + "”"
        );
      }
      properties[propKey] = newProperty;
    }
  }
}

interface Props {
  summary: BulkUploadSummary,
  errors: BulkUploadError[]
}

export default function BulkUploadProgress(props: Props) {
  const classes = useStyles();
  return (
    <>
      <Table className={classes.bulkUploadSummary}>
        <TableBody>
          <TableRow
            key="newNumbers"
          >
            <TableCell>New Numbers</TableCell>
            <TableCell>{props.summary.newNumbers}</TableCell>
          </TableRow>
          <TableRow
            key="updatedNumbers"
          >
            <TableCell>Updated Numbers</TableCell>
            <TableCell>{props.summary.updatedNumbers}</TableCell>
          </TableRow>
          <TableRow
            key="unchangedNumbers"
          >
            <TableCell>Unchanged Numbers</TableCell>
            <TableCell>{props.summary.unchangedNumbers}</TableCell>
          </TableRow>
          <TableRow
            key="newCampaigns"
          >
            <TableCell>New Campaigns</TableCell>
            <TableCell>{props.summary.newCampaigns}</TableCell>
          </TableRow>
          <TableRow
            key="updatedCampaigns"
          >
            <TableCell>Updated Campaigns</TableCell>
            <TableCell>{props.summary.updatedCampaigns}</TableCell>
          </TableRow>
          <TableRow
            key="unchangedCampaigns"
          >
            <TableCell>Unchanged Campaigns</TableCell>
            <TableCell>{props.summary.unchangedCampaigns}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      { props.errors.length > 0 &&
        (
          <Table className={classes.bulkUploadErrorTable}>
            <TableHead>
              <TableRow>
                <TableCell>PAI</TableCell>
                <TableCell>From</TableCell>
                <TableCell>Campaign Name</TableCell>
                <TableCell>Campaign Display Name</TableCell>
                <TableCell>Campaign Description</TableCell>
                <TableCell>Reference Campaign</TableCell>
                <TableCell>Error</TableCell>
                <TableCell>Row Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.errors.map((error) => (
                <TableRow
                  key={(error.row.campaignName || "") + (error.row.pai || "") + (error.row.from || "") + error.errorMsg}
                >
                  <TableCell>{error.row.pai}</TableCell>
                  <TableCell>{error.row.from}</TableCell>
                  <TableCell>{error.row.campaignName}</TableCell>
                  <TableCell>{error.row.campaignDisplayName}</TableCell>
                  <TableCell>{error.row.campaignDescription}</TableCell>
                  <TableCell>{error.row.campaignReferenceCampaign}</TableCell>
                  <TableCell>{error.errorMsg}</TableCell>
                  <TableCell>{error.row.excelRowNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )
      }
    </>
  );
}
