import { Fragment, useState } from "react";
import {
  Button,
  Avatar,
  Typography,
  Link,
  Grid,
} from "@mui/material";
import { Formik, Form } from "formik";
import FieldFW from "../../common/templates/forms/FieldFW";
import AlertSnackback from "../../common/templates/feedback/AlertSnackbar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { validationSchema } from "./validationSchema";
import useStyles from "../styles";
import { Auth } from "aws-amplify";
import { credentialsStorage } from "../../../utils/credentialStorage";
import { extractErrorMessage } from "../../../utils/utils";

interface SignInType {
  username: string;
  password: string;
}

interface Props {
  setActiveStep: (step: number) => void;
  setUser: (user: any) => void;
  setVerify: (state: boolean) => void;
  setUsername: (username: string) => void;
}
export default function SignInForm(props: Props) {
  const classes = useStyles();
  const { setActiveStep, setUser, setVerify, setUsername } = props;
  const [msg, setMsg] = useState("");
  const [error, setError] = useState(false);

  async function signIn(username: string, password: string) {
    try {
      const user = await Auth.signIn(username, password);
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        setUser(user);
        setActiveStep(1);
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(user);
        setUsername(username);
        setVerify(false);
        setActiveStep(3);
      } else if (user.challengeName === "MFA_SETUP") {
        setUser(user);
        setUsername(username);
        setActiveStep(4);
      } else {
        setUser(user);
      }
    } catch (err: any) {
      switch (err.code) {
        case "UserNotConfirmedException": {
          setMsg("User not confirmed. Contact system admin");
          setError(true);
          break;
        }
        case "PasswordResetRequiredException": {
          setMsg("Your password must be reset. Please follow the steps.");
          setVerify(true);
          setUsername(username);
          setActiveStep(3);
          break;
        }
        case "NotAuthorizedException":
        case "UserNotFoundException": {
          setMsg("Username or password is incorrect");
          setError(true);
          break;
        }
        case "CodeMismatchException": {
          setMsg("Invalid MFA code");
          setError(true);
          break;
        }
        case "UserLambdaValidationException": {
          // The prefix "PreAuthentication failed with error " comes from Cognito and we only want to display the message from our lambda.
          setMsg(err.message.replaceAll("PreAuthentication failed with error ", ""));
          setError(true);
          break;
        }
        default: {
          setMsg(extractErrorMessage(err));
          setError(true);
          break;
        }
      }
    }
  }
  return (
    <Fragment>
      <div>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
      </div>
      {credentialsStorage.removeItem("username")}
      <Formik
        validateOnChange={true}
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (data: SignInType, { setSubmitting }) => {
          setSubmitting(true);
          await signIn(data.username.toLowerCase(), data.password);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FieldFW
                  type="text"
                  required
                  id="username"
                  name="username"
                  placeholder="Username"
                  variant="outlined"
                  submitForm={() => submitForm()}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldFW
                  type="password"
                  required
                  id="password"
                  name="password"
                  placeholder="Password"
                  variant="outlined"
                  submitForm={() => submitForm()}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => submitForm()}
                  disabled={isSubmitting}
                >
                  Sign In
                </Button>
                <Link 
                  variant="body2"
                  className={classes.forgot}
                  onClick={() => setActiveStep(2)}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </Fragment>
  );
}
