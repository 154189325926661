import { Typography } from "@mui/material";
import userService, { UsersGroup } from "../../../services/userService";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { invalidateUserQueries } from "../../../utils/invalidateQueries";
import SearchFieldToolbar from "../../common/DataGrid/SearchFieldToolbar";
import { sxProps } from "../../OPCOs/OPCOsCardTable/styles";
import useStyles from "./styles";
import { UserType } from "../../../context/UserContext";

interface Props {
  data: UsersGroup[];
  currentUser: UserType;
  showSubRoles: boolean;
  setShowSubRoles: (s: boolean) => void;
  setSuccessMessage: (s: string) => void;
  setErrorMessage: (s: string) => void;
}

export default function UsersTable(props: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const queryClient = useQueryClient();
  const { mutateAsync: revokeRoles } =
    useMutation((ids: string[]) => {
      const revokeRoleCalls: {userId: string, role: string}[] = [];
      ids.forEach((id) => {
        // id in format "e63352e1-71c4-4020-9004-31e5cad56099,partnerAlias-brandAlias-brand-manager"
        const split = id.split(",");
        const rowUserId = split[0];
        const rowRole = split[1];
        // do not allow revoking current role
        if (props.currentUser.username === rowUserId && rowRole.endsWith(props.currentUser.role)) {
          throw new Error("Cannot revoke current role");
        }
        revokeRoleCalls.push({userId: rowUserId, role: rowRole});
      })
      return Promise.all(revokeRoleCalls.map((call) => userService.revokeRole(call.userId, call.role)));
    }, {
      onSuccess: (results) => {
        props.setSuccessMessage("Revoked " + results.length + " Role(s)!");
      },
      onSettled: () => {
        invalidateUserQueries(queryClient);
      }
    });

  const handleClick = (username: string) =>
    navigate(location.pathname + "/" + username, {
      state: location.state,
    });

  if (props.data.length === 0) return <Typography marginTop="1em">No Users</Typography>;

  const columns: GridColDef[] = [
    { field: 'email', headerName: 'E-Mail', width: 350 },
    { field: 'role', headerName: 'Role', width: 300 },
    { field: 'enabled', headerName: 'Enabled?', width: 250 },
    { field: 'userStatus', headerName: 'User Status', width: 145 }
  ];

  const rows: any[] = [];

  props.data.map((userGroup) => {
    return userGroup.groupUsers.map((user) => {
      rows.push({
        // There might be multiple rows for the same user, so we need "+ user.groupName" in "id".
        // It is safe to use "," as separator, because "," is not allowed in an alias and therefore cannot be contained in a group name.
        id: user.Username + "," + userGroup.groupName,
        userId: user.Username, // we need user.Username alone for handleClick()
        email: user.Attributes.find(e => e.Name === "email")?.Value,
        role: userGroup.prettyGroupName,
        enabled: user.Enabled === true ? "Yes" : "No",
        userStatus: user.UserStatus
      })
    })
  });

  const role = props.currentUser.role;
  const canDelete: boolean = role === "vodafone-admin" || role === "partner-manager" || role === "brand-manager" || role == "opco-admin";

  return (
    <DataGrid className={classes.datagrid}
      // Style for search toolbar
      sx={sxProps.datagridToolbar}
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
      checkboxSelection={canDelete}
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
        // Sort by email to group data by user
        sorting: {
          sortModel: [{ field: 'email', sort: 'asc' }],
        },
      }}
      pageSizeOptions={[10, 20, 50, 100]}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      onRowClick={(row) => {handleClick(row.row.userId)}}
      slots={{
        toolbar: SearchFieldToolbar,
      }}
      slotProps={{
        toolbar: {
          alertDialogTitle: "Are you sure you want to revoke the selected Role(s)?",
          alertDialogContent: "This operation only revokes the selected roles from the selected users. The users are not deleted.",
          rowSelection: rowSelectionModel,
          deleteFunction: canDelete ? revokeRoles : undefined,
          setErrorMessage: props.setErrorMessage,
          showSubRoles: props.showSubRoles,
          // only show toggle for vodafone-admin and partner-manager
          setShowSubRoles: role === "vodafone-admin" || role === "partner-manager" ? props.setShowSubRoles : undefined,
        },
      }}
    />
  );
}
