/**
 * @file App.tsx Sets global application routing
 * @requries material-ui, aws-amplify, @aws-amplify/ui-react
 * @author Harry Rhodes
 */
import { useState, useEffect, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Backdrop, CircularProgress, Theme } from "@mui/material";

import { useUserInfo } from "./hooks/useUserInfo";
import VFAdminRoutes from "./components/common/routing/routes/VFAdminRoutes";
import PartnerManagerRoutes from "./components/common/routing/routes/PartnerManagerRoutes";
import BrandManagerRoutes from "./components/common/routing/routes/BrandManagerRoutes";
import BrandReporterRoutes from "./components/common/routing/routes/BrandReporterRoutes";
import { Storage } from "aws-amplify";
import OPCOAdminRoutes from "./components/common/routing/routes/OPCOAdminRoutes";
import { UserContext, UserType } from "./context/UserContext";
import NoRolesAssignedRoutes from "./components/common/routing/routes/NoRolesAssignedRoutes/NoRolesAssignedRoutes";
import VFReporterRoutes from "./components/common/routing/routes/VFReporterRoutes";

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: "flex" },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function App() {
  const classes = useStyles();
  const [routes, setRoutes] = useState<React.ReactNode>();
  const {
    opcoId,
    opcoAlias,
    partnerId,
    partnerAlias,
    brandId,
    brandAlias,
    role,
    username,
    loading,
    email,
  } = useUserInfo();
  const [user, setUser] = useState<UserType>({
    loading: true,
    role: "null",
  });
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);
  useEffect(() => {
    const getRoutes = () => {
      if (!loading) {
        let routes;
        if (role === "vodafone-admin") {
          setUser({
            ...user,
            username: username,
            loading: false,
            role: role,
            email: email,
          });
          routes = (
            <UserContext.Provider value={value}>
              <VFAdminRoutes />
            </UserContext.Provider>
          );
        } else if (role === "vodafone-reporter") {
          setUser({
            ...user,
            username: username,
            loading: false,
            role: role,
            email: email,
          });
          routes = (
            <UserContext.Provider value={value}>
              <VFReporterRoutes />
            </UserContext.Provider>
          );
        } else if (role === "opco-admin") {
          setUser({
            ...user,
            username: username,
            loading: false,
            role: role,
            opcoId: opcoId,
            opcoAlias: opcoAlias,
            email: email,
          });
          routes = (
            <UserContext.Provider value={value}>
              <OPCOAdminRoutes opcoId={opcoId as string} />
            </UserContext.Provider>
          );
        } else if (role === "partner-manager") {
          setUser({
            ...user,
            username: username,
            loading: false,
            role: role,
            partnerId: partnerId,
            partnerAlias: partnerAlias,
            email: email,
          });
          routes = (
            <UserContext.Provider value={value}>
              <PartnerManagerRoutes partnerId={partnerId as string} />{" "}
            </UserContext.Provider>
          );
        } else if (role === "brand-manager") {
          setUser({
            ...user,
            username: username,
            loading: false,
            role: role,
            partnerId: partnerId,
            partnerAlias: partnerAlias,
            brandId: brandId,
            brandAlias: brandAlias,
            email: email,
          });
          routes = (
            <UserContext.Provider value={value}>
              <BrandManagerRoutes
                partnerId={partnerId as string}
                brandId={brandId as string}
              />
            </UserContext.Provider>
          );
        } else if (role === "brand-reporter") {
          setUser({
            ...user,
            username: username,
            loading: false,
            role: role,
            partnerId: partnerId,
            partnerAlias: partnerAlias,
            brandId: brandId,
            brandAlias: brandAlias,
            email: email,
          });
          routes = (
            <UserContext.Provider value={value}>
              <BrandReporterRoutes
                partnerId={partnerId as string}
                brandId={brandId as string}
              />
            </UserContext.Provider>
          );
        } else {
          setUser({
            ...user,
            username: username,
            loading: false,
            role: role,
            email: email,
          });
          routes = (
            <UserContext.Provider value={value}>
              <NoRolesAssignedRoutes />
            </UserContext.Provider>
          );
        }
        setRoutes(routes);
      }
    };
    getRoutes();
  }, [loading, user.role, user.email]);
  //Configure public storage at root for Amplify.
  Storage.configure({
    customPrefix: {
      public: "",
    },
  });

  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return <div className={classes.root}>{routes}</div>;
}
