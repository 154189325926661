/**
 * @file credentialsStorage.ts Defines the {@link Storage} used for storing credentials.
 * @author Rudy Schürer
 * @exports credentialsStorage
 */

/**
 * {@link Storage} used for storing sensitive information related to credentials and login.
 * Currently, this constant resolves to either {@link localStorage} or {@link sessionStorage},
 * depending on environment settings.
 * 
 * Usage of session storage was mandated by the Penetration Test (see ERBT-6219).
 * Thus, it needs to be used for Staging and Production.
 */
export const credentialsStorage = function() {
    const config = process.env.REACT_APP_STAY_LOGGED_IN;
    return config === "true" ? localStorage : sessionStorage;
}()
