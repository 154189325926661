/**
 * @file BrandDashboardWithNav.tsx page. To be used in its own page, e.g. accessed via navigate().
 * @author Rafael Mello
 * @exports React.Component
 */

import { useLocation } from "react-router-dom";
import WithNav from "../components/common/templates/WithNav";
import BrandDashboard from "./BrandDashboard";

interface State {
  brandId: string;
}

export default function BrandDashboardWithNav() {
  const { state } = useLocation() as { state: State };
  return (
    <WithNav>
      <BrandDashboard brandId={state.brandId} />
    </WithNav>
  );
}