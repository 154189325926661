import * as Yup from "yup";
import {
  paiPrefixMinLength,
  paiPrefixMaxLength,
  paiMinLength,
  paiMaxLength,
  fromPrefixMinLength,
  fromPrefixMaxLength,
  fromMinLength,
  fromMaxLength
} from "./msisdnUtils";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { FormikErrors, FormikValues } from "formik";

/* eslint-disable no-template-curly-in-string */

export const yupForPaiContingent: Yup.NumberSchema<any> = 
  Yup.number()
    .integer("PAI Contingent must be an integer")
    .positive("PAI Contingent must be a positive number")
    .max(1000000, "The maximum value allowed is 1.000.000")
    // Anything that is not a valid number becomes `undefined`.
    // So required(message) is enough to validate the input and show an appropriate error.
    .required("Valid number required");

export const yupForAlias: Yup.StringSchema<any> = 
  Yup.string()
    .min(3, "Alias cannot be shorter than ${min} characters")
    .max(15, "Alias cannot be longer than ${max} characters")
    .required("Required")
    .matches(/^[._0-9a-zA-Zà-öÀ-Öø-þØ-Þ]*$/, "Alias contains invalid characters");

export const yupForRequiredStartDate: Yup.DateSchema<any> = Yup.date().required("Please select a start date");

export const yupForReportEndDate: Yup.DateSchema<any> =
  Yup.date().required("Please select an end date")
    .test("dates-are-ok", "Please make sure the end date is not before the start date", function (value) {
      return this.parent.from === undefined || this.parent.from <= value!;
    })
    .test("end-date-is-ok", "Please make sure the end date is not later than yesterday", function (value) {
      if (!value) {
        return true;
      }
      const yesterday: Date = new Date();
      yesterday.setUTCDate(yesterday.getUTCDate() - 1);
      const valueUTC = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
      return valueUTC <= yesterday;
    })
    .test("yesterday-report-is-ready", "The data for the previous day will only be available at 03:00 GMT+1.", function (value) {
      if (!value) {
        return true;
      }
      const now: Date = new Date();
      const yesterdayUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 1));
      const valueUTC = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
      if (valueUTC.getTime() === yesterdayUTC.getTime()) {
        // The report for the previous day will only be available at 02:00 UTC.
        if (now.getUTCHours() < 2) {
          return false;
        }
      }
      return true;
    });

// Yup string that rejects leading and trailing spaces.
export function yupTrimmedString(fieldPrettyName: string): Yup.StringSchema<any> {
  return Yup.string()
    .strict()
    .trim(fieldPrettyName + " cannot contain leading or trailing spaces");
}

/**
 * Creates an Yup schema to validate a display name field.
 * @param fieldPrettyName field name that will be shown to the user in case of an error.
 */
export function createYupDisplayName(fieldPrettyName: string) {
  return yupTrimmedString(fieldPrettyName)
    .min(2, fieldPrettyName + " cannot be shorter than ${min} characters")
    .max(30, fieldPrettyName + " cannot be longer than ${max} characters")
    // Accept all characters from GSM 7-bit default basic character set
    // (3GPP TS 23.038 / GSM 03.38, see also https://en.wikipedia.org/wiki/GSM_03.38)
    // with the following exceptions:
    //  * non-printable characters (LF, CR, ESC)
    //  * double quote (")
    //
    // Tests by Vodafone/Nokia have shown that double quote causes problems.
    // Background: as per RFC 3261 the display-name is defined as
    //   display-name = *(token LWS) / quoted-string
    // and in quoted strings, quotation marks (") need to be escaped.
    // Even in escaped format we saw some impact.
    //
    // Initially, there was some dispute on whether
    // Ç (LATIN CAPITAL LETTER C WITH CEDILLA) or
    // ç (LATIN SMALL LETTER C WITH CEDILLA) should be allowed.
    // See also the discussion in http://www.unicode.org/Public/MAPPINGS/ETSI/GSM0338.TXT
    // Tests by Vodafone/Nokia have shown that only Ç gets mapped to 7-bit 0x09,
    // so Ç is supported, but ç isn't.
    //
    // The regular expression accepts the following ranges:
    //  * space ( ), underscore (_), exclamation mark (!)
    //  * char range from #-Z. Includes #$%&'()*+,-./0-9:;<=>?@A-Z
    //  * char range from a-z.
    //  * special symbols and letters that are explicitly defined.
    .matches(/^[ _!#-Za-z£¥èéùìòØøÅåΔΦΓΛΩΠΨΣΘΞÆæßÉ¤¡ÄÖÑÜ§¿äöñüàÇ]+$/,
      "Only characters from the GSM 7-bit default basic character set (3GPP TS 23.038 / GSM 03.38) are allowed, excluding quotation mark (\")")
    // reject adjacent spaces
    .test(
      "Check for adjacent spaces",
      fieldPrettyName + " cannot contain adjacent spaces",
      (value: any, ctx: Yup.TestContext) => !value?.includes("  ")
    )
    .required("Required");
}

/**
 * Creates an Yup schema to validate a Number.
 *
 * Validated fields are: is_pai_prefix, pai, is_from_prefix and from.
 *
 * @param isPAIAPrefixMessage error message that will be shown if
 * the user entered a value that's OK for a prefix, but not for a PAI.
 * @param isFromAPrefixMessage error message that will be shown if
 * the user entered a value that's OK for a prefix, but not for a From.
 *
 * If not defined, a default error message is used.
 */
export function createYupNumberSchema(isPAIAPrefixMessage?: string, isFromAPrefixMessage?: string) {
  const defaultIsNumberAPrefixMessage = "Is the number a prefix? If yes, please select the prefix box, otherwise the number is too short";
  return Yup.object().shape({
    is_pai_prefix: Yup.bool().required(),
    pai: Yup.string()
      .required("PAI is required")
      .matches(/^\+[1-9][0-9]*$/, "PAI must be in format +1234567890")
      .when("is_pai_prefix", {
        is: true,
        then: (schema) =>
          schema
            .min(paiPrefixMinLength, "A PAI prefix must be at least ${min} characters in length")
            .max(paiPrefixMaxLength, "A PAI prefix cannot be longer than ${max} characters"),
        otherwise: (schema) =>
          schema
            .test(
              "is-pai-possibly-a-prefix",
              isPAIAPrefixMessage ? isPAIAPrefixMessage : defaultIsNumberAPrefixMessage,
              // if the user entered a value that's OK for a prefix, but not for an PAI, 
              // let this test fail and thus give the user the message asking if they meant to check the box
              (value) => !(value && value.length >= paiPrefixMinLength && value.length < paiMinLength)
            )
            .min(paiMinLength, "PAI must be at least ${min} characters in length")
            .max(paiMaxLength, "PAI cannot be longer than ${max} characters")
      }),
    is_from_prefix: Yup.bool().required(),
    from: Yup.string()
      .matches(/^\+[1-9][0-9]*$/, "From must be in format +1234567890")
      .when("is_from_prefix", {
        is: true,
        then: (schema) =>
          schema
            .min(fromPrefixMinLength, "A From-prefix must be at least ${min} characters in length")
            .max(fromPrefixMaxLength, "A From-prefix cannot be longer than ${max} characters"),
        otherwise: (schema) =>
          schema
            .test(
              "is-from-possibly-a-prefix",
              isFromAPrefixMessage ? isFromAPrefixMessage : defaultIsNumberAPrefixMessage,
              // if the user entered a value that's OK for a prefix, but not for an From, 
              // let this test fail and thus give the user the message asking if they meant to check the box
              (value) => !(value && value.length >= fromPrefixMinLength && value.length < fromMinLength)
            )
            .min(fromMinLength, "From must be at least ${min} characters in length")
            .max(fromMaxLength, "From cannot be longer than ${max} characters")
      })
  });
}

/**
 * This function can be used in conjuction with Formik's "validateOnChange={true}"
 * to trigger Yup validation every time an input field is updated, instead of
 * only validating the input values when the form is submitted.
 * 
 * "touched" is used because otherwise validation errors for all fields would show up when one field is changed.
 * "setFieldTouched" is used because "touched" does not seem to be updated properly in every scenario for every field.
 * 
 * Once "errors" and "touched" are being tracked, they can be used to show validation errors as desired.
 * See https://formik.org/docs/tutorial#visited-fields.
 */
export async function setField(
  name: string,
  value: string | GridRowSelectionModel,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<FormikValues>>,
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => Promise<void | FormikErrors<FormikValues>>
) {
  await setFieldValue(name, value);
  await setFieldTouched(name, true);
}
