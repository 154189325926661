/**
 * @file Campaign.tsx Campaign page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState } from "react";
import { Grid, Paper, Button, Stack, Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import campaignService, { CampaignType } from "../services/campaignService";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import OnLoadWithNav from "../components/common/templates/WithNav/OnLoadWithNav";
import OnErrorWithNav from "../components/common/templates/WithNav/OnErrorWithNav";
import useStyles from "../components/common/templates/style";
import { useUserContext } from "../context/UserContext";
import CampaignDesigner from "../components/Campaigns/CampaignDesigner/CampaignDesigner";
import AlertDialog from "../components/common/templates/feedback/AlertDialog";
import AlertSnackback from "../components/common/templates/feedback/AlertSnackbar";
import { alertDialogContent, alertDialogTitle, extractErrorMessage } from "../utils/utils";
import { invalidateCampaignQueries, invalidateMsisdnQueries } from "../utils/invalidateQueries";

/**
 * Props
 * @typedef {{brandId: string}} Props
 */
interface Props {
  brandId?: string;
}
/**
 * Renders Campaign page
 * @returns {React.Component} Campaign page
 */
export default function Campaign(props: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { campaignId } = useParams<{ campaignId: string }>();
  const { brandId: brandIdParam } = useParams<{ brandId: string }>();
  const { user } = useUserContext();

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  let brandId: string;
  if (!props?.brandId) {
    brandId = brandIdParam as string;
  } else {
    brandId = props.brandId as string;
  }

  const {
    data: campaign,
    isLoading: loadingCampaign,
    error: campaignError,
  } = useQuery<CampaignType>(["campaign", campaignId], () =>
    campaignService.getSingle(campaignId as string)
  );

  const queryClient = useQueryClient();

  const { mutateAsync: updateCampaign } = useMutation(campaignService.update, {
    onSuccess: () => {
      setMsg("Campaign updated!");
      setSuccess(true);
      setOpen(false);
      invalidateCampaignQueries(queryClient);
      invalidateMsisdnQueries(queryClient);
    }
  });

  const { mutateAsync: deleteCampaign } = useMutation(campaignService.delete, {
    onSuccess: () => {
      setMsg("Campaign deleted!");
      setSuccess(true);
      invalidateCampaignQueries(queryClient);
      // invalidate relevant query in the campaign designer
      invalidateMsisdnQueries(queryClient);
      navigate("../..", {relative: "path"});
    }
  });

  const handleDelete = () => {
    setOpenConfirm(false);
    deleteCampaign(campaignId as string).catch((err) => {
      setMsg(extractErrorMessage(err));
      setError(true);
    });
  };

  const isLoading: boolean = loadingCampaign;
  const renderError = campaignError;

  if (isLoading) return <OnLoadWithNav />;
  if (renderError) return <OnErrorWithNav error={renderError} />;

  return (
    <WithNav>
      {/* a ? b : (c ? d : e) */}
      <Grid container spacing={3}>
        {campaign?.approval_status === "PENDING" ? (
          <Grid item xs={12}>
            <Alert severity="warning">This campaign is pending approval.</Alert>
          </Grid>
        ) : campaign?.approval_status === "REJECTED" ? (
          <Grid item xs={12}>
            <Alert severity="error">
              This campaign has been rejected due to irrelevant/inappropriate
              content. Please review it and re-submit.
            </Alert>
          </Grid>
        ) : (
          <div></div>
        )}

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>{`${campaign?.name}`}</Title>
          </Paper>
        </Grid>
        {user.role !== "brand-reporter" && user.role !== "opco-admin" && user.role !== "vodafone-reporter" && (
          <Grid item xs={12}>
            <Stack spacing={2} direction="row">
              <Button color="primary" onClick={() => setOpen(true)}>
                Edit Campaign
              </Button>
              <Button color="secondary" onClick={() => setOpenConfirm(true)}>
                Delete Campaign
              </Button>
            </Stack>

            <CampaignDesigner
              brandId={brandId}
              campaign={campaign}
              saveCampaign={updateCampaign}
              open={open}
              setOpen={setOpen}
              setErrorMsg={(msg) => {
                setMsg(msg);
                setError(true); 
              }}
            />
            <AlertDialog
              title={alertDialogTitle("Campaign")}
              content={alertDialogContent("Campaign")}
              open={openConfirm}
              setOpen={setOpenConfirm}
              handleYes={handleDelete}
            />
            <AlertSnackback
              message={msg}
              type="success"
              open={success}
              setOpen={setSuccess}
            />
            <AlertSnackback
              message={msg}
              type="error"
              open={error}
              setOpen={setError}
            />
          </Grid>
        )}
      </Grid>
    </WithNav>
  );
}
