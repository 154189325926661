/**
 * @file BrandDashboard.tsx page. To be used within another page, e.g. Dashboard.
 * @author Rafael Mello
 * @exports React.Component
 */

import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import AnsweredCallsChart from "../components/Charts/AnsweredCallsChart";
import DurationBucketChart from "../components/Charts/DurationBucketChart";
import LineChart from "../components/Charts/LineChart";
import { useQuery } from "react-query";
import { ApiCallParams, toIsoDate, ReportLevelOfDetail } from "../components/Reports/ReportsForm/ReportsForm";
import reportService, { ReportData } from "../services/reportService";
import OnError from "../components/common/templates/feedback/OnError";
import OnLoad from "../components/common/templates/feedback/OnLoad";
import { useLocation } from "react-router-dom";
import { compareStrings } from "../utils/utils";
import Title from "../components/common/titles/Title";
import useStyles from "../components/common/templates/style";
import { Help } from "@mui/icons-material";

interface State {
  brandId: string;
}

interface Props {
  brandId?: string;
}

export default function BrandDashboard(props: Props) {
  const classes = useStyles();
  const { state } = useLocation() as { state: State };

  const now: Date = new Date();
  // Data from previous day is only available at 2:00 UTC.
  now.setHours(now.getHours() - 2);
  // Default period: last 14 days.
  const fromUTC = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 14);
  const yesterdayUTC = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 1);

  const from = toIsoDate(fromUTC);
  const to = toIsoDate(yesterdayUTC);

  const brandId = props.brandId || state.brandId;

  const rawParams: ApiCallParams = {
    id: brandId,
    from: from,
    to: to,
    type: "raw",
    levelOfDetail: ReportLevelOfDetail.BRANDS,
    callAnalytics: true,
    excludeReferenceCampaigns: true
  };

  const {
    data: rawReportData,
    isLoading: loadingRawReportData,
    error: rawReportDataError,
  } = useQuery<ReportData>(["raw-report-for-brand", brandId], () =>
    reportService.getByBrandId(rawParams),
    {
      staleTime: 120000
    }
  );

  const aggregateParams: ApiCallParams = {
    id: brandId,
    from: from,
    to: to,
    type: "aggregate",
    levelOfDetail: ReportLevelOfDetail.BRANDS,
    callAnalytics: true,
    excludeReferenceCampaigns: true
  };

  const {
    data: aggregateReportData,
    isLoading: loadingAggregateReportData,
    error: aggregateReportDataError,
  } = useQuery<ReportData>(["aggregate-report-for-brand", brandId], () =>
    reportService.getByBrandId(aggregateParams),
    {
      staleTime: 120000
    }
  );

  if (loadingRawReportData || loadingAggregateReportData) return <OnLoad />;
  if (rawReportDataError || aggregateReportDataError) return <OnError error={rawReportDataError || aggregateReportDataError} />;

  const chartName = "chart_brand-" + rawReportData!.rows[0]?.brandAlias + "_";

  // Add artificial rows for days that don't have any data.
  // We want to include every day of the selected period in the chart.
  while(fromUTC <= yesterdayUTC) {
    const rowDate = toIsoDate(fromUTC);
    if (!rawReportData!.rows.some(row => row.date === rowDate)) {
      rawReportData!.rows.push({count: "0", date: rowDate});
    }
    fromUTC.setDate(fromUTC.getDate() + 1);
  }
  rawReportData!.rows.sort((a, b) => compareStrings(a.date, b.date));

  return (
    <>
      <div className={classes.titleDiv}>
        <Title>Global Brand Call Analytics</Title>
        <Tooltip className={classes.tooltip} title={
          <Typography>
            This dashboard shows call analytics across the entire Brand. It only contains calls with a defined display name (CallerID).
            Calls from Reference Campaigns are excluded. Please use the Chart Designer to analyze or compare data from Reference Campaigns.
          </Typography>
        }>
          <IconButton><Help/></IconButton>
        </Tooltip>
      </div>
      <Grid item marginTop={"3em"}>
        <AnsweredCallsChart chartData={rawReportData!} chartName={chartName + "answeredCalls"} />
      </Grid>
      <Grid item marginTop={"3em"}>
        <LineChart
          chartData={rawReportData!}
          chartName={chartName + "pickUpRate"}
          label="Pick-Up Rate"
          metric="pickUpRate"
          yInPercentage={true}
        />
      </Grid>
      <Grid item marginTop={"3em"}>
        <LineChart
          chartData={rawReportData!}
          chartName={chartName + "averageCallDuration"}
          label="Average Call Duration (seconds)"
          metric="averageCallDuration"
        />
      </Grid>
      <Grid item marginTop={"3em"}>
        <DurationBucketChart chartData={aggregateReportData!} chartName={chartName + "segmentationByCallDuration"} />
      </Grid>
    </>
  );
}