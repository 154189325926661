/**
 * @file AlertDialog.tsx
 * @description AlertDialog component
 * @author Harry Rhodes
 * @exports React.Component
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface Props {
  title: string;
  content: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleYes: () => void;
}
export default function AlertDialog(props: Props) {
  const { title, content, open, setOpen, handleYes } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      { content &&
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
      }
      <DialogActions>
        <Button color="secondary" onClick={handleClose} autoFocus>
          No
        </Button>
        <Button color="primary" onClick={handleYes}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
