/**
 * @file Review.tsx
 * @description Review component for AddPartner
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Ref, useState, useEffect } from "react";
import { List, ListItem, ListItemText, Grid, Typography } from "@mui/material";
import { PartnerCreateType } from "../../../../services/partnerService";
import { UserInfoType } from "../../../common/onboarding/UserInfo";
import { Formik, Form } from "formik";
import useStyles from "./styles";
import { extractErrorMessage } from "../../../../utils/utils";

interface Props {
  partner: PartnerCreateType;
  target: File;
  user: UserInfoType;
  createPartner: (partner: FormData) => Promise<any>;
  setErrorMsg: (s: string) => void;
  handleNext?: () => void;
  innerRef?: Ref<any>;
}
export default function Review(props: Props) {
  const classes = useStyles();
  const { partner, target, user, createPartner, setErrorMsg } = props;
  const [preview, setPreview] = useState("");

  useEffect(() => {
    if (target) {
      const reader = new FileReader();
      reader.readAsDataURL(target);
      reader.addEventListener("load", () => {
        setPreview(reader.result as string);
      });
    }
  }, [target]);

  return (
    <Formik
      innerRef={props.innerRef}
      initialValues={{
        null: null,
      }}
      onSubmit={() => {
        //Parse into form data for potential file upload
        let formData = new FormData();
        formData.append("name", partner.name);
        formData.append("alias", partner.alias.toUpperCase());
        formData.append("account_email", user.username.toLowerCase());
        formData.append("pai_contingent", partner.pai_contingent);
        formData.append("opco_ids", JSON.stringify(partner.opco_ids));
        if (target) {
          formData.append("file", target);
        }
        createPartner(formData).catch((err) => {
          setErrorMsg(extractErrorMessage(err));
        });
      }}
    >
      <Form>
        <div className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                New Partner Details
              </Typography>
              {preview && <img className={classes.logo} src={preview} alt="logo preview" />}
              <div>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={props.partner.name}
                      secondary={"Name"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={props.partner.alias}
                      secondary={"Alias"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={props.partner.pai_contingent}
                      secondary={"PAI Contingent"}
                    />
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                Partner Manager Details
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary={props.user.username}
                    secondary={"Username / Email"}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      </Form>
    </Formik>
  );
}
