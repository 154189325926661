/**
 * @file Users.tsx Users page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useEffect, useRef, useState } from "react";
import { Button, Grid, Paper } from "@mui/material";
import UsersTable from "../components/Users/UsersTable";
import { useQuery } from "react-query";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import OnLoadWithNav from "../components/common/templates/WithNav/OnLoadWithNav";
import OnErrorWithNav from "../components/common/templates/WithNav/OnErrorWithNav";
import useStyles from "../components/common/templates/style";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import userService, { UserRole, UsersGroup } from "../services/userService";
import AddUserDialog from "../components/Users/AddUserDialog";
import AlertSnackback from "../components/common/templates/feedback/AlertSnackbar";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/lib/core";

/**
 * Props
 * @typedef {{brandId: string}} Props
 */
interface Props {
  brandId?: string;
}

/**
 * State
 * @typedef {{brandAlias: string}} State
 */
export interface State {
  opcoAlias?: string;
  partnerAlias?: string;
  brandAlias?: string;
}

interface UserDetailsCsvData {
  email: string,
  role: string,
  enabled: string,
  userStatus: string
}

/**
 * Renders Users page
 * @param props component props @see Props
 * @returns {React.Component} Users page
 */
export default function Users(props: Props) {
  const classes = useStyles();
  const { user } = useUserContext();
  const { role } = user;
  const { state } = useLocation() as { state: State };

  const [openAddUser, setOpenAddUser] = useState(false);
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [groupName, setGroupName] = useState<string>("");
  const [radioRoles, setRadioRoles] = useState<UserRole[]>([]);
  const [showSubRoles, setShowSubRoles] = useState<boolean>(false);
  const [brandAlias, setBrandAlias] = useState("");
  const [partnerAlias, setPartnerAlias] = useState("");
  const [opcoAlias, setOpcoAlias] = useState("");

  /** 
   * Keep track of initialMount within useEffect.
   * We need this to only trigger the CSV download when `data` state changes
   * and skip the initial run of useEffect when the page is mounted.
   * 
   * See 
   * https://legacy.reactjs.org/docs/hooks-faq.html#can-i-run-an-effect-only-on-updates
   * https://stackoverflow.com/questions/55075604/react-hooks-useeffect-only-on-update
   * for more info.
   */
  const isInitialMount = useRef(true);
  /** Holds the data that will go into the CSV */
  const [data, setData] = useState<Data>([]);
  /** Holds the name of the CSV file that will be downloaded */
  const [csvFileName, setCsvFileName] = useState<string>("");
  /** A reference to the CSVLink component, which we use to simulate a click onto it */
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      csvLinkRef.current?.link.click();
    }
  }, [data]);

  function downloadUserDetailsCsv() {
    const usersMap = new Map<string, UserDetailsCsvData>();
    usersGroup!.forEach((group) => {
      let line: UserDetailsCsvData;
      group.groupUsers.forEach((user) => {
        line = {
          email: user.Attributes.find(e => e.Name === "email")!.Value,
          role: group.prettyGroupName,
          enabled: user.Enabled === true ? "Yes" : "No",
          userStatus: user.UserStatus
        };
        const email = line.email;
        if (usersMap.has(email)) {
          // if the same user already exists in a previous line, join roles
          line.role = usersMap.get(email)!.role += ", " + line.role; 
        }
        usersMap.set(email, line);
      });
    });
    setCsvFileName(getCsvFileName());
    setData(Array.from(usersMap.values()));
  }

  function getCsvFileName() {
    let fileName = "users_";
    if (groupName === "vodafone-admin") {
      fileName += "global";
    } else if (groupName.endsWith("opco-admin")) {
      fileName += "opco-" + opcoAlias;
    } else if (groupName.endsWith("partner-manager")) {
      fileName += "partner-" + partnerAlias;
    } else if (groupName.endsWith("brand-manager")) {
      fileName += "brand-" + brandAlias;
    }
    if (showSubRoles) {
      fileName += "_with-sub-entities";
    }
    return fileName;
  }

  function setupVodafoneAdmin() {
    setGroupName("vodafone-admin");
    setRadioRoles([UserRole.VODAFONE_ADMIN, UserRole.VODAFONE_REPORTER]);
  }

  function setupOpcoAdmin(alias: string) {
    setGroupName(alias + "-opco-admin");
    setRadioRoles([UserRole.OPCO_ADMIN]);
    setOpcoAlias(alias);
  }

  function setupPartnerManager(alias: string) {
    setGroupName(alias + "-partner-manager");
    setRadioRoles([UserRole.PARTNER_MANAGER]);
    setPartnerAlias(alias);
  }

  function setupBrandUser(brandAlias: string, partnerAlias: string) {
    setGroupName(partnerAlias + "-" + brandAlias + "-brand-manager");
    setRadioRoles([UserRole.BRAND_MANAGER, UserRole.BRAND_REPORTER]);
    setBrandAlias(brandAlias);
    setPartnerAlias(partnerAlias);
  }

  useEffect(() => {
    switch (user.role) {
      case "vodafone-admin":
        if (state && state.partnerAlias && state.brandAlias) {
          setupBrandUser(state.brandAlias, state.partnerAlias);
        } else if (state && state.partnerAlias) {
          setupPartnerManager(state.partnerAlias);
        } else if (state && state.opcoAlias) {
          setupOpcoAdmin(state.opcoAlias);
        } else {
          setupVodafoneAdmin();
        }
        break;
      case "opco-admin":
        setupOpcoAdmin(user.opcoAlias as string);
        break;
      case "partner-manager":
        if (state && state.brandAlias) {
          setupBrandUser(state.brandAlias, user.partnerAlias as string);
        } else {
          setupPartnerManager(user.partnerAlias as string);
        }
        break;
      case "brand-manager":        
        setupBrandUser(user.brandAlias as string, user.partnerAlias as string);
        break;
      default:
        setGroupName("");
        break;
    }
  }, [state, role]);

  const {
    data: usersGroup,
    isLoading: loadingUsers,
    error: usersError,
  } = useQuery<UsersGroup[]>(
    ["users", groupName, showSubRoles],
    () => userService.getUsersFromGroupAndSubGroups(groupName, showSubRoles),
    {
      enabled: !!groupName,
      staleTime: 120000
    }
  );

  if (loadingUsers) return <OnLoadWithNav />;
  if (usersError) return <OnErrorWithNav error={usersError} />;

  return (
    <WithNav>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>Users</Title>
            <div className={classes.buttons}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => setOpenAddUser(true)}
              >
                Add User
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.tableButtons}
                onClick={() => {downloadUserDetailsCsv()}}
              >
                Download User Details
              </Button>
            </div>
            <UsersTable
              data={usersGroup || []}
              currentUser={user}
              showSubRoles={showSubRoles}
              setShowSubRoles={setShowSubRoles}
              setSuccessMessage={(msg) => {
                setMsg(msg);
                setSuccess(true); 
              }}
              setErrorMessage={(msg) => {
                setMsg(msg);
                setError(true); 
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      <AddUserDialog
        radioRoles={radioRoles}
        open={openAddUser}
        setOpen={setOpenAddUser}
        setSuccess={(msg) => {
          setMsg(msg);
          setSuccess(true); 
        }}
        setError={(msg) => {
          setMsg(msg);
          setError(true); 
        }}
        brandAlias={brandAlias}
        partnerAlias={partnerAlias}
        opcoAlias={opcoAlias}
      />
      <AlertSnackback
        message={msg}
        type="success"
        open={success}
        setOpen={setSuccess}
      />
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
      <CSVLink
        hidden={true}
        headers={[
          { label: "E-Mail",      key: "email" },
          { label: "Role",        key: "role" },
          { label: "Enabled?",    key: "enabled" },
          { label: "User Status", key: "userStatus" }
        ]}
        data={data}
        filename={csvFileName}
        separator=";"
        enclosingCharacter='"'
        ref={csvLinkRef}
      />
    </WithNav>
  );
}
