import { ReactElement, useState, useEffect } from "react";
import SignIn from "../../../pages/SignIn";
import Confirm from "../../../pages/Confirm";
import { Auth } from "aws-amplify";
import { Route, Navigate, BrowserRouter, Routes } from "react-router-dom";

interface Props {
  children: ReactElement;
}
function Authenticator(props: Props) {
  const { children } = props;
  const [content, setContent] = useState<ReactElement>(<div></div>);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function checkAuthStatus() {
      try {
        setLoading(true);
        await Auth.currentAuthenticatedUser();
        setContent(children);
        setLoading(false);
      } catch {
        setContent(
          <BrowserRouter basename="/">
            <Routes>
              <Route path="/" element={<Navigate to={"/signin"} />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signin/confirm" element={<Confirm />}/>
              {/* <Route path="*" element={<NotFound authenticated={false}/>} /> */}
              <Route path="*" element={<SignIn />} />
            </Routes>
          </BrowserRouter>
        );
        setLoading(false);
      }
    }
    checkAuthStatus();
  }, []);

  if (loading) {
    <div></div>;
  }

  return content;
}

export default Authenticator;
