import axios from "axios";
import { Auth } from "aws-amplify";
// Base application URLS
let baseUrl: string = "http://localhost:4000/api/v1";
// Deployment env check
if (process.env.NODE_ENV === "production") {
  baseUrl = process.env.REACT_APP_API as string;
}

const client = axios.create({
  baseURL: baseUrl,
});

//Add auth to outbound requests if in prod
client.interceptors.request.use(
  async (config) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export { baseUrl, client };
