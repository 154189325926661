/**
 * @file index.tsx Defines top level configurations for react app
 * @author Harry Rhodes
 * @exports makeStyles
 */
import ReactDOM from "react-dom";
import App from "./App";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from "./reportWebVitals";
import { Amplify, Analytics } from "aws-amplify";
import Authenticator from "./components/common/authentication/Authenticator";
import { credentialsStorage } from "./utils/credentialStorage"

/**
 * Configures AWS Amplify integration
 * @param AmplifyConfig @see aws-amplify
 */
Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_AMPLIFY_IDENTITY_POOL_ID,
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_SRP_AUTH",
    // Define where to store authentication tokens
    storage: credentialsStorage,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_AWS_REGION,
    },
  },
});

Analytics.configure({
  AWSPinpoint: {
    appId: process.env.REACT_APP_PINPOINT_APP_ID,
    region: process.env.REACT_APP_AWS_REGION,
    endpointId: "portal-frontend", // overrides the default Amplify-generated endpoint ID.
    // Amplify only supports up-to 15 endpoints but future update will remove endpoints entirely
  },
  mandatorySignIn: false, // To allow logging even if user is not signed in
});

/**
 * Defines application theme colours
 * @param theme @see material-ui
 */
const theme = createTheme({
  palette: {
    primary: {
      main: "#e60000",
      light: "#FCDFDF",
    },
    secondary: {
      main: "#54575a",
      light: "#EAEAEA",
    },
    error: {
      main: "#e60000",
    },
    success: {
      main: "#a8b400",
    },
  },
});

const client = new QueryClient();

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={client}>
        <Authenticator>
          <App />
        </Authenticator>
      </QueryClientProvider>
    </ThemeProvider>
  </StyledEngineProvider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
