/**
 * @file partnerService.ts
 * @description Interface/client for interacting with local /partner API.
 * @requries ./axiosClient
 * @author Harry Rhodes
 */

import { client } from "./axiosClient";

export type PartnerCreateType = {
  name: string;
  alias: string;
  account_email: string;
  pai_contingent: string; // string instead of number so we can use an empty string as default value in the Create form
  opco_ids: string[];
  image?: File;
};
/**
 * @typedef PartnerType
 * @property {string} id - DB generated id
 * @property {string} logo - S3 key for partner logo
 * @property {string} name - Name of partner
 * @property {string} alias - Short name of partner ie VGSL (Vodafone Group Services Limited)
 * @property {number} pai_contingent - Upper bound of the sum of PAI contingents of all brands owned by this partner.
 * @property {string[]} opco_ids - List of opco ids which the partner is associated to @default null @see opco.model.ts
 */
export type PartnerType = {
  id?: string;
  logo?: string;
  name: string;
  alias: string;
  pai_contingent: number;
  opco_ids?: string[];
};

const service = {
  getAll: async (opcoId?: string): Promise<PartnerType[]> => {
    let res;
    if (opcoId) {
      res = await client.get("/partners", {
        params: { opco_id: opcoId },
      });
    } else {
      res = await client.get("/partners");
    }
    return res.data;
  },
  getId: async (alias: string) => {
    let res = await client.get("/partners", { params: { alias: alias } });
    return res.data[0].id || [];
  },
  getName: async (alias: string) => {
    let res = await client.get("/partners", { params: { alias: alias } });
    return res.data[0]?.name || "";
  },
  getAlias: async (id: string) => {
    let res = await client.get("/partners/" + id);
    return res.data[0].alias || [];
  },
  getSingle: async (id: string): Promise<PartnerType> => {
    let res = await client.get("/partners/" + id);
    return res.data[0] || [];
  },
  create: async (body: FormData) => {
    let res = await client.post("/partners", body);
    return res.data || [];
  },
  update: async (body: PartnerType) => {
    let res = await client.put("/partners/" + body.id, body);
    return res.data || [];
  },
  updateLogo: async (id: string, body: FormData) => {
    let res = await client.patch("/partners/" + id + "/logo", body);
    return res.data || [];
  },
  delete: async (id: string) => {
    let res = await client.delete("/partners/" + id);
    return res.data || [];
  },
};

export default service;
