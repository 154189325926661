import { Ref, useState } from "react";
import { FormControlLabel, FormHelperText, Grid, Switch, Typography } from "@mui/material";
import FieldFW from "../../../common/templates/forms/FieldFW";
import { Formik, Form } from "formik";
import { validationSchema } from "../../common/validationSchema";
import { CampaignInfoType } from "../CampaignDesigner";
import useStyles from "./styles";
import RadioSelect from "../../../common/templates/forms/RadioSelect";
import { AntiSpoofingType } from "../../../../services/campaignService";

interface Props {
  brandId: string;
  campaignInfo: CampaignInfoType;
  setCampaignInfo: (campaign: CampaignInfoType) => void;
  featureAntiSpoofingEnabled: boolean;
  handleNext?: () => void;
  innerRef?: Ref<any>;
}

/** 
 * Component for the first step when creating a campaign. 
 * Shows a form that allows input of campaign name, description and display name.
*/
export default function CampaignInfo(props: Props) {
  const classes = useStyles();
  const { brandId, campaignInfo } = props;

  const [antiSpoofingEnabled, setAntiSpoofingEnabled] =
    useState<boolean>(!!campaignInfo.anti_spoofing && campaignInfo.anti_spoofing !== AntiSpoofingType.DISABLED);

  const [referenceCampaign, setReferenceCampaign] = useState<boolean>(campaignInfo.reference_campaign);
  // Placeholder for when referenceCampaign is true
  const DISPLAY_NAME_PLACEHOLDER = "Only the number will be shown – without a display name";

  return (
    <Formik
      innerRef={props.innerRef}
      validationSchema={validationSchema}
      validateOnChange={true}
      initialValues={{
        id: campaignInfo?.id,
        name: campaignInfo?.name || "",
        display_name: referenceCampaign ? DISPLAY_NAME_PLACEHOLDER : campaignInfo?.display_name || "",
        desc: campaignInfo?.desc || "",
        brand_id: brandId as string,
        campaign_anti_spoofing_enabled: antiSpoofingEnabled,
        anti_spoofing: campaignInfo?.anti_spoofing || AntiSpoofingType.DISABLED,
        reference_campaign: referenceCampaign
      }}
      onSubmit={(data: any, { setSubmitting }) => {
        setSubmitting(true);
        // This is needed because of https://rncorp.atlassian.net/browse/ERBT-6777.
        // If campaign_anti_spoofing_enabled is set to enabled and then back to disabled,
        // the anti_spoofing value (which was also set) needs to be ignored.
        if (!data.campaign_anti_spoofing_enabled) {
          data.anti_spoofing = AntiSpoofingType.DISABLED;
        }
        // If reference_campaign is true, display_name value must be ignored.
        if (data.reference_campaign) {
          delete data.display_name;
        }
        props.setCampaignInfo(data);
        setSubmitting(false);
        if (props.handleNext) {
          props.handleNext();
        }
      }}
    >
      {({ values, errors }) => (
        <Form>
          <div className={classes.container}>
            <Grid container>
              <Grid item container xs={12} md={6}>
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Campaign Details
                    </Typography>
                    <FieldFW
                      type="text"
                      required
                      id="name"
                      name="name"
                      placeholder="Name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldFW
                      type="text"
                      required
                      id="display_name"
                      name="display_name"
                      placeholder="Display Name"
                      variant="outlined"
                      readonly={referenceCampaign}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldFW
                      type="text"
                      required
                      id="desc"
                      name="desc"
                      placeholder="Description"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>

          {props.featureAntiSpoofingEnabled == true && 
            <div>
              <Grid container className={classes.antiSpoofingButtons}>
                <Grid item xs={1.5}>
                  <FormControlLabel
                    name="campaign_anti_spoofing_enabled"
                    label="Anti-Spoofing"
                    checked={antiSpoofingEnabled}
                    disabled={campaignInfo.id !== undefined}
                    onChange={() => {
                      values.campaign_anti_spoofing_enabled = !values.campaign_anti_spoofing_enabled;
                      setAntiSpoofingEnabled(values.campaign_anti_spoofing_enabled);
                    }}
                    control={<Switch />}
                  />
                  {(errors.campaign_anti_spoofing_enabled) && (
                    <FormHelperText error={true}>
                      {errors.campaign_anti_spoofing_enabled}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item>
                  <RadioSelect
                    name="anti_spoofing"
                    disabled={!values.campaign_anti_spoofing_enabled || campaignInfo.id !== undefined}
                    options={Object.keys(AntiSpoofingType)
                      .filter((key) => key !== AntiSpoofingType.DISABLED)
                      .map((key) => { 
                        return { 
                          label: AntiSpoofingType[key as keyof typeof AntiSpoofingType],
                          value: key
                        }
                      })
                    }
                  />
                  {(errors.anti_spoofing) && (
                    <FormHelperText error={true}>
                      {errors.anti_spoofing}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <FormHelperText>
                When turning on Anti-Spoofing, your outbound system must execute a pre-announcement to prevent number spoofing.
              </FormHelperText>
              <FormHelperText>
                In case no pre-announcement is taking place or the time limit is exceeded, the call can either be blocked or does not get enriched.
              </FormHelperText>
            </div>
          }

          <div className={classes.referenceCampaignDiv}>
            <Grid container>
              <Grid item xs={1.5}>
                <FormControlLabel
                  name="reference_campaign"
                  label="Reference Campaign"
                  checked={referenceCampaign}
                  disabled={campaignInfo.id !== undefined}
                  onChange={() => {
                    values.reference_campaign = !values.reference_campaign;
                    setReferenceCampaign(values.reference_campaign);
                    if (values.reference_campaign) {
                      values.display_name = DISPLAY_NAME_PLACEHOLDER;
                    } else {
                      values.display_name = "";
                    }
                  }}
                  control={<Switch />}
                />
                {(errors.reference_campaign) && (
                  <FormHelperText error={true}>
                    {errors.reference_campaign}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <FormHelperText>
              When activating “Reference Campaign”, outbound calls from Numbers being assigned to this Campaign will not show a display name, but still be reported in CallerID.
            </FormHelperText>
            <FormHelperText>
              This feature allows to collect and compare call analytics for Numbers with and without using a CallerID.
            </FormHelperText>
          </div>

        </Form>
      )}
    </Formik>
  );
}
