/**
 * @file userService.ts Interface/client for interacting with local /users API.
 * @requries ./axiosClient
 * @author Harry Rhodes
 */

import { client } from "./axiosClient";

/**
 * @typedef UserType
 * @property {number} id - AWS generated id
 * @property {string} username - Given username
 * @property {string} email - Email address
 * @property {string} phone - Phone number
 * @property {string} opco_alias - OPCO alias
 * @property {string} aggregator_alias - Aggregator alias
 * @property {string} partner_alias - Partner alias
 * @property {string} brand_alias - Brand alias 
 * @property {string} role - assigned role

 */

export type UserCreateType = {
  email: string;
  role: string;
};

export enum UserRole {
  BRAND_MANAGER = "Brand Manager",
  BRAND_REPORTER = "Brand Reporter",
  PARTNER_MANAGER = "Partner Manager",
  OPCO_ADMIN = "OPCO Admin",
  VODAFONE_ADMIN = "Vodafone Admin",
  VODAFONE_REPORTER = "Vodafone Reporter",
}

/**
 * Convert the pretty name of a role to the format required/expected by the server.
 * 
 * Brand Manager -> brand-manager
 * Brand Reporter -> brand-reporter
 * Partner Manager -> partner-manager
 * OPCO Admin -> opco-admin
 */
export function transformRoleToServerFormat(role: UserRole): string {
  return role.toLowerCase().replaceAll(" ", "-");
}

type AttributeType = {
  Name: string;
  Value: string;
};

/**
 * Type alias for the backend/cognito response of a ListUsersInGroup call
 * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ListUsersInGroup.html
 */
type UserReadAllType = {
  Username: string;
  Attributes: AttributeType[];
  UserCreateDate: string;
  UserLastModifiedDate: string;
  Enabled: boolean;
  UserStatus: string;
};

/**
 * Wrapper for a {@link UserReadAllType}.
 * Used to parse information that's usually inside the Attributes array into easily-readable fields.
 */
export class UserReadAll {
  readonly Username: string;
  readonly Attributes: AttributeType[];
  readonly UserCreateDate: string;
  readonly UserLastModifiedDate: string;
  readonly Enabled: boolean;
  readonly UserStatus: string;
  readonly Email? : string;
  readonly EmailVerified : boolean;

  constructor(readonly user: UserReadAllType) {
    this.Username = user.Username;
    this.Attributes = user.Attributes;
    this.UserCreateDate = user.UserCreateDate;
    this.UserLastModifiedDate = user.UserLastModifiedDate;
    this.Enabled = user.Enabled;
    this.UserStatus = user.UserStatus;

    // populate e-mail and verified fields from the attributes
    this.Email = this.Attributes.find(e => e.Name === "email")?.Value;
    this.EmailVerified = this.Attributes.find(e => e.Name === "email_verified")?.Value === "true" || false;
  }
}

/**
 * Wrapper for the output of a "getUsersFromGroupAndSubGroups" call.
 */
export type UsersGroup = {
  groupName: string,
  prettyGroupName: string,
  groupUsers: UserReadAll[]
}

/**
 * Type alias for the backend/cognito response of a AdminGetUser call
 * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_AdminGetUser.html
 */
type UserReadSingleType = {
  Username: string;
  UserAttributes: AttributeType[];
  UserCreateDate: string;
  UserLastModifiedDate: string;
  Enabled: boolean;
  UserStatus: string;
  PreferredMfaSetting?: string;
  UserMFASettingList: string[];
};

/**
 * Wrapper for a {@link UserReadSingleType}.
 * Used to parse information that's usually inside the UserAttributes array into easily-readable fields.
 */
export class UserReadSingle {

  readonly Username: string;
  readonly UserAttributes: AttributeType[];
  readonly UserCreateDate: string;
  readonly UserLastModifiedDate: string;
  readonly Enabled: boolean;
  readonly UserStatus: string;
  readonly PreferredMfaSetting?: string;
  readonly UserMFASettingList: string[];
  readonly Email? : string;
  readonly EmailVerified : boolean;

  constructor(readonly user: UserReadSingleType) {
    this.Username = user.Username;
    this.UserAttributes = user.UserAttributes;
    this.UserCreateDate = user.UserCreateDate;
    this.UserLastModifiedDate = user.UserLastModifiedDate;
    this.Enabled = user.Enabled;
    this.UserStatus = user.UserStatus;
    this.PreferredMfaSetting = user.PreferredMfaSetting;
    this.UserMFASettingList = user.UserMFASettingList;

    // populate e-mail and verified fields from the attributes
    this.Email = this.UserAttributes.find(e => e.Name === "email")?.Value;
    this.EmailVerified = this.UserAttributes.find(e => e.Name === "email_verified")?.Value === "true" || false;
  }
}

export type UserGroupType = {
  GroupName: string;
  UserPoolId: string;
  Description?: string;
  RoleArn: string;
  Precedence: number;
  LastModifiedDate: string;
  CreationDate: string;
};

const service = {
  getAll: async (groupName?: string): Promise<UserReadAll[]> => {
    let res;
    if (!groupName) {
      res = await client.get("/users");
    } else {
      res = await client.get("/users", {
        params: { group_name: groupName },
      });
    }

    let users : UserReadAllType[] = res.data;
    let returnData : UserReadAll[] = [];

    users.forEach(e => {
      returnData.push(new UserReadAll(e));
    })

    return returnData;
  },
  getSingle: async (username: string): Promise<UserReadSingle> => {
    let res = await client.get("/users/" + username);
    return new UserReadSingle(res.data);
  },
  getGroups: async (username: string): Promise<UserGroupType[]> => {
    let res = await client.get("/users/" + username + "/groups");
    return res.data || [];
  },
  getUsersFromGroupAndSubGroups: async (groupName: string, includeSubRoles: boolean): Promise<UsersGroup[]> => {
    let res = await client.get("/usersFromGroup", {
      params: {
        group_name: groupName,
        include_sub_roles: includeSubRoles
      }
    });
    return res.data || [];
  },
  create: async (body: UserCreateType) => {
    let res = await client.post("/users", body);
    return res.data || [];
  },
  revokeRole: async (userId: string, groupName: string) => {
    let res = await client.post("/users/" + userId + "/revokeRole", { group_name: groupName });
    return res.data || [];
  }
};

export default service;
