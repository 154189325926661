/**
 * @file Reports.tsx Reports page
 * @author Christian Vasold
 * @exports React.Component
 */

import { Grid, Paper } from "@mui/material";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import useStyles from "../components/common/templates/style";
import ReportsForm from "../components/Reports/ReportsForm/ReportsForm";

/**
 * Properties to pass to the page
 */
interface Props {
    partnerId?: string;
    brandId?: string;
}

/**
 * Reports page. Shows the ReportsForm component inside a Paper in a Grid.
 */
export default function Reports(props: Props) {
    const classes = useStyles();
    return (
        <WithNav>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        {/* TODO use a better title, like "Reports for Partner MegaCorp" */}
                        <Title>Reports</Title>
                        <ReportsForm brandId={props.brandId} partnerId={props.partnerId} />
                    </Paper>
                </Grid>
            </Grid>
        </WithNav>
    );
}