/**
 * @file validationSchema.ts Form input validation for the reports form
 * @author Christian Vasold
 * @exports validationSchema
 */
import * as Yup from 'yup';
import { yupForReportEndDate, yupForRequiredStartDate } from '../../../utils/validationUtils';

export const validationSchema = Yup.object().shape({
  from: yupForRequiredStartDate,
  to: yupForReportEndDate,
  campaignIds: Yup
    .array()
    .of(Yup.string())
    .required()
    .min(1, "Please select at least one campaign")
    .max(5, "Please select up to 5 campaigns"),
  metric: Yup
    .string()
    .required()
});