/**
 * @file AddMSISDN.tsx
 * @description AddMSISDN component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState } from "react";
import {
  Button,
  Checkbox,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel
} from "@mui/material";
import msisdnService from "../../../services/msisdnService";
import { Formik, Field } from "formik";
import FieldFW from "../../common/templates/forms/FieldFW";
import { useMutation, useQueryClient } from "react-query";
import { validationSchema } from "../common/validationSchema";
import useStyles from "../common/style";
import AlertSnackback from "../../common/templates/feedback/AlertSnackbar";
import { MSISDNType } from "../../../services/msisdnService";
import { extractErrorMessage } from "../../../utils/utils";
import { invalidateMsisdnQueries } from "../../../utils/invalidateQueries";

/**
 * Props
 * @typedef {{brandId: string}} Props
 */
interface Props {
  brandId: string;
  open: boolean;
  handleClickClose: () => void;
  initialValuePai?: string;
  initialValueFrom?: string;
}
/**
 * Renders AddMSISDN component
 * @param props component props @see Props
 * @returns {React.Component} AddMSISDN component
 */
export default function AddMSISDN(props: Props) {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const queryClient = useQueryClient();

  const { mutateAsync: createMSISDN } = useMutation(msisdnService.create, {
    onSuccess: () => {
      setSuccess(true);
      invalidateMsisdnQueries(queryClient);
    }
  });
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClickClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Number</DialogTitle>
        <DialogContent className={classes.dialog}>
          <Formik
            validateOnChange={true}
            initialValues={{
              pai: props.initialValuePai ? props.initialValuePai : "",
              from: props.initialValueFrom ? props.initialValueFrom : "",
              brand_id: props.brandId,
              is_pai_prefix: false,
              is_from_prefix: false
            }}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true);
              /**
               * If we want to create a prefix, we simply append an asterisk to the MSISDN
               */
              if (data.is_pai_prefix && data.pai) {
                data.pai = data.pai + "*"
              }
              if (data.is_from_prefix && data.from) {
                data.from = data.from + "*"
              }

              // Prepare the parameters for the backend call.
              // Note that we exclude is_prefix
              const values : MSISDNType = {
                pai: data.pai,
                from: data.from? data.from : undefined,
                brand_id: data.brand_id
              }
              try {
                await createMSISDN(values);
              } catch (err: any) {
                setError(true);
                setErrorMsg(extractErrorMessage(err));
              }
              setSubmitting(false);
              props.handleClickClose();
            }}
          >
            {({ isSubmitting, handleSubmit, submitForm }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FieldFW
                      type="text"
                      required
                      id="pai"
                      name="pai"
                      placeholder="PAI"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="checkbox"
                      name="is_pai_prefix"
                      as={FormControlLabel}
                      control={<Checkbox />}
                      label="Prefix"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldFW
                      type="text"
                      id="from"
                      name="from"
                      placeholder="From"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="checkbox"
                      name="is_from_prefix"
                      as={FormControlLabel}
                      control={<Checkbox />}
                      label="Prefix"
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    onClick={() => submitForm()}
                    disabled={isSubmitting}
                    color="primary"
                  >
                    Add
                  </Button>
                  <Button onClick={props.handleClickClose} color="secondary">
                    Cancel
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <AlertSnackback
        message={"Number created!"}
        type="success"
        open={success}
        setOpen={setSuccess}
      />
      <AlertSnackback
        message={errorMsg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </>
  );
}
