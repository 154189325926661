/**
 * @file validationSchema.ts Yup validation schema for SignIn
 * @author Harry Rhodes
 * @exports Yup.object
 */
import * as Yup from "yup";

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Required")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});
