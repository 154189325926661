import { Fragment, useState, useEffect } from "react";
import { Button, Avatar, Typography, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import FieldFW from "../../common/templates/forms/FieldFW";
import AlertSnackback from "../../common/templates/feedback/AlertSnackbar";
import PhonelinkLockOutlinedIcon from "@mui/icons-material/PhonelinkLockOutlined";
import { validationSchema } from "./validationSchema";
import useStyles from "../styles";
import { Auth } from "aws-amplify";
import { QRCodeCanvas } from "qrcode.react";
import { useNavigate } from "react-router-dom";
import { extractErrorMessage } from "../../../utils/utils";

interface MFAType {
  code: string;
}

interface Props {
  user: any;
  email: string;
}

export default function MFAForm(props: Props) {
  const classes = useStyles();
  const { user, email } = props;
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  const env = process.env.REACT_APP_ENVIRONMENT_DESCRIPTION;
  const encodedIssuer = encodeURIComponent("Vodafone CallerID" + (env ? " (" + env + ")" : ""));
  const encodedImageUrl = encodeURIComponent("https://" + window.location.host + "/vf-logo.png");
  const encodedEmail = encodeURIComponent(email);

  useEffect(() => {
    Auth.setupTOTP(user).then((code) => {
      setUrl("otpauth://totp/" + encodedIssuer + ":" + encodedEmail + "?secret=" + code + "&issuer=" + encodedIssuer + "&image=" + encodedImageUrl);
    });
  }, []);

  return (
    <Fragment>
      <div>
        <Avatar className={classes.avatar}>
          <PhonelinkLockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Set Up MFA
        </Typography>
        <Typography>
          You currently do not have MFA set up, but it is required for your
          account. Scan the QR code with an MFA app such as Google Authentictor
          or Authy and enter the code into the box below. This will be required
          each time you sign in.
        </Typography>
      </div>

      <QRCodeCanvas size={300} marginSize={8} value={url} />
      <Formik
        validateOnChange={true}
        initialValues={{
          code: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (data: MFAType, { setSubmitting }) => {
          setSubmitting(true);
          try {
            await Auth.verifyTotpToken(user, data.code);
            await Auth.setPreferredMFA(user, "SOFTWARE_TOKEN_MFA");
            navigate("/");
            window.location.reload();
          } catch (err: any) {
            if (err.code === "EnableSoftwareTokenMFAException" || err.code === "InvalidParameterException") {
              setMsg("MFA code is invalid");
            } else {
              setMsg(extractErrorMessage(err));
            }
            setError(true);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FieldFW
                  type="text"
                  required
                  id="code"
                  name="code"
                  placeholder="MFA Code"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => submitForm()}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </Fragment>
  );
}
