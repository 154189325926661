import { Chart } from 'chart.js/auto'

export const VF_RED = 'rgb(230, 0, 0)';
export const VF_GREY = 'rgb(84, 87, 90)';

// Colors provided by Vodafone to be used in our charts.

// This is meant to be used in charts where 5 colors are needed.
export const CHART_COLORS = [
  'rgb(156, 42, 160)', // red violet
  'rgb(0, 176, 202)', // aqua blue
  'rgb(168, 180, 0)', // spring green
  'rgb(235, 151, 0)', // orange
  'rgb(0, 0, 0)', // black
];

// These are meant to be used in charts where we need two sets of 5 colors for different data types.
// E.g. Answered vs Not Answered
export const CHART_COLORS_SET_1 = [
  VF_RED,
  'rgb(156, 42, 160)', // red violet
  'rgb(0, 176, 202)', // aqua blue
  'rgb(168, 180, 0)', // spring green
  'rgb(254, 203, 0)', // lemon yellow
];
export const CHART_COLORS_SET_2 = [
  VF_GREY,
  'rgb(94, 39, 80)', // aubergine
  'rgb(0, 124, 146)', // turquoise
  'rgb(235, 151, 0)', // orange
  'rgb(0, 0, 0)', // black
];

/* Common utility/helper functions for Chart components */

// Destroy existing Chart Instance to reuse <canvas> element
export function destroyChartInstance(chartName: string) {
  let chartStatus = Chart.getChart(chartName); // <canvas> id
  if (chartStatus != undefined) {
    chartStatus.destroy();
  }
}

/**
 * Display title with given text
 */
export function optionsPluginsTitle(text: string) {
  return {
    display: true,
    text: text
  }
}

/**
 * Show datalabels within the bars
 */
export const optionsPluginsDatalabels = {
  color: 'white',
  // hide "0" labels
  formatter: function(value: string) {
    return value === "0" ? "" : value;
  }
}

/**
 * Stacked x and y axes
 */
export const optionsScales = {
  x: {
    stacked: true,
  },
  y: {
    stacked: true
  }
}
