/**
 * @file styles.ts
 * @description Defines CSS styles for CampaignInfo
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: 20,
  },
  title: {
    margin: theme.spacing(0, 0, 2),
  },
  antiSpoofingButtons: {
    display: "flex",
    justifyContent: "flex-start",
    margin: theme.spacing(1, 0, 0),
    alignItems: "top"
  },
  referenceCampaignDiv: {
    marginTop: 30
  },
}));
export default useStyles;
