/**
 * @file MSISDNsTable.tsx
 * @description MSISDNsTable component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState } from "react";
import { Typography } from "@mui/material";
import msisdnService, { MSISDNType } from "../../../services/msisdnService";
import { transformMsisdnForDisplaying } from "../../../utils/msisdnUtils";
import { CampaignType } from "../../../services/campaignService";
import { DataGrid, GridCellParams, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import useStyles, { sxProps } from "./styles";
import { useMutation, useQueryClient } from "react-query";
import { invalidateMsisdnQueries } from "../../../utils/invalidateQueries";
import SearchFieldToolbar from "../../common/DataGrid/SearchFieldToolbar";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { alertDialogTitle, alertDialogContent } from "../../../utils/utils";

/**
 * Props
 * @typedef {{data: MSISDNType[], campaignsMap: Map<string, CampaignType>}} Props
 */
interface Props {
  data: MSISDNType[];
  campaignsMap: Map<string, CampaignType>;
  setSuccessMessage: (s: string) => void;
  setErrorMessage: (s: string) => void;
}

/**
 * Renders MSISDNsTable component
 * @param props component props @see Props
 * @returns {React.Component} MSISDNsTable component
 */
export default function MSISDNsTable(props: Props) {
  const classes = useStyles();
  const { role } = useUserInfo();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const queryClient = useQueryClient();
  const { mutateAsync: deleteMSISDN } = useMutation((ids: string[]) => Promise.all(ids.map(msisdnService.delete)), {
    onSuccess: (results) => {
      props.setSuccessMessage("Deleted " + results.length + " Numbers(s)!");
    },
    onSettled: () => {
      invalidateMsisdnQueries(queryClient);
    }
  });

  if (props.data.length === 0) return <Typography marginTop="1em">No Numbers</Typography>;

  const columns: GridColDef[] = [
    { field: 'number', headerName: 'Number', width: 450 },
    {
      field: 'campaign',
      headerName: 'Campaign',
      width: 375,
      // We need this to add the desired style to the column,
      // which is to show the text in two different colors for Reference Campaigns.
      renderCell: (params) => {
        return params.row.referenceCampaign ? 
          <Typography className={classes.renderCellDiv} color="grey">{params.row.campaign}
            <Typography color="red" display={'inline'}> (Reference Campaign) </Typography>
          </Typography>
          : params.row.name;
      }
    },
    { field: 'displayName', headerName: 'Display Name', width: 265 }
  ];

  const noCampaignAssigned = "No campaign assigned";
  const notEnriched = "Will not be enriched";
  const noDisplayName = "No display name";

  const rows = props.data.map((msisdn) => {
    const campaignName = msisdn.campaign_id ? props.campaignsMap.get(msisdn.campaign_id)?.name : "";
    const campaignDisplayName = msisdn.campaign_id ? props.campaignsMap.get(msisdn.campaign_id)?.display_name : "";
    const referenceCampaign = msisdn.campaign_id && !campaignDisplayName ? true : false;

    return {
      id: msisdn.id,
      number: transformMsisdnForDisplaying(msisdn),
      campaignId: msisdn.campaign_id,
      campaign: campaignName || noCampaignAssigned,
      displayName: campaignDisplayName || (referenceCampaign ? noDisplayName : notEnriched),
      referenceCampaign: referenceCampaign,
    }
  });

  const canDelete: boolean = role === "vodafone-admin" || role === "partner-manager" || role === "brand-manager";

  return (
    <DataGrid className={classes.datagrid}
      // Style for search toolbar
      sx={sxProps.datagridToolbar}
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
      checkboxSelection={canDelete}
      rows={rows}
      columns={columns}
      // color "no campaign" and "reference campaign" cells
      getCellClassName={(params: GridCellParams<any, any, any>) => {
        return (params.field === 'campaign' || params.field === 'displayName') && (!params.row.campaignId || params.row.referenceCampaign) ? 'color' : '';
      }}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      pageSizeOptions={[10, 20, 50, 100]}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      slots={{
        toolbar: SearchFieldToolbar,
      }}
      slotProps={{
        toolbar: {
          alertDialogTitle: alertDialogTitle("Number", rowSelectionModel.length),
          alertDialogContent: alertDialogContent("Number", rowSelectionModel.length),
          rowSelection: rowSelectionModel,
          deleteFunction: canDelete ? deleteMSISDN : undefined,
          setErrorMessage: props.setErrorMessage,
        },
      }}
    />
  );
}
