/**
 * @file styles.ts
 * @description Defines CSS styles for DatGridLogo and Toolbar
 * @author Rafael Mello
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme: Theme) => ({
  mediaContainer: {
    display: "flex",
    justifyContent: "center",
  },
  media: {
    borderRadius: "50%",
    width: 50
  },
  filters: {
    marginLeft: "auto"
  }
}));
export default useStyles;
