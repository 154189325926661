/**
 * @file configurationService.ts
 * @description Interface/client for interacting with /configuration API.
 * @requries ./axiosClient
 * @author Rafael Mello
 */

import { client } from "./axiosClient";

export type Config = {
  pai_discovery_number?: string;
};

const service = {
  getConfiguration: async (): Promise<Config> => {
    let res = await client.get("/configuration");
    return res.data;
  },
};

export default service;
