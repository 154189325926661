/**
 * @file OnLoad.tsx
 * @description Component for displaying page loading
 * @author Harry Rhodes
 * @exports React.Component
 */
import { CircularProgress } from "@mui/material";
import useStyles from "../../style";
/**
 * Renders OnLoad wrapper component
 * @returns {React.Component} OnLoad wrapper component
 */
export default function OnLoad() {
  const classes = useStyles();
  return (
    <div className={classes.centerDiv}>
      <CircularProgress color="primary" />
    </div>
  );
}
