/**
 * @file styles.ts
 * @description Defines CSS styles for StepperWrapper component
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
  stepper: {
    marginTop: 30,
    marginLeft: 15,
    marginRight: 15,
  },
  container: {
    marginTop: 30,
    marginLeft: 30,
    marginRight: 30,
  },
  controls: {
    marginTop: 30,
    marginLeft: 30,
    marginBottom: 30,
  },
  containerCenter: {
    textAlign: "center",
  },
}));

export default useStyles;
