import { Ref } from "react";
import { Grid, Typography } from "@mui/material";
import FieldFW from "../../../common/templates/forms/FieldFW";
import { Formik, Form } from "formik";
import { PartnerCreateType } from "../../../../services/partnerService";
import { validationSchema } from "../../common/validationSchema";
import useStyles from "./styles";
import LogoCropper from "../../../common/LogoCropper";

interface Props {
  setPartner: (partner: PartnerCreateType) => void;
  setTarget: (target: File) => void;
  handleNext?: () => void;
  innerRef?: Ref<any>;
}

export default function PartnerInfo(props: Props) {
  const classes = useStyles();
  return (
    <Formik
      innerRef={props.innerRef}
      validateOnChange={true}
      initialValues={{
        name: "",
        alias: "",
        account_email: "",
        pai_contingent: "",
        opco_ids: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(data: PartnerCreateType, { setSubmitting }) => {
        setSubmitting(true);
        data.alias = data.alias.toUpperCase();
        props.setPartner(data);
        setSubmitting(false);
        if (props.handleNext) {
          props.handleNext();
        }
      }}
    >
      <Form>
        <div className={classes.container}>
          <Grid container spacing={2}>
            <Grid item container xs={12} md={6}>
              <Grid item container spacing={2} xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.title}>
                    Partner Details
                  </Typography>
                  <FieldFW
                    type="text"
                    required
                    id="name"
                    name="name"
                    placeholder="Name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldFW
                    type="text"
                    required
                    id="alias"
                    name="alias"
                    placeholder="Alias"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FieldFW
                    type="number"
                    required
                    id="pai_contingent"
                    name="pai_contingent"
                    placeholder="PAI Contingent"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  Upload Logo (Optional)
                </Typography>
                <LogoCropper setTarget={props.setTarget} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Form>
    </Formik>
  );
}
