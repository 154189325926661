import { Fragment, useState } from "react";
import { Button, Avatar, Typography, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import AlertSnackback from "../../common/templates/feedback/AlertSnackbar";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import useStyles from "../styles";
import { useNavigate } from "react-router-dom";

interface Props {
  username: string;
  verify: boolean;
}
export default function CodeConfirmation(props: Props) {
  const classes = useStyles();
  const { username, verify } = props;
  const [error, setError] = useState(false);
  let navigate = useNavigate();
  const routeChange = () => {
    let path = "/signin/confirm";
    navigate(path, {
      state: {
        username: username,
        verify: verify,
      },
    });
  };

  return (
    <Fragment>
      <div>
        <Avatar className={classes.avatar}>
          <MarkEmailUnreadIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Check Email
        </Typography>
      </div>
      <Formik
        initialValues={{
          username: "",
        }}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          setSubmitting(false);
          routeChange();
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  A verification code has just been sent to the email address
                  associated with your account.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => submitForm()}
                  disabled={isSubmitting}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <AlertSnackback
        message="Something went wrong. Please try again."
        type="error"
        open={error}
        setOpen={setError}
      />
    </Fragment>
  );
}
