/**
 * @file OPCOAdminRoutes.tsx
 * @description Defines routes for OPCO-Admin role.
 * @requries react-router-dom
 * @author Harry Rhodes
 * @exports BrowserRouter
 */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../../../../../pages/Dashboard";
import Aggregators from "../../../../../pages/Aggregators";
import Aggregator from "../../../../../pages/Aggregator";
import Partners from "../../../../../pages/Partners";
import Partner from "../../../../../pages/Partner";
import Brand from "../../../../../pages/Brand";
import Assets from "../../../../../pages/Assets";
import NotFound from "../../../../../pages/error/NotFound";
import Campaign from "../../../../../pages/Campaign";
import OPCO from "../../../../../pages/OPCO";
import ManageAggregators from "../../../../../pages/ManageAggregators";
import ManagePartners from "../../../../../pages/ManagePartners";
import Approvals from "../../../../../pages/Approvals";
import Approval from "../../../../../pages/Approval";
import Users from "../../../../../pages/Users";
import User from "../../../../../pages/User";
import { isAggregatorsFeatureEnabled, isApprovalsFeatureEnabled, isAssetsFeatureEnabled } from "../../../../../utils/featureToggle";

/**
 * Props
 * @typedef {{opcoId: string}} Props
 */
interface Props {
  opcoId: string;
}
/**
 * Renders OPCOAdminRoutes
 * @param props component props @see Props
 * @returns {React.Component} OPCOAdminRoutes
 */
export default function OPCOAdminRoutes(props: Props) {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        {/* OPCO Info Routes */}
        <Route path={"/opcoInfo"} element={<OPCO opcoId={props.opcoId} />} />
        {/* OPCO Info View All Partners Routes*/}
        <Route path={"/opcoInfo/partners"} element={<Partners />} />
        <Route path={"/opcoInfo/partners/:partnerId"} element={<Partner />} />
        <Route path={"/opcoInfo/partners/:partnerId/brands/:brandId"} element={<Brand />} />
        <Route path={"/opcoInfo/partners/:partnerId/brands/:brandId/campaigns/:campaignId"} element={<Campaign />} />
        {/* View All Routes */}
        <Route path={"/partners"} element={<Partners />} />
        <Route path={"/partners/manage"} element={<ManagePartners opcoId={props.opcoId} />} />
        <Route path={"/partners/:partnerId"} element={<Partner />} />
        <Route path={"/partners/:partnerId/brands/:brandId"} element={<Brand />} />
        <Route path={"/partners/:partnerId/brands/:brandId/campaigns/:campaignId"} element={<Campaign />} />
        <Route path={"/users"} element={<Users />} />
        <Route path={"/users/:username"} element={<User />} />
        {isAssetsFeatureEnabled() &&
          <>
            <Route path={"/opcoInfo/partners/assigned/:partnerId/brands/:brandId/assets"} element={<Assets />} />
            <Route path={"/opcoInfo/partners/:partnerId/brands/:brandId/assets"} element={<Assets />} />
            <Route path={"/partners/assigned/:partnerId/brands/:brandId/assets"} element={<Assets />} />
            <Route path={"/partners/:partnerId/brands/:brandId/assets"} element={<Assets />} />
          </>
        }
        {isAggregatorsFeatureEnabled() &&
          <>
            <Route path={"/aggregators/assigned"} element={<Aggregators opcoId={props.opcoId} />} />
            <Route path={"/aggregators/assigned/:aggregatorId"} element={<Aggregator opcoId={props.opcoId} />} />
            <Route path={"/aggregators"} element={<Aggregators />} />
            <Route path={"/aggregators/manage"} element={<ManageAggregators opcoId={props.opcoId} />} />
            <Route path={"/aggregators/:aggregatorId"} element={<Aggregator opcoId={props.opcoId} />} />
          </>
        }
        {isApprovalsFeatureEnabled() &&
          <>
            <Route path={"/approvals"} element={<Approvals opcoId={props.opcoId} />} />
            <Route path={"/approvals/campaigns"} element={<Approvals opcoId={props.opcoId} />} />
            <Route path={"/approvals/campaigns/:campaignId"} element={<Approval />} />
            <Route path={"/approvals/approved"} element={<Approvals opcoId={props.opcoId} />} />
            <Route path={"/approvals/approved/campaigns"} element={<Approvals opcoId={props.opcoId} />} />
            <Route path={"/approvals/approved/campaigns/:campaignId"} element={<Approval />} />
            <Route path={"/approvals/pending"} element={<Approvals opcoId={props.opcoId} />} />
            <Route path={"/approvals/pending/campaigns"} element={<Approvals opcoId={props.opcoId} />} />
            <Route path={"/approvals/pending/campaigns/:campaignId"} element={<Approval />} />
            <Route path={"/approvals/rejected"} element={<Approvals opcoId={props.opcoId} />} />
            <Route path={"/approvals/rejected/campaigns"} element={<Approvals opcoId={props.opcoId} />} />
            <Route path={"/approvals/rejected/campaigns/:campaignId"} element={<Approval />} />
          </>
        }
        <Route path="*" element={<NotFound authenticated={true} />} />
      </Routes>
    </BrowserRouter>
  );
}
