/**
 * @file FieldFW.tsx
 * @description A custom Field component based on a Material UI components with Formik
 * @author Harry Rhodes
 * @exports React.Component
 */
import React from "react";
import { FieldAttributes, useField } from "formik";
import TextField from "@mui/material/TextField";
/**
 * Renders FieldFW component
 * @param FieldAttributes Formik field attributes @see Formik
 * @returns {React.Component} FieldFW component
 */

type FieldProps = {
  variant: "outlined" | "standard" | "filled";
  multiline?: boolean;
  readonly?: boolean;
  shouldAutoFocus?: boolean;
  // if this is present, it will be called when "Enter" is pressed
  submitForm?: (() => Promise<void>);
} & FieldAttributes<{}>;

const FieldFW: React.FC<FieldProps> = ({
  type,
  required,
  placeholder,
  variant,
  multiline,
  readonly,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const shouldAutoFocus = props.shouldAutoFocus ? props.shouldAutoFocus : false;

  return (
    <TextField
      autoFocus={shouldAutoFocus}
      type={type}
      label={placeholder}
      {...field}
      helperText={errorText}
      error={!!errorText}
      required={required}
      fullWidth
      variant={variant}
      multiline={multiline}
      disabled={readonly}
      onKeyPress={(e) => {
        if (props.submitForm && e.key === "Enter") {
          props.submitForm();
        }
      }}
    />
  );
};
export default FieldFW;
