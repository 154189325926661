import { UseMutateAsyncFunction } from "react-query";
import {
  BulkUploadError,
  BulkUploadSummary,
  BulkUploadRow,
  CampaignProperties,
  newBulkUploadError,
  setCampaignProperties,
  newCampaignValidationError
} from "../components/MSISDNs/BulkUploadProgress/BulkUploadProgress";
import { BrandType } from "../services/brandService";
import { CampaignType, ApprovalStatus, AntiSpoofingType } from "../services/campaignService";
import { MSISDNType } from "../services/msisdnService";
import { extractErrorMessage } from "./utils";
import { validationSchema as campaignValidationSchema } from "../components/Campaigns/common/validationSchema";
import { validationSchema as msisdnValidationSchema } from "../components/MSISDNs/BulkUploadProgress/validationSchema";

export type BulkUploadCallbacks = {
  /**
   * Called every time any kind of error is detected during Bulk Upload execution.
   */
  pushBulkUploadError(newError: BulkUploadError): void,
  /**
   * Called to update the Bulk Upload progress after a row is processed or when a final state is reached.
   */
  updateBulkUploadProgress(summary: BulkUploadSummary): void,
  /**
   * Called to create a new Campaign.
   */
  createCampaign: UseMutateAsyncFunction<any, unknown, CampaignType, unknown>,
  /**
   * Called to update an existing Campaign.
   */
  updateCampaign: UseMutateAsyncFunction<any, unknown, CampaignType, unknown>,
  /**
   * Called to create a new MSISDN.
   */
  createMSISDN: UseMutateAsyncFunction<any, unknown, MSISDNType, unknown>,
  /**
   * Called to update an existing MSISDN.
   */
  updateMSISDN: UseMutateAsyncFunction<any, unknown, MSISDNType, unknown>,
}

const isReferenceCampaignValues = [true, "true", "yes", "ja", "on", "1", 1];
const isNotReferenceCampaignValues = [false, "false", "no", "nein", "off", "0", 0];

export const freshSummary: BulkUploadSummary = {
  newNumbers: 0,
  updatedNumbers: 0,
  unchangedNumbers: 0,
  newCampaigns: 0,
  updatedCampaigns: 0,
  unchangedCampaigns: 0
};
Object.freeze(freshSummary);

/**
 * Method extracted from Brand.tsx so it can be tested.
 */
export async function bulkUpload(
  rows: BulkUploadRow[],
  brand: BrandType,
  msisdns: MSISDNType[],
  campaigns: CampaignType[],
  callbacks: BulkUploadCallbacks
): Promise<string> {

  let summary: BulkUploadSummary = { ...freshSummary }; // clone object
  let reportedError: boolean = false;

  const uniquePais: Set<string> = new Set(msisdns.map((number) => number.pai));

  const paiAndFromCombinations: Set<string> = new Set();

  // Map from Campaign Name to Campaign properties.
  const campaignPropertiesMap: Map<string, CampaignProperties> = new Map();

  for (const row of rows) {
    // Save "yes", "no" or undefined, instead of "on", "off", etc. before doing any validation.
    // This is necessary to always show a consistent Reference Campaign value if any error occurs.
    try {
      row.campaignReferenceCampaign = parseReferenceCampaign(row.campaignReferenceCampaign);
    } catch (e: any) {
      // Ignore, will be checked again alter
    }

    const pai = row.pai;
    const from = row.from;

    try {
      // if FROM is defined, PAI cannot be empty
      if (from && !pai) {
        throw new Error("PAI is required when From is defined");
      }

      if (pai) {
        // Check for duplicated "PAI"/"From" combinations.
        // Multiple rows with empty "PAI" and empty "From" are valid.
        // This is needed for creating/updating multiple Campaigns that have no Numbers assigned.
        // We only need to worry about PAI/From combinations if PAI is defined.
        // Otherwise:
        // 1. PAI and From are NOT defined: row with empty number.
        // 2. PAI is NOT defined and From is defined: row will generate a "PAI is required when FROM is defined" error.
        const combination = pai + (from ? "/" + from : "");
        if (paiAndFromCombinations.has(combination)) {
          throw new Error("There must be no duplicate PAI/From combinations in Excel file: " + combination);
        } else {
          paiAndFromCombinations.add(combination);
        }

        // Validate PAI and From fields.
        // To reuse the Yup objects, we need to do some preparation of the validated object:
        // 1. Pass is_pai_prefix and is_from_prefix.
        // 2. Remove "*" from "pai" and "from".
        const isPaiPrefix: boolean = pai.endsWith("*");
        const isFromPrefix: boolean = from?.endsWith("*") || false;
        await msisdnValidationSchema.validate({
          is_pai_prefix: isPaiPrefix,
          pai: isPaiPrefix ? pai.slice(0, -1) : pai,
          is_from_prefix: isFromPrefix,
          from: isFromPrefix ? from!.slice(0, -1) : (from || undefined),
        });

        uniquePais.add(pai);
      }
    } catch (validationError: any) {
      callbacks.pushBulkUploadError(newBulkUploadError(validationError.message, row));
      reportedError = true;
    }

    const campaignName = row.campaignName;
    const campaignDisplayName = row.campaignDisplayName;
    const campaignDescription = row.campaignDescription;
    const campaignReferenceCampaign = row.campaignReferenceCampaign;

    try {
      // if any Campaign Property is defined, "Campaign Name" must be defined too
      if ((campaignDisplayName || campaignDescription || campaignReferenceCampaign) && !campaignName) {
        throw new Error("A row containing any Campaign property must also have a Campaign Name");
      }

      // We need to merge the campaign properties from all rows.
      // For each Campaign Name value and each Campaign Property column,
      // it is not allowed to have 2 different, non-empty campaign property values.
      if (campaignName) {
        let campaignProperties = campaignPropertiesMap.get(campaignName);

        // If no data exists for the Campaign name, then start with empty data.
        if (!campaignProperties) {
          campaignProperties = {
            campaignDisplayName: undefined,
            campaignDescription: undefined,
            campaignReferenceCampaign: undefined
          };
          campaignPropertiesMap.set(campaignName, campaignProperties);
        }

        // Check for invalid Reference Campaign values.
        parseReferenceCampaign(campaignReferenceCampaign);

        // Try to merge data from the current row
        setCampaignProperties(campaignProperties, row);
      }
    } catch (error: any) {
      callbacks.pushBulkUploadError(newBulkUploadError(error.message, row));
      reportedError = true;
    }

  }

  // Validate Campaign fields
  for (const mapEntry of Array.from(campaignPropertiesMap.entries())) {
    const campaignName = mapEntry[0];
    const campaignProperties = mapEntry[1];

    try {

      if (campaignProperties.campaignReferenceCampaign === "yes" && campaignProperties.campaignDisplayName) {
        throw new Error("Campaign named “" + campaignName + "” cannot have a display name and be a Reference Campaign");
      }

      let existentCampaign = campaigns.find((campaign) => campaign.name === campaignName);

      if (existentCampaign) {
        if (existentCampaign.display_name) {
          // Normal Campaign exists

          if (campaignProperties.campaignReferenceCampaign === "yes") {
            // Cannot change normal campaign to reference
            throw new Error("Existent Campaign named “" + campaignName + "” cannot be changed to a Reference Campaign");
          }

        } else {
          // Reference Campaign exists

          if (campaignProperties.campaignDisplayName || campaignProperties.campaignReferenceCampaign === "no") {
            // Cannot change reference campaign to normal
            throw new Error("Existent Reference Campaign named “" + campaignName + "” cannot be changed to a Campaign with display name");
          }
        }
      }

      // To reuse the Yup objects, we need to do some preparation of the validated object:
      // 1. Pass campaign_anti_spoofing_enabled = false. We do not care about anti-spoofing here.
      // 2. Already set the values that will be used in create/update.
      // 3. If the campaign does not exist and no "Display Name" is set in any row for this campaign,
      // then "Campaign Name" must be validated as a display name, because in this case the "Campaign Name" will be used as "Display Name".
      const props = getCampaignProperties(campaignName, existentCampaign, campaignProperties);

      const validatedObject = {
        name: campaignName,
        reference_campaign: !props.display_name,
        display_name: props.display_name,
        desc: props.desc,
        campaign_anti_spoofing_enabled: false
      };
      await campaignValidationSchema.validate(validatedObject);

    } catch (error: any) {
      callbacks.pushBulkUploadError(newCampaignValidationError(error.message, campaignName, campaignProperties));
      reportedError = true;
    }

  }

  // Count the number of new PAIs up front.
  // The whole bulk upload fails if it would exhaust the PAI contingent.
  if (uniquePais.size > brand.pai_contingent) {
    throw new Error("Bulk Upload was rejected because it would exhaust the PAI Contingent");
  }

  // Check if any error was detected during the client-side validation.
  // A validation error will reject the Bulk Upload before attempting any updates on the server side.
  if (reportedError) {
    return "❌ Upload rejected!";
  }

  // Map from Campaign Name to CampaignType to store campaigns
  // that were already processed during this Bulk Upload operation.
  const processedCampaigns: Map<string, CampaignType> = new Map();

  // Set containing the names of failed campaigns.
  // Rows with these campaigns should not be processed.
  const failedCampaigns = new Set();

  for (const row of rows) {
    const campaignName = row.campaignName;
    const pai = row.pai;
    const from = row.from;

    let rowCampaign: CampaignType | undefined = undefined;
    if (campaignName) {

      if (failedCampaigns.has(campaignName)) {
        callbacks.pushBulkUploadError(
          newBulkUploadError(
            "Row was rejected. Campaign named “" + campaignName + "” failed to be created in a previous row/attempt.",
            row
          )
        );
        reportedError = true;
        continue;
      }

      const campaignProperties: CampaignProperties = campaignPropertiesMap.get(campaignName)!;
      rowCampaign = processedCampaigns.get(campaignName);

      // Create or update campaign if it has not been processed yet
      if (!rowCampaign) {

        let existentCampaign = campaigns.find((campaign) => campaign.name === campaignName);

        const props = getCampaignProperties(campaignName, existentCampaign, campaignProperties);

        if (!existentCampaign) {

          // Campaign needs to be created

          try {
            rowCampaign = await createBulkUploadCampaign(brand, props, callbacks.createCampaign);
            summary.newCampaigns++;
          } catch (err: any) {
            callbacks.pushBulkUploadError(
              newBulkUploadError("Failed to create Campaign '" + campaignName + "': " + extractErrorMessage(err), row)
            );
            // Mark as processed and add it to failedCampaigns set.
            // Rows with this campaign should not be processed.
            processedCampaigns.set(campaignName, rowCampaign!);
            failedCampaigns.add(campaignName);
            reportedError = true;
            continue;
          }

        } else {

          // Campaign exists. Check if it needs to be updated.

          const updatedCampaign = {
            ...existentCampaign,
            name: props.name,
            display_name: props.display_name,
            desc: props.desc
          };

          try {
            rowCampaign = updatedCampaign;

            const mutableFields: Array<keyof CampaignType> = ["name", "display_name", "desc"];
            if (areObjectsDifferent(mutableFields, existentCampaign, updatedCampaign)) {
              await callbacks.updateCampaign(updatedCampaign);
              summary.updatedCampaigns++;
            } else {
              summary.unchangedCampaigns++;
            }
          } catch (err: any) {
            // Campaign will be marked as processed and we continue with the row.
            // Numbers still can/should be assigned to the Campaign.
            callbacks.pushBulkUploadError(
              newBulkUploadError(
                "Failed to update Campaign '" + campaignName + "': " + extractErrorMessage(err) + ". Numbers will still be assigned to it.",
                row
              )
            );
            reportedError = true;
          }

        }

        processedCampaigns.set(campaignName, rowCampaign!);
      }
    }

    if (!pai) {
      // Row with empty number. No need to do anything else.
      callbacks.updateBulkUploadProgress({...summary});
      continue;
    }

    // Check if Number already exists for selected Brand
    let existentNumber: MSISDNType | undefined = undefined;

    for (const existentMsisdn of msisdns) {
      // Note == instead of === because we want null to match undefined
      if (pai == existentMsisdn.pai && from == existentMsisdn.from) {
        existentNumber = existentMsisdn;
        break;
      }
    }

    if (!existentNumber) {

      // Number does not exist

      let campaignId: string | undefined = rowCampaign?.id;

      // Create the number with the associated campaign
      let msisdn : MSISDNType = {
        pai: pai,
        from: from? from : undefined,
        campaign_id: campaignId,
        brand_id: brand.id,
      }

      try {
        await callbacks.createMSISDN(msisdn);
      } catch (err: any) {
        callbacks.pushBulkUploadError(
          newBulkUploadError(extractErrorMessage(err), row)
        );
        reportedError = true;
        continue;
      }
      summary.newNumbers++;

    } else {

      // Number already exists

      if (existentNumber.campaign_id != rowCampaign?.id) {
        // note != instead of !==
        // we do not want an update if both are empty/null/undefined

        // Campaigns (of input and existent number) are different, 
        // number needs to be updated

        let campaignId: string | undefined = rowCampaign?.id;

        let msisdn : MSISDNType = {
          ...existentNumber,
          // We need "from" to be undefined instead of null/empty
          from: existentNumber.from ? existentNumber.from : undefined,
          campaign_id: campaignId,
        };
        try {
          await callbacks.updateMSISDN(msisdn);
        } catch (err: any) {
          callbacks.pushBulkUploadError(
            newBulkUploadError(extractErrorMessage(err), row)
          );
          reportedError = true;
          continue;
        }
        summary.updatedNumbers++;
      } else {
        // number is already in the correct state, do nothing
        summary.unchangedNumbers++;
      }

    }
    callbacks.updateBulkUploadProgress({...summary});
  }
  return reportedError ? "⚠️ Upload completed with error(s)!" : "✅ Upload complete!";
}

function parseReferenceCampaign(input: string | undefined): "yes" | "no" | undefined {
  if (!input) {
    return undefined;
  }
  if (isReferenceCampaignValues.includes(input.toLowerCase())) {
    return "yes";
  }
  if (isNotReferenceCampaignValues.includes(input.toLowerCase())) {
    return "no";
  }
  throw new Error("Campaign has an invalid Reference Campaign value: " + input);
}

/**
 * Assemble campaign data (name, display_name, desc) based on the existing entity (if it exists)
 * and the input from the XLSX file.
 * Returning object can be used in Campaign validation and in Campaign create/update operations.
 */
function getCampaignProperties(
  campaignName: string,
  existentCampaign: CampaignType | undefined,
  campaignProperties: CampaignProperties
): {name: string, display_name: string | undefined, desc: string}  {
  let displayName;
  let desc;
  if (existentCampaign) {
    // If fields are not defined in XLSX and the Campaign exists, use values from existing campaign.
    // displayName: "|| undefined" needed to convert null -> undefined
    displayName = campaignProperties.campaignDisplayName || existentCampaign.display_name || undefined;
    desc = campaignProperties.campaignDescription || existentCampaign.desc;
  } else {
    // If fields are not defined in XLSX and the Campaign does not exist, use the default values.
    // Default for display_name: campaign name
    // Default for desc: "Bulk Upload on YYYY-MM-DD"
    displayName = campaignProperties.campaignDisplayName || (campaignProperties.campaignReferenceCampaign === "yes" ? undefined : campaignName);
    desc = campaignProperties.campaignDescription || "Bulk upload on " + toIsoDate(new Date());
  }
  return {
    name: campaignName,
    display_name: displayName,
    desc: desc
  }
}

/** 
 * Convert a date into an ISO date string like 2023-06-23,
 * using the local time of the date.
 */
function toIsoDate(date: Date): string {
  return date.getFullYear() + "-"
    + ("0" + (date.getMonth() + 1)).slice(-2) + "-"
    + ("0" + date.getDate()).slice(-2);
}

/**
 * Returns true if at least one of the fields in `mutableFields`
 * has a different value between the two objects.
 */
function areObjectsDifferent<T>(mutableFields: Array<keyof T>, dbEntity: any, updateObject: T): boolean {
  // Note == instead of === because we want null to match undefined
  return mutableFields.some((field) => dbEntity[field] != updateObject[field]);
}

async function createBulkUploadCampaign(
  brand: BrandType,
  campaign: {name: string, display_name?: string, desc: string},
  createCampaign: UseMutateAsyncFunction<any, unknown, CampaignType, unknown>
) {
  const newCampaign : CampaignType = {
    name: campaign.name,
    display_name: campaign.display_name,
    desc: campaign.desc,
    brand_id: brand.id,
    approval_status: ApprovalStatus.APPROVED,
    anti_spoofing: AntiSpoofingType.DISABLED
  }
  const result = await createCampaign(newCampaign);
  return {...newCampaign, id: result.id};
}
