/**
 * @file Navigation.tsx Naviagtion component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState, useEffect } from "react";
import clsx from "clsx";
import VFAdminList from "../routing/nav/VFAdminList";
import VFReporterList from "../routing/nav/VFReporterList";
import OPCOAdminList from "../routing/nav/OPCOAdminList";
import PartnerManagerList from "../routing/nav/PartnerManagerList";
import BrandManagerList from "../routing/nav/BrandManagerList";
import BrandReporterList from "../routing/nav/BrandReporterList";
import {
  AppBar,
  CssBaseline,
  Toolbar,
  Drawer,
  Hidden,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import Logo from "../Logo";
import ProfileIcon from "./ProfileIcon";
import SwitchRole from "./ProfileIcon/ProfileMenu/SwitchRole";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useUserContext } from "../../../context/UserContext";
import useStyles from "./styles";

/**
 * Renders Naviagtion component
 * @returns {React.Component} Naviagtion component
 */
export default function Navigation() {
  const theme = useTheme();
  const classes = useStyles();
  const { user } = useUserContext();
  const [listItems, setListItems] = useState<JSX.Element>();
  const [open, setOpen] = useState(false);
  const [openSwitchRole, setOpenSwitchRole] = useState(false);
  const breakpoint = useMediaQuery(theme.breakpoints.down("md"));
  const breakpoint1 = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    /**
     * Dynamically render navigation list depending on assumed role
     */
    const getListItems = () => {
      if (user) {
        if (user.role === "vodafone-admin") {
          setListItems(<VFAdminList />);
        } else if (user.role === "vodafone-reporter") {
          setListItems(<VFReporterList />);
        } else if (user.role === "opco-admin") {
          setListItems(<OPCOAdminList opcoId={user.opcoId as string} />);
        } else if (user.role === "partner-manager") {
          setListItems(
            <PartnerManagerList partnerId={user.partnerId as string} />
          );
        } else if (user.role === "brand-manager") {
          setListItems(
            <BrandManagerList
              partnerId={user.partnerId as string}
              brandId={user.brandId as string}
            />
          );
        } else if (user.role === "brand-reporter") {
          setListItems(
            <BrandReporterList
              partnerId={user.partnerId as string}
              brandId={user.brandId as string}
            />
          );
        }
      }
    };
    getListItems();
  }, [user]);

  const handleClick = () => {
    if (open) {
      setOpen(false);
    } else {
      breakpoint && setOpen(true);
    }
  };

  const handleOpenSwitchRole = () => setOpenSwitchRole(true);
  const handleCloseSwitchRole = () => setOpenSwitchRole(false);

  function getPageTitleLarge(): string {
    if (process.env.REACT_APP_ENVIRONMENT_DESCRIPTION) {
      return "CallerID BusinessPortal (" + process.env.REACT_APP_ENVIRONMENT_DESCRIPTION + ")";
    } else {
      return "CallerID Business Portal";
    }
  }

  return (
    <>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleClick}
            className={clsx(classes.menuButton, open)}
            size="large"
          >
            <Logo />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="secondary"
            noWrap
            className={classes.title}
          >
            {!breakpoint1
              ? getPageTitleLarge()
              : "CallerID Portal"}
          </Typography>
          <div>
            <ProfileIcon openSwitchRole={handleOpenSwitchRole} />
          </div>
        </Toolbar>
      </AppBar>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.toolbarIcon} />

          {listItems}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          anchor="left"
          classes={{
            paper: classes.drawerPaperPermanent,
          }}
          variant="persistent"
          open
        >
          <div className={classes.toolbarIcon}></div>
          <Divider />
          {listItems}
        </Drawer>
      </Hidden>
      <SwitchRole open={openSwitchRole} handleClose={handleCloseSwitchRole} />
    </>
  );
}
