import Subtitle from "../../common/titles/Subtitle";
import Chip from "@mui/material/Chip";
import { OPCOType } from "../../../services/opcoService";
import useStyles from "../common/style";
interface Props {
  opcos: OPCOType[];
  setCurrentOPCOs: (x: any) => void;
  setAvailableOPCOs: (x: any) => void;
}
export default function OPCOChipList(props: Props) {
  const classes = useStyles();

  const handleDelete = (data: OPCOType) => () => {
    props.setAvailableOPCOs((prevState: any) => [...prevState, data]);
    props.setCurrentOPCOs((currentState: any) =>
      currentState.filter((opco: OPCOType) => opco !== data)
    );
  };

  if (props.opcos?.length === 0) return <div></div>;
  return (
    <>
      <Subtitle>Active OPCOs</Subtitle>
      <div className={classes.root}>
        {props.opcos?.map((data) => {
          return (
            <li key={data.id}>
              <Chip
                label={data.name}
                color="primary"
                onDelete={handleDelete(data)}
                className={classes.chip}
              />
            </li>
          );
        })}
      </div>
    </>
  );
}
