import { Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";

/**
 * The component used for the transition after clicking on "Add Entity" buttons
 * and before opening "Entity Designer" dialogs.
 * For more information: https://mui.com/material-ui/transitions/
 */
export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
