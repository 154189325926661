import { Breadcrumbs, Typography, Link } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import partnerService from "../../../../../services/partnerService";
import brandService from "../../../../../services/brandService";
import aggregatorService from "../../../../../services/aggregatorService";
import campaignService from "../../../../../services/campaignService";
import opcoService from "../../../../../services/opcoService";
import userService from "../../../../../services/userService";
import { useState, useEffect } from "react";

export default function Location() {
  const location = useLocation();

  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  /**
   * A bean holding a breadcrumb to be displayed
   */
  class BreadCrumb {
    constructor(readonly text: string, readonly link: string, readonly isClickable: boolean) {}
  }

  /**
   * Transforms parts of the URL path into "pretty" parsed strings to be displayed on the Breadcrumbs.
   * If the URL part is an UUID, just return it as it is.
   */
  async function parseString(str: string, prev: string, beforePrev: string): Promise<string> {
    switch (str) {
      case "opcos":
        return "OPCOs";
      case "opcoInfo":
        return "OPCO Info";
      case "partnerInfo":
        return "Partner Info";
      case "brandInfo":
        return "Brand Info";
      case "chartDesigner":
        return "Chart Designer";
      default:
        if (uuidRegex.test(str) && prev) {
          return resolveUuidToNameByUrlType(str, prev, beforePrev)
            // in case resolution fails with some error, we just return the UUID as-is
            .catch(() => { return str; });
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }

  /**
   * Resolves a UUID from the URL to the name of the respective entity via a DB lookup
   * @param uuid the UUID where we want to learn the name
   * @param urlType the part of the URL that precededs the UUID, e.g. "partners" to help the method 
   *  figure out what the UUID refers to.
   * @param beforeUrlType (optional) the part of the URL that precedes the {@link urlType}. 
   *  Sometimes URLs are built in a way that we need to look back twice to figure out what the UUID refers to, 
   *  e.g. `/partners/assigned/UUID`
   * @returns the name of the entity or the UUID if it cannot be resolved
   */
  async function resolveUuidToNameByUrlType(uuid: string, urlType: string, beforeUrlType?: string): Promise<string> {

    // if the URL contains "assigned" in the last place, look back once more, it can be an assigned partner e.g.
    if (beforeUrlType && urlType === "assigned") {
      // TODO check if there are any other cases like "assigned", where we have a "sub-URL"
      urlType = beforeUrlType;
    }

    switch (urlType) {
      case "partners":
        return resolvePartner(uuid);
      case "aggregators":
        return resolveAggregator(uuid);
      case "brands":
        return resolveBrand(uuid);
      case "campaigns":
        return resolveCampaign(uuid);
      case "opcos":
        return resolveOpco(uuid);
      case "users":
        return resolveUser(uuid);
      default:
        // if we can't resolve it, we fall back to the UUID
        return uuid;
    }
  }

  async function resolvePartner(uuid: string) {
    return partnerService.getSingle(uuid)
      .then((r) => {
        return r.name ? r.name : uuid;
      });
  }

  async function resolveBrand(uuid: string) {
    return brandService.getSingle(uuid)
      .then((r) => {
        return r.name ? r.name : uuid;
      });
  }

  async function resolveAggregator(uuid: string) {
    return aggregatorService.getSingle(uuid)
      .then((r) => {
        return r.name ? r.name : uuid;
      });
  }

  async function resolveCampaign(uuid: string) {
    return campaignService.getSingle(uuid)
      .then((r) => {
        return r.name ? r.name : uuid;
      });
  }

  async function resolveOpco(uuid: string) {
    return opcoService.getSingle(uuid)
      .then((r) => {
        return r.name ? r.name : uuid;
      });
  }

  async function resolveUser(uuid: string) {
    return userService.getSingle(uuid)
      .then((r) => {
        return r.Email ? r.Email : uuid;
      });
  }

  // our array of breadcrumbs to be passed to the render function
  const [breadCrumbs, setBreadcrumbs] = useState<BreadCrumb[]>([]);

  /**
   * Loops through the parts of the path, waiting for each to be ready, resolves their UUIDs or names to something to display.
   */
  const makeCrumbs = (async () => {
    // split our browser location into parts
    let pathnames = location.pathname.split("/").filter((x) => x);

    let crumbs: BreadCrumb[] = [];
    for await (const [index, value] of pathnames.entries()) {
      const prev: string = index > 0 ? pathnames[index - 1] : "";
      const beforePrev: string = index > 1 ? pathnames[index - 2] : "";
      const last = index === pathnames.length - 1;
      const to = `/${pathnames.slice(0, index + 1).join("/")}`;
      const text = await parseString(value, prev, beforePrev);
      crumbs[index] = new BreadCrumb(text, to, text === "Campaigns" || text === "Brands" ? false : !last);
    }
    // set the state
    setBreadcrumbs(crumbs);
  });

  // this is called when the breadcrumbs are first rendered and will update them accordingly
  useEffect(() => {
    makeCrumbs();
    // clean up after ourselves
    return () => {
      setBreadcrumbs([]);
    }
  },
  // It is necessary to add "location" as a dependency here to make the breadcrumbs to be refreshed properly. See https://rncorp.atlassian.net/browse/ERBT-6412
  // FIXME useEffect is executed twice, even with an empty dependency array - we want to avoid that.
  // Also, it actually generates a compiler warning in webpack:
  // React Hook useEffect has a missing dependency: 'makeCrumbs'. Either include it or remove the dependency array  react-hooks/exhaustive-deps
  [location]);

  return (
    <Breadcrumbs aria-label="Breadcrumb" separator=">">
      <Link color="inherit" component={RouterLink} to="/">
        Home
      </Link>
      {breadCrumbs.map((crumb) => {
        return crumb.isClickable ? (
          <Link color="inherit" component={RouterLink} to={crumb.link} key={crumb.link} state={location.state} >
            {crumb.text}
          </Link>
        ) : (
          <Typography color="textPrimary" key={crumb.link}>
            {crumb.text}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
}
