/**
 * @file utils.ts A collection of reusable/useful functions used across the UI
 * @author Harry Rhodes
 */

import axios, { AxiosError } from "axios";
import brandService from "../services/brandService";
import opcoService from "../services/opcoService";
import partnerService from "../services/partnerService";
import { Storage } from "aws-amplify";

/**
 * Parses combo box values to satisfy material UIs limitations
 * on handling null values
 * @param {unknown} value - Combo value to be parsed
 * @returns {string | null} Parsed combo value
 */
export function parseComboValue(value: unknown) {
  if (value === "null" || !value) {
    return null;
  } else {
    return value as string;
  }
}
export function parseNullComboValue(value: unknown) {
  if (value === "null" || !value) {
    return [];
  } else {
    return value as string[];
  }
}
/**
 * Parses given group name and returns the role
 * @param {string} name - group name to be parsed
 * @returns {string} Role
 */
export function parseGroupName(name: string) {
  let r: string;
  if (name === "vodafone-admin") {
    r = "vodafone-admin";
  } else if (name === "vodafone-reporter") {
    r = "vodafone-reporter";
  } else if (name.endsWith("-opco-admin")) {
    r = "opco-admin";
  } else if (name.endsWith("-aggregator-admin")) {
    r = "aggregator-admin";
  } else if (name.endsWith("-partner-manager")) {
    r = "partner-manager";
  } else if (name.endsWith("-brand-manager")) {
    r = "brand-manager";
  } else if (name.endsWith("-brand-reporter")) {
    r = "brand-reporter";
  } else {
    r = "";
  }
  return r;
}
/**
 * Uses given group name to get the name of the corresponding entity 
 * from the DB, parse it and return it in a prettier format
 * @param {string} groupName - group name to be parsed
 * @returns {string} Role
 */
export async function prettyParseGroupName(groupName: string) {
  let r: string;
  let split = groupName.split("-");
  if (groupName === "vodafone-admin") {
    r = "Vodafone Admin";
  } else if (groupName === "vodafone-reporter") {
    r = "Vodafone Reporter";
  } else if (groupName.endsWith("-opco-admin")) {
    let name = await opcoService.getName(split[0]);
    r = name ? name + " OPCO Admin" : "";
  } else if (groupName.endsWith("-aggregator-admin")) {
    // TODO: Implement aggregator role
    r = "";
  } else if (groupName.endsWith("-partner-manager")) {
    let name = await partnerService.getName(split[0]);
    r = name ? name + " Partner Manager" : "";
  } else if (groupName.endsWith("-brand-manager")) {
    let name = await brandService.getName(split[1]);
    r = name ? name + " Brand Manager" : "";
  } else if (groupName.endsWith("-brand-reporter")) {
    let name = await brandService.getName(split[1]);
    r = name ? name + " Brand Reporter" : "";
  } else {
    r = "";
  }
  return r;
}
/**
 * Coverts base64 url into File object
 * @param {string} url - Source of content
 * @param {string} filename - Name of outputted file
 * @param {string} mimeType - Type of outputted file
 * @returns {string} Role
 */
export function URLToFile(url: string, filename: string, mimeType: string) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

/** 
 * Extract the error message from the `error` object.
 * 
 * If `error` is an AxiosError, extract the message from AxiosError.response.data
 * Validation errors need to be treated as a special case because they
 * have a different structure.
 * 
 * If `error` is an instance of Error, extract the message from Error.message
 */
export function extractErrorMessage(error: any): string {
  let errorMessage: string = "";
  if (axios.isAxiosError(error)) {
    errorMessage = (error as AxiosError).response?.data.message.name === "ValidationError" 
    ? error.response?.data.message.message
    : error.response?.data.message;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }
  return errorMessage || "The application has encountered an unknown error.";
}

/**
 * Function to determine the alphabetical order of two strings.
 * It can be used in sorting algorithms, e.g. Array.sort((a, b) => compareStrings(a, b)).
 *
 * Returns -1 if the first argument comes before the second argument, 0 if they're equal, and 1 otherwise.
 *
 * If one of the arguments is undefined, null or empty, then it is placed second.
 * Note that this differs from the natural ordering of strings,
 * where empty strings would be placed before non-empty strings.
 */
export function compareStrings(a?: string, b?: string): number {
  if (a && b) return a < b ? -1 : a > b ? 1 : 0;
  return a ? -1 : b ? 1 : 0;
}

/**
 * Utility function used to define a recurrent AlertDialog.title
 * @param resource the resource type
 * @param numberOfItems the number of affected items. If not defined, assume 1.
 * @returns singular/plural text based on numberOfItems
 */
export function alertDialogTitle(resource: string, numberOfItems?: number): string {
  return !numberOfItems || numberOfItems == 1
    ? `Are you sure you want to delete the ${resource}?`
    : `Are you sure you want to delete ${numberOfItems} ${resource}s?`;
}

/**
 * Utility function used to define a recurrent AlertDialog.content
 * @param resource the resource type
 * @param numberOfItems the number of affected items. If not defined, assume 1.
 * @returns singular/plural text based on numberOfItems
 */
export function alertDialogContent(resource: string, numberOfItems?: number): string {
  return !numberOfItems || numberOfItems == 1
    ? `If you delete the ${resource}, it cannot be undone.`
    : `If you delete the selected ${resource}s, it cannot be undone.`;
}

/**
 * Trigger download of the Handbook PDF from S3.
 */
export async function downloadHandbook() {
  const url = await Storage.get("handbook/partner_manager/Partner_Manager_Handbook.pdf");
  download(url);
}

/**
 * Trigger download of the Handbook PDF in Brands version from S3.
 */
export async function downloadBrandHandbook() {
  const url = await Storage.get("handbook/brand_manager/Brand_Manager_Handbook.pdf");
  download(url);
}

/**
 * Browsers behave differently according to the object metadata in S3.
 * 
 * When there is a "Content-Disposition: attachment; filename="Handbook.pdf"" metadata in S3:
 *  Both FF and Chrome open the download dialog.
 * 
 * When there is no "Content-disposition" metadata in S3:
 *  Chrome: opens the PDF file.
 *  FF: the user can choose the behavior based on browser config.
 * 
 * In addition to that, `link.target = "_blank"` should be defined (or not)
 * to decide where to display the download URL. If set, a new tab is used
 * instead of using the "current" Portal tab.
 */
function download(url: string) {
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.click();
}
