/**
 * @file OnErrorWithNav.tsx
 * @description Component for displaying errors in page loading
 * @author Harry Rhodes
 * @exports React.Component
 */
import WithNav from "..";
import OnError from "../../feedback/OnError";

interface Props {
  error: any
}

/**
 * Renders OnError wrapper component
 * @returns {React.Component} OnError wrapper component
 */
export default function OnErrorWithNav(props: Props) {
  return (
    <WithNav>
      <OnError error={props.error}/>
    </WithNav>
  );
}
