/**
 * @file styles.ts Defines CSS styles for MSISDNsTable component
 * @author Rafael Mello
 */
import { SxProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { gridClasses } from "@mui/x-data-grid";

export const sxProps: Record<string, SxProps<Theme> | undefined> = {
  datagridToolbar: {
    '& .MuiDataGrid-overlayWrapper': {
      height: "3em" // for when no results are found after filtering
    },
    // A getCellClassName function can be used to dynamically apply this to the Datagrid cells.
    [`.${gridClasses.cell}.color`]: {
      color: '#e60000',
      fontWeight: 'bold'
    },
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  datagrid: {
    marginTop: theme.spacing(1)
  },
  renderCellDiv: {
    lineHeight: "inherit"
  }
}));

export default useStyles;