/**
 * @file GroupsTable.tsx GroupsTable component
 * @author Harry Rhodes
 * @exports React.Component
 */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { UserGroupType } from "../../../services/userService";

/**
 * Props
 * @typedef {{data: GroupType[]}} Props
 */
interface Props {
  data?: UserGroupType[];
}

/**
 * Renders GroupsTable component
 * @param props component props @see Props
 * @returns {React.Component} GroupsTable component
 */
export default function GroupsTable(props: Props) {
  if (props?.data?.length === 0)
    return <Typography>No Assigned Groups</Typography>;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.data?.map((group) => (
          <TableRow key={group?.GroupName}>
            <TableCell>{group?.GroupName}</TableCell>
            <TableCell align="right">
              {group?.Description ? group?.Description : "None"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
