/**
 * @file styles.ts
 * @description Defines CSS styles for EditLogo
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme: Theme) => ({
  mediaContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "240px",
  },
  media: {
    width: "200px",
    height: "200px",
    borderRadius: "50%",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  dialog: {
    overflow: "hidden",
  },
  progress: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default useStyles;
