/**
 * @file AutoCompleteFW.tsx
 * @description a custom AutoComplete component based on a Material UI components with Formik
 * @author Harry Rhodes
 * @exports React.Component
 */
import React, { Fragment } from "react";
import { FieldAttributes, useField } from "formik";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
/**
 * Props
 * @typedef {{placeholder: string, values: string[], FieldAttributes: FormikProps}} Props
 */
type Props = {
  loading: boolean;
  placeholder: string;
  values?: string[];
} & FieldAttributes<{}>;
/**
 * @function AutoCompleteFW
 * @description Renders AutoComplete component
 * @param props Custom props & Formik field attributes @see Formik @see Props
 * @returns {React.Component} AutoComplete component
 */
const AutoCompleteFW: React.FC<Props> = ({
  loading,
  placeholder,
  values,
  ...props
}) => {
  const [field, meta, helpers] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  meta.value = meta.value === null ? "null" : meta.value; //Convert null to string to satisfy material-ui
  return (
    <Autocomplete
      {...field}
      options={values || []}
      loading={loading}
      onChange={(_, value) => helpers.setValue(value as string)}
      isOptionEqualToValue={(item: any, current: any) =>
        item.value === current.value
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={placeholder}
          helperText={errorText}
          error={!!errorText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          fullWidth
        />
      )}
    />
  );
};
export default AutoCompleteFW;
