/**
 * @file styles.ts
 * @description Defines CSS styles for AggregatorInfo
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: 30,
  },
  title: {
    margin: theme.spacing(0, 0, 2),
  },
}));

export default useStyles;
