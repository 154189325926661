/**
 * @file styles.ts Defines CSS styles for ProfileCard
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 2.5px 5px 0px #949698",
  },
  cardMedia: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "10px",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    fontSize: "36px",
    width: "100px",
    height: "100px",
  },
}));

export default useStyles;
