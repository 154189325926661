/**
 * @file validationSchema.ts Yup validation schema for SignIn
 * @author Harry Rhodes
 * @exports Yup.object
 */
import * as Yup from "yup";
import { yupTrimmedString } from "../../../utils/validationUtils";

export const resetPasswordSchema = Yup.object().shape({
  code: yupTrimmedString("Verification code")
    .required("Required")
    .max(6, "Verification code cannot be greater than 6 digits"),
  password: Yup.string()
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Required")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});
