/**
 * @file styles.ts
 * @description Defines CSS styles for Dropzone component
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    height: "100%",
    width: "100%",
    display: "flex",
    border: "1px solid #555",
    borderRadius: "5px",
    padding: "50px",
    "&:hover": {
      background: "#fafafa",
    },
  },
}));

export default useStyles;
