
import { Chart } from 'chart.js/auto'
import { ReportData } from '../../services/reportService';
import { CHART_COLORS, destroyChartInstance, optionsPluginsDatalabels, optionsPluginsTitle, optionsScales, VF_RED } from './chartUtils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Button } from '@mui/material';
import useStyles from './styles';

interface Props {
  chartData: ReportData;
  chartName: string;
}

export default function DurationBucketChart(props: Props) {
  const classes = useStyles();
  let htmlExport: HTMLAnchorElement;

  // callback creates the chart on the canvas element
  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    destroyChartInstance(props.chartName);
    const ctx = canvas.getContext("2d");

    let datasets: { label: string; data: (string | undefined)[]; backgroundColor: string; }[] = [];
    const campaignLevel: boolean = !!props.chartData.rows[0]?.campaign;

    const firstRow = props.chartData.rows[0];

    if (!firstRow) {
      // Empty chart
      datasets = [{ label: 'Count', data: [], backgroundColor: CHART_COLORS[0] }];
    } else if (campaignLevel) {
      // Campaign level ReportData, group metrics for each campaign by bucket.
      datasets = props.chartData.rows.map((row, index) => {
        return {
          label: row.campaign!,
          data: [
            row.durationDB0Count,
            row.durationDB2Count,
            row.durationDB10Count,
            row.durationDB30Count,
            row.durationDB60Count,
            row.durationDB120Count,
            row.durationDB180Count,
            row.durationDB300Count,
            row.durationDB600Count,
            row.durationDB1200Count,
          ],
          backgroundColor: CHART_COLORS[index],
        }
      });
    } else {
      // Brand level ReportData
      datasets = [{
        label: 'Count',
        data: [
          firstRow.durationDB0Count,
          firstRow.durationDB2Count,
          firstRow.durationDB10Count,
          firstRow.durationDB30Count,
          firstRow.durationDB60Count,
          firstRow.durationDB120Count,
          firstRow.durationDB180Count,
          firstRow.durationDB300Count,
          firstRow.durationDB600Count,
          firstRow.durationDB1200Count,
        ],
        backgroundColor: VF_RED,
      }];
    }

    if (ctx) {
      Chart.register(ChartDataLabels); // Needed to enable "datalabels" in options.plugins
      const newChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: [
            "< 2s",
            "2-10s",
            "10-30s",
            "30s-1min",
            "1-2min",
            "2-3min",
            "3-5min",
            "5-10min",
            "10-20min",
            "> 20min"
          ],
          datasets: datasets
        },
        options: {
          locale: "en-US",
          plugins: {
            title: optionsPluginsTitle("Segmentation by Call Duration"),
            legend: {
              display: campaignLevel
            },
            // Show the labels within the bars
            datalabels: optionsPluginsDatalabels,
          },
          scales: campaignLevel ? optionsScales : {},
          responsive: true,
          animation: {
            onComplete: function () {
              htmlExport = document.createElement('a');
              htmlExport.href = newChart.toBase64Image();
              htmlExport.download = props.chartName + ".png";
            },
          }
        }
      });
    }
  };

  return (
    <>
      <div className={classes.exportChartButton}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => htmlExport.click()}
        >
          Export Chart
        </Button>
      </div>
      <div>
        <canvas id={props.chartName} ref={canvasCallback}></canvas>
      </div>
    </>
  );
};
