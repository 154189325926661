/**
 * @file CampaignDesigner.tsx
 * @description CampaignDesigner component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import CampaignInfo from "./CampaignInfo";
import AssignMSISDNs from "./AssignMSISDNs";
import StepperWrapper, {
  StepperContentType,
} from "../../common/onboarding/StepperWrapper";
import {
  CampaignType,
  AssignedMSISDNsType,
  AntiSpoofingType,
} from "../../../services/campaignService";
import { useQuery } from "react-query";
import brandService, { BrandType } from "../../../services/brandService";
import msisdnService, { MSISDNType } from "../../../services/msisdnService";
import { useParams } from "react-router-dom";
import CampaignReview from "./CampaignReview";
import useStyles from "./styles";
import { Transition } from "../../common/Transition";

export interface CampaignInfoType {
  id: string;
  name: string;
  display_name: string | undefined;
  desc: string;
  brand_id: string;
  anti_spoofing: AntiSpoofingType;
  reference_campaign: boolean
}

/** 
 * The input parameters for our component
*/
interface CampaignDesignerProps {
  brandId: string;
  campaign?: CampaignType;
  saveCampaign: (c: CampaignType) => Promise<any>;
  open: boolean;
  setOpen: (state: boolean) => void;
  setErrorMsg: (s: string) => void;
}

export default function CampaignDesigner(props: CampaignDesignerProps) {
  const classes = useStyles();
  const { brandId, campaign, saveCampaign, open, setOpen, setErrorMsg } = props;
  const { campaignId } = useParams<{ campaignId: string }>();

  const {
    data: brand,
    isLoading: loadingBrand,
    error: brandError,
  } = useQuery<BrandType>(["brand", brandId], () => brandService.getSingle(brandId));

  const {
    data: msisdns,
    isLoading: loadingMsisdns,
    error: msisdnsError,
  } = useQuery<MSISDNType[]>(["msisdns", brandId], () => msisdnService.getAll(props.brandId, ""));

  const {
    data: assignedMSISDNs,
    isLoading: loadingAssignedMSISDNs,
    error: assignedMSISDNsError,
  } = useQuery<MSISDNType[]>(
    ["assignedMSISDNs", campaignId],
    () => {
      if (campaignId) {
        return msisdnService.getAll("", campaignId);
      }
      else {
        // if there is no campaign ID, we are creating a new campaign,
        // so there are no assigned MSISDNs yet
        return Promise.resolve([]);
      }
    }
  );

  const [campaignInfo, setCampaignInfo] = useState<CampaignInfoType>();

  const [campaignMSISDNs, setCampaignMSISDNs] = useState<AssignedMSISDNsType>({
    msisdns: assignedMSISDNs as MSISDNType[]
  });

  useEffect(() => {
    // update the campaign info every time the campaign changes or the Designer opens
    setCampaignInfo(
      {
        id: campaign?.id as string,
        name: campaign?.name as string,
        desc: campaign?.desc as string,
        brand_id: campaign?.brand_id as string,
        display_name: campaign?.display_name,
        anti_spoofing: campaign?.anti_spoofing as AntiSpoofingType,
        reference_campaign: campaign ? !campaign.display_name : false,
      } || null
    )
  }, [campaign, open]);

  const handleClose = () => {
    setOpen(false);
  };

  const stepperData: StepperContentType[] = [
    {
      id: 1,
      name: "Campaign Info",
      content: (
        <CampaignInfo
          brandId={brandId}
          campaignInfo={campaignInfo as CampaignInfoType}
          setCampaignInfo={setCampaignInfo}
          featureAntiSpoofingEnabled={brand!.feature_anti_spoofing_enabled}
        />
      ),
      optional: false,
    },
    {
      id: 2,
      name: "Assign Phone Numbers",
      content: (
        <AssignMSISDNs
          msisdns={msisdns as MSISDNType[]}
          assignedMSISDNs={assignedMSISDNs as MSISDNType[]}
          setCampaignMSISDNs={setCampaignMSISDNs}
        />
      ),
      optional: false,
    },
    {
      id: 3,
      name: "Review",
      content: (
        <CampaignReview
          brandLogo={brand?.logo as string}
          campaignInfo={campaignInfo as CampaignInfoType}
          campaignMSISDNs={campaignMSISDNs as AssignedMSISDNsType}
          saveCampaign={saveCampaign}
          featureAntiSpoofingEnabled={brand!.feature_anti_spoofing_enabled}
          setErrorMsg={setErrorMsg}
        />
      ),
      optional: false,
    },
  ];

  const isLoading: boolean =
    loadingBrand ||
    loadingMsisdns ||
    loadingAssignedMSISDNs;

  const error =
    brandError ||
    msisdnsError ||
    assignedMSISDNsError;

  if (isLoading || error) return <div></div>;

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="secondary"
              onClick={handleClose}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              color="secondary"
              className={classes.title}
            >
              Campaign Designer
            </Typography>
          </Toolbar>
        </AppBar>
        <StepperWrapper data={stepperData} />
      </Dialog>
    </div>
  );
}
