/**
 * @file styles.ts
 * @description Defines CSS styles for UploadTable component in AddAsset
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  preview: {
    maxWidth: "356px",
    maxHeight: "244px",
  },
}));

export default useStyles;
