/**
 * @file PaiDiscovery.tsx
 * @description PAI Discovery component
 * @author Rafael Mello
 * @exports React.Component
 */
import { useState } from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from "@mui/material";
import { Formik } from "formik";
import FieldFW from "../../common/templates/forms/FieldFW";
import useStyles from "../common/style";
import paiDiscoveryService from "../../../services/paiDiscoveryService";
import { validationSchema } from "./validationSchema";
import AddMSISDN from "../AddMSISDN";
import { extractErrorMessage } from "../../../utils/utils";
import configurationService, { Config } from "../../../services/configurationService";
import { useQuery } from "react-query";
import OnError from "../../common/templates/feedback/OnError";
import OnLoad from "../../common/templates/feedback/OnLoad";

interface Props {
  brandId: string;
  open: boolean;
  setErrorMsg: (s: string) => void;
  handleClickClose: () => void;
}

export default function PaiDiscovery(props: Props) {
  const classes = useStyles();

  const [discoveredPai, setDiscoveredPai] = useState("");
  const [from, setFrom] = useState("");
  const [openAddMsisdn, setOpenAddMsisdn] = useState(false);

  const handleAddMsisdn = (pai: string, from: string) => {
    setDiscoveredPai(pai);
    setFrom(from);
    setOpenAddMsisdn(true);
    props.handleClickClose();
  }

  const {
    data: configuration,
    isLoading: loadingConfiguration,
    error: configurationError,
  } = useQuery<Config>(["configuration"], () => configurationService.getConfiguration());
  
  let jsxElement;
  if (loadingConfiguration) {
    jsxElement = <OnLoad />;
  } else if (configurationError) {
    jsxElement = <OnError error={configurationError} />;
  } else {
    jsxElement = <>
      <DialogContentText paragraph>
        PAI Discovery allows discovering the PAI (P-Asserted-Identity SIP header) of a Number if only the From number is known.
        The From is always available, because it is the number visible to the callee (B-party).
      </DialogContentText>
      <DialogContentText paragraph>
        PAI Discovery works as follows: Place a call from your Number to {configuration!.pai_discovery_number}.
        The call disconnects immediately. However, PAI and From of your Number are observed by the system.
        Afterwards (but within 24 hours), your Number can be entered in this dialog in order to retrieve the corresponding PAI.
      </DialogContentText>
      <Formik
        validateOnChange={true}
        initialValues={{
          from: ""
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          try {
            const pai: string = await paiDiscoveryService.doPaiDiscovery(data.from);
            if (pai) {
              handleAddMsisdn(pai, data.from);
            } else {
              props.setErrorMsg("No PAI was found for this From.");
            }
          } catch (err: any) {
            props.setErrorMsg(extractErrorMessage(err));
          }
          setSubmitting(false);
        } }
      >
        {({ isSubmitting, handleSubmit, submitForm }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FieldFW
                  type="text"
                  id="from"
                  name="from"
                  placeholder="From"
                  variant="standard" />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                onClick={submitForm}
                disabled={isSubmitting}
                color="primary"
              >
                Discover PAI
              </Button>
              <Button onClick={props.handleClickClose} color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </>
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClickClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">PAI Discovery</DialogTitle>
        <DialogContent className={classes.dialog}>
          {jsxElement}
        </DialogContent>
      </Dialog>

      <AddMSISDN
        open={openAddMsisdn}
        handleClickClose={() => setOpenAddMsisdn(false)}
        brandId={props.brandId}
        initialValuePai={discoveredPai}
        initialValueFrom={from}
      />
    </>
  );
}
