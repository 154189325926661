/**
 * @file validationSchema.ts
 * @description Yup validation schema for Edit component
 * @author Harry Rhodes
 * @exports Yup.object
 */
import * as Yup from "yup";
import { yupTrimmedString } from "../../../../utils/validationUtils";

/* eslint-disable no-template-curly-in-string */

export const validationSchema = Yup.object().shape({
  descriptions: Yup.array()
    .of(
      Yup.object().shape({
        description: yupTrimmedString("Description")
          .max(500, "Description cannot be longer than ${max} characters")
          .required("Required"),
      })
    )
    .min(1),
});
