import { Fragment, useState } from "react";
import { Button, Avatar, Typography, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import FieldFW from "../../common/templates/forms/FieldFW";
import AlertSnackback from "../../common/templates/feedback/AlertSnackbar";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { validationSchema } from "./validationSchema";
import useStyles from "../styles";
import { Auth } from "aws-amplify";
import { credentialsStorage } from "../../../utils/credentialStorage";
import { extractErrorMessage } from "../../../utils/utils";

interface ForgotPasswordType {
  username: string;
}

interface Props {
  setActiveStep: (step: number) => void;
  setUsername: (username: string) => void;
}
export default function ForgotPasswordForm(props: Props) {
  const classes = useStyles();
  const { setActiveStep, setUsername } = props;
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");

  return (
    <Fragment>
      <div>
        <Avatar className={classes.avatar}>
          <LockOpenOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Typography>
          Enter your username below to reset your password
        </Typography>
      </div>

      <Formik
        validateOnChange={true}
        initialValues={{
          username: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (data: ForgotPasswordType, { setSubmitting }) => {
          setSubmitting(true);
          const username = data.username.toLowerCase();
          try {
            await Auth.forgotPassword(username);
            credentialsStorage.setItem("username", username);
            setUsername(username);
            setActiveStep(5);
          } catch (err: any) {
            setMsg(extractErrorMessage(err));
            setError(true);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FieldFW
                  shouldAutoFocus={true}
                  type="username"
                  required
                  id="username"
                  name="username"
                  placeholder="Username"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => submitForm()}
                  disabled={isSubmitting}
                >
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </Fragment>
  );
}
