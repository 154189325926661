/**
 * @file invalidateQueries.ts A collection of functions to 
 * invalidate queries used across the application.
 * @author Rafael Mello
 */

import { QueryClient } from "react-query";

/* List of Query Keys used across the application.

  ["partner", partnerId]
  ["partners"]
  ["assignedPartners", opcoId]

  ["brand", brandId]
  ["brands", partnerId]

  ["campaign", campaignId]
  ["campaigns", brandId]

  ["msisdns", brandId]
  ["assignedMSISDNs", campaignId]

  ["opco", opcoId]
  ["opcos"]
  ["opcos-for-aggregator", aggregatorId]
  ["opcos-for-partner", partnerId]

  ["aggregator", aggregatorId]
  ["aggregators"]
  ["assignedAggregators", opcoId]

  ["endpoints", aggregatorId, opcoId]
  ["endpoints", aggregatorId]

  ["assets", brandId]

  ["user", username]
  ["users", groupName]
  ["users", groupNames[]]
  ["groups", username]
 */

export function invalidateAssetQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(["assets"]);
}

export function invalidateAggregatorQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(["aggregator"]);
  queryClient.invalidateQueries(["aggregators"]);
  queryClient.invalidateQueries(["assignedAggregators"]);
}

export function invalidateBrandQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(["brand"]);
  queryClient.invalidateQueries(["brands"]);
}

export function invalidateOpcoQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(["opco"]);
  queryClient.invalidateQueries(["opcos"]);
  queryClient.invalidateQueries(["opcos-for-aggregator"]);
  queryClient.invalidateQueries(["opcos-for-partner"]);
}

export function invalidatePartnerQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(["partner"]);
  queryClient.invalidateQueries(["partners"]);
  queryClient.invalidateQueries(["assignedPartners"]);
}

export function invalidateEndpointQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(["endpoints"]);
}

export function invalidateMsisdnQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(["msisdns"]);
  queryClient.invalidateQueries(["assignedMSISDNs"]);
}

export function invalidateUserQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(["user"]);
  queryClient.invalidateQueries(["users"]);
  queryClient.invalidateQueries(["groups"]);
}

export function invalidateCampaignQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(["campaign"]);
  queryClient.invalidateQueries(["campaigns"]);
}