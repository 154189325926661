/**
 * @file campaignService.ts
 * @description Interface/client for interacting with local /campaign API.
 * @requries ./axiosClient
 * @author Harry Rhodes
 */

import { client } from "./axiosClient";
import { MSISDNType } from "./msisdnService";

/**
 * @enum ApprovalStatus
 * @description States of approval for campaign
 */
export enum ApprovalStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
/**
 * @enum AntiSpoofingType
 * @description Types of campaign anti-spoofing
 */
export enum AntiSpoofingType {
  DISABLED = "DISABLED", // this is never shown to the user
  BLOCKING = "Calls will be blocked",
  NON_BLOCKING = "Calls will not get enriched"
}
/**
 * @typedef AssignedAssetType
 * @property {string} asset_id - asset id
 * @property {number} priority - associated priority rating
 */
export type AssignedAssetType = {
  asset_id: string;
  priority: number;
};
/**
 * @typedef AssignedMSISDNsType
 * @property {MSISDNType[]} msisdns - list of associated msisdns
 */
export type AssignedMSISDNsType = {
  msisdns: MSISDNType[];
};
/**
 * @typedef CampaignType
 * @property {string} id - DB generated id
 * @property {string} name - name of campaign
 * @property {string} display_name - display name of campaign
 * @property {string} desc - description of campaign
 * @property {ApprovalStatus} approval_status - approval state of campaign
 * @property {string} brand_id - id of associated brand
 * @property {string[]} msisdn_ids - ids of associated msisdns
 * @property {AntiSpoofingType} anti_spoofing - type of anti spoofing. Can be empty if feature is disabled
 */
export type CampaignType = {
  id?: string;
  name: string;
  display_name?: string;
  desc: string;
  approval_status: ApprovalStatus;
  brand_id: string;
  msisdn_ids?: string[];
  anti_spoofing: AntiSpoofingType;
};
/**
 * @typedef CampaignQueryType
 * @property {string} brandId - Id of brand
 * @property {string} msisdnId - id of MSISDN
 */
export type CampaignQueryType = {
  brandId?: string;
  msisdnId?: string;
  // Querying by approval status is currently not supported as this was tied to the removed opco column in the campaigns table.
  // If we ever need it again, we will have to implement the respective backend call.
  // approvalStatus?: string;
};

/**
 * We don't want to send "disabled" value for anti-spoofing
 * so this method can be used to delete it from the object
 * when we want it to be undefined.
 */
function deleteAntiSpoofingField(campaign: CampaignType) {
  let campaignWithoutAntiSpoofingField: any = campaign;
  delete campaignWithoutAntiSpoofingField.anti_spoofing;
  return campaignWithoutAntiSpoofingField;
}

/**
 * Set object's anti spoofing field to 
 * AntiSpoofingType.DISABLED if it is undefined.
 */
function setAntiSpoofingFieldIfUndefined(campaign: any) {
  if (campaign && campaign.anti_spoofing === undefined) {
    campaign.anti_spoofing = AntiSpoofingType.DISABLED;
  }
}

const service = {
  getAll: async (): Promise<CampaignType[]> => {
    let res = await client.get("/campaigns");

    // transform NULL/undefined anti spoofing into AntiSpoofingType.DISABLED
    for (let campaign of res.data) {
      setAntiSpoofingFieldIfUndefined(campaign);
    }

    return res.data;
  },
  getByParam: async (query: CampaignQueryType): Promise<CampaignType[]> => {
    let res;
    if (query.brandId) {
      res = await client.get("/campaigns", {
        params: { brand_id: query.brandId },
      });
    } else if (query.msisdnId) {
      res = await client.get("/campaigns", {
        params: { msisdn_id: query.msisdnId },
      });
    }

    // transform NULL/undefined anti spoofing into AntiSpoofingType.DISABLED
    if (res) {
      for (let campaign of res.data) {
        setAntiSpoofingFieldIfUndefined(campaign);
      }
    }
    
    return res?.data || [];
  },
  getSingle: async (id: string): Promise<CampaignType> => {
    let res = await client.get("/campaigns/" + id);

    // transform NULL/undefined anti spoofing into AntiSpoofingType.DISABLED
    setAntiSpoofingFieldIfUndefined(res.data[0]);

    return res.data[0] || [];
  },
  create: async (body: CampaignType) => {
    let res = await client.post("/campaigns", 
      body.anti_spoofing === AntiSpoofingType.DISABLED 
        ? deleteAntiSpoofingField(body) 
        : body);
    return res.data || [];
  },
  update: async (body: CampaignType) => {
    let res = await client.put("/campaigns/" + body.id, deleteAntiSpoofingField(body));
    return res.data || [];
  },
  delete: async (id: string) => {
    let res = await client.delete("/campaigns/" + id);
    return res.data || [];
  },
  approve: async (id: string) => {
    let res = await client.patch("/campaigns/" + id + "/approve");
    return res.data || [];
  },
  reject: async (id: string) => {
    let res = await client.patch("/campaigns/" + id + "/reject");
    return res.data || [];
  },
};

export default service;
