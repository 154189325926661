
import { Chart } from 'chart.js/auto'
import { ReportData } from '../../services/reportService';
import { CHART_COLORS_SET_1, destroyChartInstance, optionsPluginsDatalabels, optionsPluginsTitle, optionsScales, VF_GREY, VF_RED } from './chartUtils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Button } from '@mui/material';
import useStyles from './styles';

interface Props {
  chartData: ReportData;
  chartName: string;
}

export default function AnsweredCallsChart(props: Props) {
  const classes = useStyles();
  let htmlExport: HTMLAnchorElement;

  // callback creates the chart on the canvas element
  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    destroyChartInstance(props.chartName);
    const ctx = canvas.getContext("2d");

    let datasets: { label: string; data: string[]; backgroundColor: string; stack?: string }[] = [];
    const campaignLevel: boolean = !!props.chartData.rows[0]?.campaign;
    const uniqueDates: string[] = Array.from(new Set(props.chartData.rows.map((row) => row.date!)));

    if (campaignLevel) {
      // Campaign level ReportData, group metrics for each campaign by date

      const uniqueCampaigns: string[] = Array.from(new Set(props.chartData.rows.map((row) => row.campaign!)));
      // Map from campaign name to an array containing the answered calls of each date for the campaign
      const campaignAnsweredDataMap = new Map<string, string[]>();
      // Map from campaign name to an array containing the not answered calls of each date for the campaign
      const campaignNotAnsweredDataMap = new Map<string, string[]>();

      for (const date of uniqueDates) {
        for (const campaign of uniqueCampaigns) {
          // For each campaign, store the value of the corresponding date.
          // Since the labels of the chart are the same for every campaign, 
          // we need to store 0 if there is no row of this campaign for this date.
          const foundRow = props.chartData.rows.find((row) => row.date === date && row.campaign === campaign);

          // Store Answered value
          const campaignAnsweredData: string[] = campaignAnsweredDataMap.get(campaign) || [];
          campaignAnsweredData.push(foundRow ? (foundRow.answered || "0") : "0");
          campaignAnsweredDataMap.set(campaign, campaignAnsweredData);

          // Store Not Answered value
          const campaignNotansweredData: string[] = campaignNotAnsweredDataMap.get(campaign) || [];
          campaignNotansweredData.push(foundRow ? (foundRow.notAnswered || "0") : "0");
          campaignNotAnsweredDataMap.set(campaign, campaignNotansweredData);
        }
      }
      // For each campaign, add Answered + Not Answered datasets
      uniqueCampaigns.forEach((campaign, index) => {
        const campaignColor = CHART_COLORS_SET_1[index];
        datasets.push(
          {
            // This label will be shown as a legend item to represent both datasets for each campaign,
            // so set it to the campaign name only.
            label: campaign,
            data: campaignAnsweredDataMap.get(campaign)!,
            backgroundColor: campaignColor,
            stack: 'Answered',
          },
        );
        datasets.push(
          {
            // This label will not be shown in the legend.
            // Define it in the appropriate format for the tooltip.
            label: campaign + ': Not Answered',
            data: campaignNotAnsweredDataMap.get(campaign)!,
            backgroundColor: campaignColor,
            stack: 'Not Answered',
          },
        );
      });
    } else {
      // Brand level ReportData
      datasets = [
        {
          label: 'Answered',
          data: props.chartData.rows.map((row) => row.answered || "0"),
          backgroundColor: VF_RED,
        },
        {
          label: 'Not Answered',
          data: props.chartData.rows.map((row) => row.notAnswered || "0"),
          backgroundColor: VF_GREY,
        },
      ];
    }

    Chart.register(ChartDataLabels); // Needed to enable "datalabels" in options.plugins
    if (ctx) {
      const newChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: uniqueDates,
          datasets: datasets
        },
        options: {
          locale: "en-US",
          plugins: {
            title: optionsPluginsTitle("Answered vs Not Answered Calls"),
            legend: campaignLevel
              // If at campaign level:
              // 1. Only show ony legend entry for each campaign.
              // 2. Disable legend onClick action.
              ? {
                labels: {
                  filter: (legendItem, chartData) => !legendItem.text.includes('Not Answered'),
                },
                onClick: () => {}
              }
              : Chart.defaults.plugins.legend,
            tooltip: campaignLevel
              // If at campaign level:
              // 1. Fix tooltip for "Answered" dataset
              ? {
                callbacks: {
                  label: function (this, tooltipItem) {
                    return tooltipItem.dataset.label!.includes("Not Answered")
                      ? tooltipItem.dataset.label! + ": " + tooltipItem.formattedValue
                      : tooltipItem.dataset.label! + ": Answered: " + tooltipItem.formattedValue;
                  }
                }
              }
              : Chart.defaults.plugins.tooltip,
            // Show the labels within the bars
            datalabels: optionsPluginsDatalabels,
          },
          scales: optionsScales,
          responsive: true,
          animation: {
            onComplete: function () {
              htmlExport = document.createElement('a');
              htmlExport.href = newChart.toBase64Image();
              htmlExport.download = props.chartName + ".png";
            },
          }
        }
      });
    }
  };

  return (
    <>
      <div className={classes.exportChartButton}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => htmlExport.click()}
        >
          Export Chart
        </Button>
      </div>
      <div>
        <canvas id={props.chartName} ref={canvasCallback}></canvas>
      </div>
    </>
  );
};
