/**
 * @file XlsxDropzone.tsx
 * @description Custom Dropzone component for excel files
 * @author Rafael Mello
 * @exports React.Component
 */
import { useState, useRef } from "react";
import { FormControl, FormHelperText } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useStyles from "./styles";

interface Props {
  target?: File;
  setTarget: (file: File) => void;
  setHasSelectedFile: (hasSelectedFile: boolean) => void;
}

enum SelectionType {
  INPUT,
  DROPZONE,
}

export default function XlsxDropzone(props: Props) {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { target, setTarget, setHasSelectedFile } = props;
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>();

  const maxNumberOfFiles = 1;
  const allowedFormats = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]

  const checkFormat = (type: string) => {
    if (allowedFormats.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    if (e.dataTransfer.files.length > maxNumberOfFiles) {
      setErrorMsg("Only " + maxNumberOfFiles + " file can be uploaded");
      setError(true);
    } else {
      if (e.dataTransfer.files.length > 0) {
        readFiles(e.dataTransfer.files[0]);
      }
    }
  };
  const onSelect = (e: any) => {
    if (e.target!.files && e.target!.files.length > maxNumberOfFiles) {
      setErrorMsg("Only " + maxNumberOfFiles + " file can be uploaded");
      setError(true);
    } else {
      readFiles(e.target.files[0]);
    }
  };
  const onDragOver = (e: any) => {
    e.preventDefault();
  };
  const onClick = (e: any) => {
    e.preventDefault();
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };
  const onSelectFile = (e: any, type: SelectionType) => {
    e.preventDefault();
    if (type === SelectionType.DROPZONE) {
      onDrop(e);
    } else {
      onSelect(e);
    }
  };

  const readFiles = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => {
      if (checkFormat(file.type)) {
        setTarget(file);
        setError(false);
        setErrorMsg("");
        setHasSelectedFile(true);
      } else {
        setError(true);
        setErrorMsg("This type of file is not supported.");
        setHasSelectedFile(false);
      }
    });
  };

  return (
    target 
    ? <div>{target.name}</div> 
    : <FormControl fullWidth error={error}>
        <div
          className={classes.dropzone}
          onDrop={(e) => onSelectFile(e, SelectionType.DROPZONE)}
          onDragOver={onDragOver}
          onClick={onClick}
        >
          <AttachFileIcon /> Click or drag & drop to attach a file here
        </div>
        <input
          type="file"
          ref={inputRef}
          onChange={(e) => onSelectFile(e, SelectionType.INPUT)}
          accept={String(allowedFormats)}
          style={{ display: "none" }}
          multiple={maxNumberOfFiles > 1 ? true : false}
        />
        {}
        <FormHelperText>{errorMsg}</FormHelperText>
      </FormControl>
  );
}
