/**
 * @file Dashboard.tsx Dashboard page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Grid, Paper } from "@mui/material";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import useStyles from "../components/common/templates/style";
import { getDashboardPageTitle } from "../utils/featureToggle";
import { useUserContext } from "../context/UserContext";
import BrandDashboard from "./BrandDashboard";

interface Props {
  brandId?: string;
}

/**
 * Renders Dashboard page
 * @returns {React.Component} Dashboard page
 */
export default function Dashboard(props: Props) {
  const classes = useStyles();
  const { user } = useUserContext();
  const { role } = user;
  const isBrandRole = role === "brand-manager" || role === "brand-reporter";

  return (
    <WithNav>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          { isBrandRole
            ? <BrandDashboard brandId={props.brandId!} />
            : <Paper className={classes.paper}>
                <Title>{getDashboardPageTitle()}</Title>
              </Paper>
          }
        </Grid>
      </Grid>
    </WithNav>
  );
}
