import { ReactNode, useEffect, useState } from "react";
import { Paper, Box, Grid, Typography, Stepper } from "@mui/material";
import Copyright from "../components/common/Copyright";
import ResetPasswordForm from "../components/Confirm/ResetPasswordForm/ResetPasswordForm";
import Logo from "../components/common/Logo";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import useStyles from "../components/SignIn/styles";
import { useLocation } from "react-router-dom";
import { credentialsStorage } from "../utils/credentialStorage";

export default function Confirm() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [previousStep, setPreviousStep] = useState(0);
  const [content, setContent] = useState<ReactNode>(<div></div>);

  let location = useLocation();

  const handleBack = () => {
    setActiveStep(previousStep);
  };

  function getStepContent(step: number) {
    const state = location.state as { username: string; verify: boolean };
    let username: string;
    let verify: boolean;

    if (state) {
      username = state.username;
      verify = state.verify;
    } else {
      username = credentialsStorage.getItem("username") as string;
      verify = true;
    }

    switch (step) {
      case 0:
        return <ResetPasswordForm username={username} verify={verify} />;
      default:
        return <div></div>;
    }
  }

  useEffect(() => {
    setContent(getStepContent(activeStep));
  }, [activeStep]);

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <Logo />
            <Typography component="h1" variant="h6" color="secondary" noWrap>
              CallerID Business Portal
            </Typography>
          </div>

          <Paper className={classes.paper}>
            <div>
              {activeStep !== 0 && (
                <KeyboardBackspaceIcon onClick={() => handleBack()} />
              )}
              <Stepper
                nonLinear
                activeStep={activeStep}
                className={classes.stepper}
              >
                {/* {steps.map((label, index) => (
                <Step key={label}></Step>
              ))} */}
              </Stepper>
            </div>

            {content}
          </Paper>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}
