/**
 * @file paiDiscoveryService.ts
 * @description Interface/client for interacting with /pai-discovery API.
 * @requries ./axiosClient
 * @author Rafael Mello
 */

import { client } from "./axiosClient";

const service = {
  doPaiDiscovery: async (from: string): Promise<string> => {
    let res = await client.get("/pai-discovery", { params: { from: from } });
    return res.data;
  },
};

export default service;
