/**
 * @file WithNav.tsx
 * @description WithNav wrapper component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Fragment } from "react";
import { Container, Box } from "@mui/material";
import Navigation from "../../Navigation";
import Copyright from "../../Copyright";
import Location from "./Location";
import useStyles from "./styles";
/**
 * Props
 * @typedef {{children: ReactNode}} Props
 */
interface Props {
  children: React.ReactNode;
}
/**
 * Renders WithNav wrapper component
 * @param props component props @see Props
 * @returns {React.Component} WithNav wrapper component
 */
export default function WithNav(props: Props) {
  const classes = useStyles();
  return (
    <Fragment>
      <Navigation />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.location}>
          <Location />
        </div>

        <Container maxWidth="lg" className={classes.container}>
          {props.children}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </Fragment>
  );
}
