/**
 * @file SelectAssets.tsx SelectAssets component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState, Ref, Fragment } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import { Formik } from "formik";
import Title from "../../../common/titles/Title";
import { MSISDNType } from "../../../../services/msisdnService";
import { AssignedMSISDNsType } from "../../../../services/campaignService";
import { transformMsisdnForDisplaying } from "../../../../utils/msisdnUtils";
import TransferList from "../../../common/management/TransferList";

/**
 * Props
 * @typedef {{brandId: string}} Props
 */

interface Props {
  msisdns: MSISDNType[];
  assignedMSISDNs: MSISDNType[];
  setCampaignMSISDNs: (values: AssignedMSISDNsType) => void;
  handleNext?: () => void;
  innerRef?: Ref<any>;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

/**
 * Renders SelectAssets component
 * @param props component props @see Props
 * @returns {React.Component} SelectAssets component
 */
export default function AssignMSISDNs(props: Props) {
  // const classes = useStyles();
  const { msisdns, assignedMSISDNs, setCampaignMSISDNs } = props;
  const [newMSISDNs, setNewMSISDNs] = useState<string[]>([]);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getMSISDNSLeftData = () => {
    if (!msisdns) {
      return [];
    }
    // return all MSISDNs which do not have a campaign
    return msisdns
      .filter((m) => !m.campaign_id)
      .map((v) => transformMsisdnForDisplaying(v)) as string[];
  };
  const getMSISDNsRightData = () => {
    if (!assignedMSISDNs) {
      return [];
    }
    return assignedMSISDNs.map((v) => transformMsisdnForDisplaying(v)) as string[];
  };

  return (
    <Fragment>
      <Formik
        innerRef={props.innerRef}
        initialValues={{ null: null }}
        onSubmit={() => {
          setCampaignMSISDNs({
            msisdns: msisdns.filter((x) =>
              newMSISDNs.some((y) => transformMsisdnForDisplaying(x) === y)
            )
          });
          if (props.handleNext) {
            props.handleNext();
          }
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Tabs
            variant="standard"
            value={value}
            onChange={handleChange}
            aria-label="MSISDNS"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="Numbers" {...a11yProps(0)} />
          </Tabs>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={6}>
          <TabPanel value={value} index={0}>
            <Title>Assign Numbers</Title>
            <TransferList
              leftData={getMSISDNSLeftData()}
              rightData={getMSISDNsRightData()}
              setAssigned={setNewMSISDNs}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </Fragment>
  );
}
