import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  List,
  Card,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";

function removeAll(from: string[], remove: string[]) {
  return from.filter((value) => remove.indexOf(value) === -1);
}

function intersection(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function filterForSubstringIgnoringCase(array: string[], filterTerm: string): string[] {
  return array.filter((v) => v.toLowerCase().includes(filterTerm.toLowerCase()));
}

interface Props {
  leftData: string[];
  rightData: string[];
  setAssigned: (values: string[]) => void;
}
export default function TransferList(props: Props) {
  const theme = useTheme();
  const classes = useStyles();
  const { leftData, rightData, setAssigned } = props;

  // all the items that are currently checked, on either side
  // TODO improve performance by using a Set<string> instead - possibly also for other state fields here
  const [checked, setChecked] = useState<string[]>([]);

  // the actual data of the left and right boxes, before any filtering is applied.
  // initially comes from the data we got in the props
  const [left, setLeft] = useState<string[]>(leftData);
  const [right, setRight] = useState<string[]>(rightData);

  // the data that is being shown in the left and right boxes,
  // with the filter from the search applied
  const [filteredLeft, setFilteredLeft] = useState<string[]>([]);
  const [filteredRight, setFilteredRight] = useState<string[]>([]);

  // the query strings of the left and right hand search boxes
  const [queryLeft, setQueryLeft] = useState<string>("");
  const [queryRight, setQueryRight] = useState<string>("");

  // the checked items to show, basically all the checked items on either side
  // that also match the current query string
  const [leftChecked, setLeftChecked] = useState<string[]>([]);
  const [rightChecked, setRightChecked] = useState<string[]>([]);

  const breakpoint = useMediaQuery(theme.breakpoints.down("md"));

  // when the user types something into the left search box (which changes queryLeft)
  // or moves something left (which changes left),
  // the data that is shown is filtered accordingly.
  useEffect(() => {
    setFilteredLeft(filterForSubstringIgnoringCase(left, queryLeft));
  }, [queryLeft, left]);

  // when the user types something into the right search box (which changes queryRight)
  // or moves something right (which changes right),
  // the data that is shown is filtered accordingly.
  useEffect(() => {
    setFilteredRight(filterForSubstringIgnoringCase(right, queryRight));
  }, [queryRight, right]);

  // Whenever something gets moved to or from the right,
  // call setAssigned to update the assigned list of items.
  useEffect(() => {
    setAssigned(right);
  }, [right, setAssigned]);

  // Whenever something gets checked or unchecked, update the UI
  useEffect(() => {
    setLeftChecked(intersection(checked, filteredLeft));
  }, [checked, filteredLeft]);
  useEffect(() => {
    setRightChecked(intersection(checked, filteredRight));
  }, [checked, filteredRight]);

  /**
   * Reacts to the user checking or un-checking a checkbox on one of the items
  */
  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    
    setChecked(newChecked);
  };

  /** 
   * Reacts to a change (i.e. some key input) in one of the two 
   * search boxes above the "Available" and "Assigned" cards.
  */
  const handleChange = (e: any, title: string) => {
    if (title === "Available") {
      setQueryLeft(e.target.value);
    } else {
      setQueryRight(e.target.value);
    }
  };

  /** 
   * Reacts to the user moving something from left to right
  */
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(removeAll(left, leftChecked));
    setChecked(removeAll(checked, leftChecked));
  };

  /** 
   * Reacts to the user moving something from right to left
  */
  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(removeAll(right, rightChecked));
    setChecked(removeAll(checked, rightChecked));
  };

  const customList = (title: string, items: string[]) => (
    <Card className={classes.card}>
      <div className={classes.title}>
        <Typography>{title}</Typography>
      </div>
      <Divider />
      <div className={classes.cardHeader}>
        <TextField
          placeholder="Search"
          variant="outlined"
          onChange={(e) => handleChange(e, title)}
          onKeyUp={(e) => handleChange(e, title)}
          fullWidth
        />
      </div>
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value: string) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  color="primary"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={12} md={5}>
        {customList("Available", filteredLeft)}
      </Grid>
      <Grid item xs={12} md={2} className={classes.controls}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            {breakpoint ? <KeyboardArrowUp /> : <KeyboardArrowLeft />}
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            {breakpoint ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={5}>
        {customList("Assigned", filteredRight)}
      </Grid>
    </Grid>
  );
}
