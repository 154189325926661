import { createContext, useContext } from "react";

export interface UserType {
  loading: boolean;
  role: string;
  username?: string;
  opcoId?: string;
  opcoAlias?: string;
  partnerId?: string;
  partnerAlias?: string;
  brandId?: string;
  brandAlias?: string;
  email?: string;
}

export interface UserContextType {
  user: UserType;
  setUser: (user: UserType) => void;
}

export const UserContext = createContext<UserContextType | null>(null);

export const useUserContext = () => useContext(UserContext) as UserContextType;
