/**
 * @file styles.ts
 * @description Defines CSS styles for TransferList
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "auto",
  },
  title: {
    padding: theme.spacing(1, 2),
    color: "#e60000",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 400,
    height: 370,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  card: {
    boxShadow: "0px 2.5px 5px 0px #949698",
  },
  controls: {
    padding: "10px",
  },
}));

export default useStyles;
