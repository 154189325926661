/**
 * @file msisdnService.ts Interface/client for interacting with local /msisdn API.
 * @requries ./axiosClient
 * @author Harry Rhodes
 */

import { client } from "./axiosClient";
/**
 * @typedef MSISDNType
 * @property {string} id - UUIDv4 Generated ID for MSISDN.
 * @property {string} pai - P-Asserted-Identity. The phone number associated with the caller of a call.
 * @property {string} from - The phone number associated with the caller of a call. "from" is in many cases the same as "pai", 
 * but can be different if the caller has many identities to reflect the relevant one for the destination.
 * @property {string} brand_id - id of associated brand
 * @property {string[]} campaign_ids - list of associated campaign ids
 */
export type MSISDNType = {
  id?: string;
  pai: string;
  from?: string;
  brand_id: string;
  campaign_id?: string;
};

const service = {
  getAll: async (
    brandId?: string,
    campaignId?: string
  ): Promise<MSISDNType[]> => {
    let res;
    if (brandId && campaignId) {
      res = await client.get("/msisdns", {
        params: { brand_id: brandId, campaign_id: campaignId },
      });
    } else if (brandId) {
      res = await client.get("/msisdns", {
        params: { brand_id: brandId },
      });
    } else if (campaignId) {
      res = await client.get("/msisdns", {
        params: { campaign_id: campaignId },
      });
    } else {
      res = await client.get("/msisdns");
    }
    return res.data;
  },
  getSingle: async (id: string): Promise<MSISDNType> => {
    let res = await client.get("/msisdns/" + id);
    return res.data[0] || [];
  },
  create: async (body: MSISDNType) => {
    let res = await client.post("/msisdns", body);
    return res.data || [];
  },
  update: async (body: MSISDNType) => {
    let res = await client.put("/msisdns/" + body.id, body);
    return res.data || [];
  },
  delete: async (id: string) => {
    let res = await client.delete("/msisdns/" + id);
    return res.data || [];
  },
};

export default service;
