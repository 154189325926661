/**
 * @file OPCOs.tsx
 * @description OPCOs page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState } from "react";
import { Grid, Paper, Button } from "@mui/material";
import OPCOsCardTable from "../components/OPCOs/OPCOsCardTable";
import { useQuery } from "react-query";
import opcoService, { OPCOType } from "../services/opcoService";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import OnLoadWithNav from "../components/common/templates/WithNav/OnLoadWithNav";
import OnErrorWithNav from "../components/common/templates/WithNav/OnErrorWithNav";
import useStyles from "../components/common/templates/style";
import { useMutation, useQueryClient } from "react-query";
import AlertSnackback from "../components/common/templates/feedback/AlertSnackbar";
import { invalidateOpcoQueries } from "../utils/invalidateQueries";
import { useUserContext } from "../context/UserContext";
import AddOPCO from "../components/OPCOs/AddOPCO";

/**
 * Renders OPCOs page
 * @returns {React.Component} OPCOs page
 */
export default function OPCOs() {
  const classes = useStyles();
  const { user } = useUserContext();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [openAddOpco, setOpenAddOpco] = useState(false);

  const {
    data: opcos,
    isLoading: loadingOPCOs,
    error: opcosError,
  } = useQuery<OPCOType[]>(["opcos"], () => opcoService.getAll());
  const queryClient = useQueryClient();

  const { mutateAsync: createOPCO } = useMutation(opcoService.create, {
    onSuccess: () => {
      setOpenAddOpco(false);
      setMsg("OPCO created!");
      setSuccess(true);
      invalidateOpcoQueries(queryClient);
    }
  });

  if (loadingOPCOs) return <OnLoadWithNav />;
  if (opcosError) return <OnErrorWithNav error={opcosError} />;

  return (
    <WithNav>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>OPCOs</Title>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {user.role === "vodafone-admin" && (
              <div className={classes.buttons}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenAddOpco(true)}
                >
                  Add OPCO
                </Button>
              </div>
            )}
            <OPCOsCardTable
              data={opcos!}
              setSuccessMessage={(msg) => {
                setMsg(msg);
                setSuccess(true);
              }}
              setErrorMessage={(msg) => {
                setMsg(msg);
                setError(true);
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      {user.role === "vodafone-admin" && ( 
        <AddOPCO 
          createOPCO={createOPCO}
          open={openAddOpco}
          setOpen={setOpenAddOpco}
          setErrorMsg={(msg) => {
            setMsg(msg);
            setError(true); 
          }}
        />
      )}
      <AlertSnackback
        message={msg}
        type="success"
        open={success}
        setOpen={setSuccess}
      />
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </WithNav>
  );
}
