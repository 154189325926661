import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";
import { UserReadSingle } from "../../../services/userService";
import useStyles from "./styles";

interface Props {
  user: UserReadSingle;
}
export default function ProfileCard(props: Props) {
  const classes = useStyles();
  const { user } = props;
  return (
    <Card className={classes.card}>
      <CardMedia className={classes.cardMedia}>
        <Avatar className={classes.avatar}>
          {user.Email?.charAt(0).toUpperCase()}
        </Avatar>
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h5" component="div">
          {user.Email}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Status: {user.UserStatus}
        </Typography>
      </CardContent>
    </Card>
  );
}
