/**
 * @file VFReporterRoutes.tsx
 * @description Defines routes for VF-Reporter role.
 * @requries react-router-dom
 * @author Rafael Mello
 * @exports BrowserRouter
 */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../../../../../pages/Dashboard";
import OPCOs from "../../../../../pages/OPCOs";
import OPCO from "../../../../../pages/OPCO";
import Partners from "../../../../../pages/Partners";
import Partner from "../../../../../pages/Partner";
import Brand from "../../../../../pages/Brand";
import NotFound from "../../../../../pages/error/NotFound";
import Campaign from "../../../../../pages/Campaign";
import Reports from "../../../../../pages/Reports";
import ChartDesigner from "../../../../../pages/ChartDesigner";
import BrandDashboardWithNav from "../../../../../pages/BrandDashboardWithNav";

/**
 * Renders VFReporterRoutes
 * @param none
 * @returns {React.Component} VFReporterRoutes
 */
export default function VFReporterRoutes() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path={"/opcos"} element={<OPCOs />} />
        <Route path={"/opcos/:opcoId"} element={<OPCO />} />
        <Route path={"/opcos/:opcoId/partners"} element={<Partners />} />
        <Route path={"/opcos/:opcoId/partners/:partnerId"} element={<Partner />} />
        <Route path={"/opcos/:opcoId/partners/:partnerId/brands/:brandId"} element={<Brand />} />
        <Route path={"/opcos/:opcoId/partners/:partnerId/brands/:brandId/campaigns/:campaignId"} element={<Campaign />} />
        <Route path={"/partners"} element={<Partners />} />
        <Route path={"/partners/:partnerId"} element={<Partner />} />
        <Route path={"/partners/:partnerId/brands/:brandId"} element={<Brand />} />
        <Route path={"/partners/:partnerId/brands/:brandId/campaigns/:campaignId"} element={<Campaign />} />
        <Route path={"/partners/:partnerId/brands/:brandId/dashboard"} element={<BrandDashboardWithNav />} />
        <Route path={"/partners/:partnerId/brands/:brandId/chartDesigner"} element={<ChartDesigner />} />
        <Route path={"/reports"} element={<Reports />} />
        <Route path="*" element={<NotFound authenticated={true} />} />
      </Routes>
    </BrowserRouter>
  );
}
