/**
 * @file validationSchema.ts Yup validation schema for Endpoints
 * @author Harry Rhodes
 * @exports Yup.object
 */
import * as Yup from "yup";
import { yupForAlias, yupTrimmedString } from "../../../utils/validationUtils";

/* eslint-disable no-template-curly-in-string */

export const validationSchema = Yup.object().shape({
  name: yupTrimmedString("Name")
    .min(2, "Name cannot be shorter than ${min} characters")
    .max(100, "Name cannot be longer than ${max} characters")
    .required("Required"),
  alias: yupForAlias,
  market: yupTrimmedString("Market")
    .min(2, "Market cannot be shorter than ${min} characters")
    .max(100, "Market cannot be longer than ${max} characters")
    .required("Required"),
});
