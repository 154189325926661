import { Fragment, useState } from "react";
import { Button, Avatar, Typography, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import FieldFW from "../../common/templates/forms/FieldFW";
import AlertSnackback from "../../common/templates/feedback/AlertSnackbar";
import PhonelinkLockOutlinedIcon from "@mui/icons-material/PhonelinkLockOutlined";
import { validationSchema } from "./validationSchema";
import useStyles from "../styles";
import { Auth } from "aws-amplify";
import { extractErrorMessage } from "../../../utils/utils";

interface MFAType {
  code: string;
}

interface Props {
  user: any;
}
export default function MFAForm(props: Props) {
  const classes = useStyles();
  const { user } = props;
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");

  return (
    <Fragment>
      <div>
        <Avatar className={classes.avatar}>
          <PhonelinkLockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Enter MFA Code
        </Typography>
      </div>
      <Formik
        validateOnChange={true}
        initialValues={{
          code: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (data: MFAType, { setSubmitting }) => {
          setSubmitting(true);
          try {
            const loggedUser = await Auth.confirmSignIn(
              user,
              data.code,
              "SOFTWARE_TOKEN_MFA"
            );
            if (loggedUser) {
              window.location.replace("/");
            } else {
              setMsg("Something went wrong. Please try again.");
              setError(true);
            }
          } catch (err: any) {
            if (err.code === "CodeMismatchException") {
              setMsg("Invalid MFA code");
            } else {
              setMsg(extractErrorMessage(err));
            }
            setError(true);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FieldFW
                  shouldAutoFocus={true}
                  type="text"
                  required
                  id="code"
                  name="code"
                  placeholder="MFA Code"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => submitForm()}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </Fragment>
  );
}
