/**
 * @file brandService.ts
 * @description Interface/client for interacting with local /brand API.
 * @requries ./axiosClient
 * @author Harry Rhodes
 */
import { client } from "./axiosClient";

export type BrandCreateType = {
  image?: File;
  name: string;
  desc: string;
  alias: string;
  signature: string;
  account_email: string;
  partner_id: string;
  feature_anti_spoofing_enabled: boolean;
  pai_contingent: string; // string instead of number so we can use an empty string as default value in the Create form
};
//TODO: Export types further ie read, update,etc
/**
 * @typedef BrandType
 * @property {string} id - DB generated id
 * @property {string} logo - S3 key for brand logo
 * @property {string} name - Name of brand
 * @property {string} desc - Description of brand
 * @property {string} alias - Short name of brand ie VGSL (Vodafone Group Services Limited)
 * @property {string} signature - Verification signature for brand
 * @property {string} partner_id - id of associated partner
 * @property {boolean} feature_anti_spoofing_enabled - Indicates if anti spoofing is enabled for the brand
 * @property {number} pai_contingent - Upper bound on the number of distinct PAIs owned by the brand
 */
export type BrandType = {
  id: string;
  logo: string;
  name: string;
  desc: string;
  alias: string;
  signature: string;
  partner_id: string;
  feature_anti_spoofing_enabled: boolean;
  pai_contingent: number;
};

const service = {
  getAll: async (partnerId?: string): Promise<BrandType[]> => {
    let res;
    if (!partnerId) {
      res = await client.get("/brands");
    } else {
      res = await client.get("/brands", { params: { partner_id: partnerId } });
    }
    return res.data;
  },
  getId: async (alias: string) => {
    let res = await client.get("/brands", { params: { alias: alias } });
    return res.data[0].id || [];
  },
  getName: async (alias: string) => {
    let res = await client.get("/brands", { params: { alias: alias } });
    return res.data[0]?.name || "";
  },
  getSingle: async (id: string): Promise<BrandType> => {
    let res = await client.get("/brands/" + id);
    return res.data[0] || [];
  },
  create: async (body: FormData) => {
    let res = await client.post("/brands", body);
    return res.data || [];
  },
  update: async (body: BrandType) => {
    let res = await client.put("/brands/" + body.id, body);
    return res.data || [];
  },
  updateLogo: async (id: string, body: FormData) => {
    let res = await client.patch("/brands/" + id + "/logo", body);
    return res.data || [];
  },
  delete: async (id: string) => {
    let res = await client.delete("/brands/" + id);
    return res.data || [];
  },
};

export default service;
