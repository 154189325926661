/**
 * @file aggregatorService.ts
 * @description Interface/client for interacting with local /aggregator API.
 * @requries ./axiosClient
 * @author Harry Rhodes
 */

import { client } from "./axiosClient";

/**
 * @typedef AggregatorType
 * @property {string} id - DB generated id
 * @property {string} logo - S3 key for aggregator logo
 * @property {string} name - name of aggregator
 * @property {string} alias - alias of aggregator
 * @property {string} service - provided service by aggregator
 * @property {string[]} opco_ids - List of opco ids which the aggregator is associated to @default null @see opco.model.ts
 */
export type AggregatorCreateType = {
  name: string;
  alias: string;
  service: string;
  opco_ids: string[];
  image?: File;
};
export type AggregatorType = {
  id: string;
  logo: string;
  name: string;
  alias: string;
  service: string;
  opco_ids?: string[];
};

const service = {
  getAll: async (opcoId?: string): Promise<AggregatorType[]> => {
    let res;
    if (opcoId) {
      res = await client.get("/aggregators", {
        params: { opco_id: opcoId },
      });
    } else {
      res = await client.get("/aggregators");
    }
    return res.data;
  },
  getId: async (alias: string) => {
    let res = await client.get("/aggregators", { params: { alias: alias } });
    return res.data[0].id || [];
  },
  getSingle: async (id: string): Promise<AggregatorType> => {
    let res = await client.get("/aggregators/" + id);
    return res.data[0] || [];
  },
  create: async (body: FormData) => {
    let res = await client.post("/aggregators", body);
    return res.data || [];
  },
  update: async (body: AggregatorType) => {
    let res = await client.put("/aggregators/" + body.id, body);
    return res.data || [];
  },
  updateLogo: async (id: string, body: FormData) => {
    let res = await client.patch("/aggregators/" + id + "/logo", body);
    return res.data || [];
  },
  delete: async (id: string) => {
    let res = await client.delete("/aggregators/" + id);
    return res.data || [];
  },
};

export default service;
