/**
 * @file ChartDesigner.tsx page
 * @author Rafael Mello
 * @exports React.Component
 */

import { Grid, Paper } from "@mui/material";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import useStyles from "../components/common/templates/style";
import { useLocation } from "react-router-dom";
import ChartDesignerForm from "../components/Reports/ChartDesignerForm";

interface State {
  brandId: string;
}

export default function ChartDesigner() {
  const { state } = useLocation() as { state: State };
  const classes = useStyles();
  return (
    <WithNav>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>Chart Designer</Title>
            <ChartDesignerForm brandId={state.brandId} />
          </Paper>
        </Grid>
      </Grid>
    </WithNav>
  );
}