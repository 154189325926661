/**
 * @file featureToggle.ts a utility to turn functionality on and off in the portal
 * @see ERBT-6123
 * @author Christian Vasold
 */

/**
 * Whether or not the Aggregators feature is enabled
 */
export function isAggregatorsFeatureEnabled(): boolean {
    return false;
}

/**
 * Whether or not the Approvals feature is enabled.
 */
export function isApprovalsFeatureEnabled(): boolean {
    return false;
}

/**
 * Whether or not the Assets feature is enabled
 */
export function isAssetsFeatureEnabled(): boolean {
    return false;
}

/**
 * Gets the title of the "Dashboard" item in the navigation menu. 
 */
export function getDashboardMenuTitle(): string {
    return "Home";
}

/**
 * Gets the heading of the "Dashboard" pag. 
 */
export function getDashboardPageTitle(): string {
    return "Welcome to Vodafone CallerID";
}

/**
 * Whether or not the Anti-Spoofing functionality is enabled
 */
export function isAntiSpoofingFeatureEnabled(): boolean {
    if (process.env.REACT_APP_FEATURE_ANTI_SPOOFING_ENABLED) {
        return JSON.parse(process.env.REACT_APP_FEATURE_ANTI_SPOOFING_ENABLED);
    }
    return false;
}