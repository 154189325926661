/**
 * @file styles.ts
 * @description Defines CSS styles for Edit component in AddAsset
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  previewWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  imagePreview: {
    minWidth: "auto",
    maxHeight: "auto",
  },
  videoPreview: {
    width: "auto",
    height: "auto",
    maxHeight: "350px",
  },
  progress: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dropzone: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
