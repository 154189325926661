import Subtitle from "../../common/titles/Subtitle";
import Chip from "@mui/material/Chip";
import { OPCOType } from "../../../services/opcoService";
import useStyles from "../common/style";

interface Props {
  opcos: OPCOType[];
  setCurrentOPCOs: (x: any) => void;
  setAvailableOPCOs: (x: any) => void;
}

export default function AvailableOPCOsChipList(props: Props) {
  const classes = useStyles();

  const handleClick = (data: OPCOType) => () => {
    props.setCurrentOPCOs((prevState: OPCOType[]) => [...prevState, data]);
    props.setAvailableOPCOs((currentState: OPCOType[]) =>
      currentState.filter((opco: OPCOType) => opco !== data)
    );
  };
  if (props.opcos?.length === 0) return <div></div>;
  return (
    <>
      <Subtitle>Available OPCOs</Subtitle>
      <div className={classes.root}>
        {props.opcos?.map((data) => {
          return (
            <li key={data.id}>
              <Chip
                label={data.name}
                onClick={handleClick(data)}
                className={classes.chip}
              />
            </li>
          );
        })}
      </div>
    </>
  );
}
