/**
 * @file Dropzone.tsx
 * @description Custom Dropzone component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState, useRef } from "react";
import { FormControl, FormHelperText } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useStyles from "./styles";

export type FileInputType = {
  file: File;
  src: string;
  mimeType: string;
};
interface Props {
  limit: number;
  formats: string[];
  minHeight: number;
  minWidth: number;
  setFiles: (f: FileInputType[]) => void;
}

enum SelectionType {
  INPUT,
  DROPZONE,
}
export default function Dropzone(props: Props) {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { limit, formats, minHeight, minWidth, setFiles } = props;
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>();

  const checkFormat = (type: string) => {
    if (formats.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    //TODO: Handle directory upload
    if (e.dataTransfer.files.length > limit) {
      setErrorMsg("A maximum of " + limit + " files can be uploaded");
      setError(true);
    } else {
      if (e.dataTransfer.files.length > 0) {
        readFiles(e.dataTransfer.files);
      }
    }
  };
  const onSelect = (e: any) => {
    if (e.target!.files && e.target!.files.length > limit) {
      setErrorMsg("A maximum of " + limit + " files can be uploaded");
      setError(true);
    } else {
      readFiles(e.target.files);
    }
  };
  const onDragOver = (e: any) => {
    e.preventDefault();
  };
  const onClick = (e: any) => {
    e.preventDefault();
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };
  const onSelectFile = (e: any, type: SelectionType) => {
    e.preventDefault();
    if (type === SelectionType.DROPZONE) {
      onDrop(e);
    } else {
      onSelect(e);
    }
  };

  const readFiles = (files: File[]) => {
    let toUpload: FileInputType[] = [];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.addEventListener("load", () => {
        if (checkFormat(files[i].type)) {
          if (files[i].type.includes("video")) {
            toUpload.push({
              file: files[i],
              src: reader.result as string,
              mimeType: files[i].type,
            });
            setFiles([...toUpload]);
          } else {
            let uploadedImg = new Image();
            uploadedImg.src = reader.result as string;
            uploadedImg.onload = () => {
              const uploadedImageHeight = uploadedImg.height;
              const uploadedImageWidth = uploadedImg.width;
              if (
                uploadedImageHeight < minHeight ||
                uploadedImageWidth < minWidth
              ) {
                setErrorMsg(
                  "Image must have a minimum height of " +
                    minHeight +
                    "px and width of " +
                    minWidth +
                    "px"
                );
                setError(true);
              } else {
                toUpload.push({
                  file: files[i],
                  src: reader.result as string,
                  mimeType: files[i].type,
                });
                setFiles([...toUpload]);
                setError(false);
                setErrorMsg("");
              }
            };
          }
        } else {
          setError(true);
          setErrorMsg("File Type Is Not Supported.");
        }
      });
    }
  };

  return (
    <FormControl className="none" error={error}>
      <div
        className={classes.dropzone}
        onDrop={(e) => onSelectFile(e, SelectionType.DROPZONE)}
        onDragOver={onDragOver}
        onClick={onClick}
      >
        <AttachFileIcon /> Click or drag & drop to attach a file here
      </div>
      <input
        type="file"
        ref={inputRef}
        onChange={(e) => onSelectFile(e, SelectionType.INPUT)}
        accept={String(formats)}
        style={{ display: "none" }}
        multiple={limit > 1 ? true : false}
      />
      {/* <div style={{marginTop: "16px", marginBottom: "-6px"}}>Images must have a minimum height of 200px and width of 400px</div> */}
      <FormHelperText>{errorMsg}</FormHelperText>
    </FormControl>
  );
}
