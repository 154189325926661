/**
 * @file OnError.tsx
 * @description Component for displaying errors in page loading
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Button } from "@mui/material";
import useStyles from "../../style";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { extractErrorMessage } from "../../../../../utils/utils";

interface Props {
  error: any
}

/**
 * Renders OnError wrapper component
 * @returns {React.Component} OnError wrapper component
 */
export default function OnError(props: Props) {
  const classes = useStyles();
  return (
    <div className={classes.centerDiv}>
      <div>
        <ErrorOutlineIcon />
      </div>
      <div>
        <p>{extractErrorMessage(props.error)} :( Hit retry to reload the page.</p>
      </div>
      <div>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </div>
    </div>
  );
}
