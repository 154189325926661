import { MSISDNType } from "../services/msisdnService";

/**
 * Utility for displaying (ERBT-6041 -style) MSISDN prefixes on the frontend.
 * Typically, we store them as `[PAI/From]*` in the DB but would like to show them as
 * `[PAI/From] (prefix)` to the users.
 * Output of this method must be used for display only, don't use it in API calls.
 * @param msisdn The MSISDN entity as we have it in the DB, containing PAI and From
 * e.g. msisdn.PAI/From = `+491234567890` for a regular PAI/From or `+49123456*` for a prefix.
 * @returns a string how to represent the input in the frontend, 
 * e.g. 
 * PAI: +49123456789
 * PAI: +49123456 (prefix)
 * PAI: +49123456789 with From: +49987654321
 * PAI: +49123456789 with From: +49987654 (prefix)
 * PAI: +49123456 (prefix) with From: +49987654321
 * PAI: +49123456 (prefix) with From: +49987654 (prefix)
 */
export function transformMsisdnForDisplaying(msisdn: MSISDNType | undefined): string {
    if (!msisdn) {
        return "";
    }
    
    let paiString = "PAI: " + (msisdn.pai.endsWith("*") ? msisdn.pai.slice(0, -1) + " (prefix)" : msisdn.pai);

    let fromString = "";
    if (msisdn.from) {
        fromString = " with From: " + (msisdn.from.endsWith("*") ? msisdn.from.slice(0, -1) + " (prefix)" : msisdn.from);
    }

    return paiString + fromString;
}
/** 
 * The minimum length of a PAI to be added, including the leading `+`
 */
export const paiMinLength = 10;
/** 
 * The maximum length of an PAI to be added, including the leading `+`
 */
export const paiMaxLength = 16;
/** 
 * The minimum length of a PAI prefix to be added, including the leading `+` but excluding the trailing `*`
 */
export const paiPrefixMinLength = 9;
/** 
 * The maximum length of a PAI prefix to be added, including the leading `+` but excluding the trailing `*`
 */
export const paiPrefixMaxLength = 15;
/** 
 * The minimum length of a From to be added, including the leading `+`
 */
export const fromMinLength = 10;
/** 
 * The maximum length of an From to be added, including the leading `+`
 */
export const fromMaxLength = 16;
/** 
 * The minimum length of a From prefix to be added, including the leading `+` but excluding the trailing `*`
 */
export const fromPrefixMinLength = 9;
/** 
 * The maximum length of a From prefix to be added, including the leading `+` but excluding the trailing `*`
 */
export const fromPrefixMaxLength = 15;