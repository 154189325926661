/**
 * @file VFReporterList.tsx
 * @description Defines Navigation list items for Vodafone Reporter role.
 * @author Rafael mello
 * @exports React.Fragment
 */
import { useState } from "react";
import {
  List,
  Collapse,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PublicIcon from "@mui/icons-material/Public";
import BusinessIcon from "@mui/icons-material/Business";
import ListIcon from "@mui/icons-material/List";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AssessmentIcon from "@mui/icons-material/Assessment";
import useStyles from "../common/styles";
import { getDashboardMenuTitle } from "../../../../../utils/featureToggle";
import { downloadHandbook } from "../../../../../utils/utils";
import { MenuBook } from "@mui/icons-material";

/**
 * Renders VFReporterList
 * @param props component props @see Props
 * @returns {React.Component} VFReporterList
 */
export default function VFReporterList() {
  const [openPartners, setOpenPartners] = useState(true);
  const [openOPCOs, setOpenOPCOs] = useState(true);
  const classes = useStyles();

  return (
    <List>
      <ListItemButton component="a" href="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={getDashboardMenuTitle()} />
      </ListItemButton>
      <ListItemButton onClick={() => setOpenOPCOs(!openOPCOs)}>
        <ListItemIcon>
          <PublicIcon />
        </ListItemIcon>
        <ListItemText primary="OPCOs" />
        {openOPCOs ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openOPCOs} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/opcos"}
          >
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="View all" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => setOpenPartners(!openPartners)}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Partners" />
        {openPartners ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openPartners} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/partners"}
          >
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="View all" />
          </ListItemButton>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/reports"}
          >
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton component="a" onClick={downloadHandbook}>
        <ListItemIcon>
          <MenuBook />
        </ListItemIcon>
        <ListItemText primary="Handbook" />
      </ListItemButton>
    </List>
  );
}
