/**
 * @file validationSchema.ts Yup validation schema for MFAForm
 * @author Harry Rhodes
 * @exports Yup.object
 */
import * as Yup from "yup";
import { yupTrimmedString } from "../../../utils/validationUtils";

export const validationSchema = Yup.object().shape({
  code: yupTrimmedString("MFA code")
    .required("Required")
    .max(6, "MFA code cannot be longer than 6 digits"),
});
