/**
 * @file ProfileIcon.tsx ProfileIcon component
 * @author Harry Rhodes
 * @exports React.Component
 */
import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import ProfileMenu from "./ProfileMenu";
import { useUserContext } from "../../../../context/UserContext";
/**
 * Props
 * @typedef {{openSwitchRole: function}} Props
 */
interface Props {
  openSwitchRole: () => void;
}
/**
 * Renders ProfileIcon component
 * @param props component props @see Props
 * @returns {React.Component} ProfileIcon component
 */
export default function ProfileIcon(props: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { user } = useUserContext();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div onClick={(e: any) => handleClick(e)}>
        <Avatar>{user.email ? user.email.charAt(0).toUpperCase() : "Me"}</Avatar>
      </div>
      <ProfileMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        openSwitchRole={props.openSwitchRole}
      />
    </>
  );
}
