import { Ref, useState } from "react";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import FieldFW from "../../../common/templates/forms/FieldFW";
import { Formik, Form } from "formik";
import { BrandCreateType } from "../../../../services/brandService";
import { validationSchema } from "../../common/validationSchema";
import useStyles from "./styles";
import LogoCropper from "../../../common/LogoCropper";
import { isAntiSpoofingFeatureEnabled } from "../../../../utils/featureToggle";

interface Props {
  partnerId: string;
  setBrand: (brand: BrandCreateType) => void;
  setTarget: (target: File) => void;
  handleNext?: () => void;
  innerRef?: Ref<any>;
}

export default function BrandInfo(props: Props) {
  const classes = useStyles();

  const [antiSpoofingChecked, setAntiSpoofingChecked] = useState(false);
  const handleAntiSpoofingChange = (event: any) => {
    setAntiSpoofingChecked(event.target.checked);
  };

  return (
    <Formik
      innerRef={props.innerRef}
      validateOnChange={true}
      initialValues={{
        name: "",
        desc: "",
        alias: "",
        signature: "",
        account_email: "",
        partner_id: props.partnerId,
        feature_anti_spoofing_enabled: false,
        pai_contingent: ""
      }}
      validationSchema={validationSchema}
      onSubmit={(data: BrandCreateType, { setSubmitting }) => {
        setSubmitting(true);
        data.alias = data.alias.toUpperCase();
        data.feature_anti_spoofing_enabled = antiSpoofingChecked;
        props.setBrand(data);
        setSubmitting(false);
        if (props.handleNext) {
          props.handleNext();
        }
      }}
    >
      <Form>
        <div className={classes.container}>
          <Grid container spacing={2}>
            <Grid item container xs={12} md={6}>
              <Grid item container spacing={2} xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.title}>
                    Brand Details
                  </Typography>
                  <FieldFW
                    type="text"
                    required
                    id="name"
                    name="name"
                    placeholder="Name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldFW
                    type="text"
                    required
                    id="desc"
                    name="desc"
                    placeholder="Description"
                    variant="outlined"
                    multiline={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldFW
                    type="text"
                    required
                    id="alias"
                    name="alias"
                    placeholder="Alias"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FieldFW
                    type="number"
                    required
                    id="pai_contingent"
                    name="pai_contingent"
                    placeholder="PAI Contingent"
                    variant="outlined"
                  />
                </Grid>
                {isAntiSpoofingFeatureEnabled() &&
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Enable Anti-Spoofing feature"
                      checked={antiSpoofingChecked}
                      onChange={handleAntiSpoofingChange}
                      control={<Checkbox />}
                    />
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  Upload Logo (Optional)
                </Typography>
                <LogoCropper setTarget={props.setTarget} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Form>
    </Formik>
  );
}
