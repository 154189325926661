import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
} from "@mui/material";
import FieldFW from "../../common/templates/forms/FieldFW";
import { Form, Formik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import userService, { UserRole, transformRoleToServerFormat } from "../../../services/userService";
import RadioSelect from "../../common/templates/forms/RadioSelect";
import { validationSchema } from "./validationSchema";
import { extractErrorMessage } from "../../../utils/utils";
import { invalidateUserQueries } from "../../../utils/invalidateQueries";

interface Props {
  radioRoles: UserRole[];
  open: boolean;
  setOpen: (open: boolean) => void;
  setSuccess: (s: string) => void;
  setError: (s: string) => void;
  brandAlias?: string;
  partnerAlias?: string;
  opcoAlias?: string;
}

export default function AddUserDialog(props: Props) {
  const { radioRoles, open, setOpen, setSuccess, setError, brandAlias, partnerAlias, opcoAlias } = props;

  const handleClose = () => {
    setOpen(false);
  };
  
  const queryClient = useQueryClient();
  const { mutateAsync: createUser } = useMutation(userService.create, {
    onSuccess: () => {
      invalidateUserQueries(queryClient);
      setSuccess("User created!");
      handleClose();
    }
  });

  return (
    <Dialog maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <DialogTitle id="alert-dialog-title">
        {`New User`}
      </DialogTitle>

      <DialogContent>
        <Formik
          validateOnChange={true}
          validationSchema={validationSchema}
          initialValues={{
            email: "",
            role: radioRoles.length === 1 && radioRoles[0] ? transformRoleToServerFormat(radioRoles[0]) : ""
          }}
          onSubmit={async (data: any, { setSubmitting }) => {
            setSubmitting(true);

            data.email = data.email.toLowerCase();
            data.brand_alias = brandAlias;
            data.partner_alias = partnerAlias;
            data.opco_alias = opcoAlias;

            try {
              await createUser(data);
            } catch (err: any) {
              setError(extractErrorMessage(err));
            }
            setSubmitting(false);
          }}
        >
          {({ errors }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <RadioSelect
                    name="role"
                    disabled={radioRoles.length === 1}
                    options={
                      radioRoles.map((role) => {
                        return { 
                          label: role, 
                          value: transformRoleToServerFormat(role)
                        }
                      })
                    }
                  />
                  {(errors.role) && (
                    <FormHelperText error={true}>
                      {errors.role}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FieldFW
                    type="text"
                    required
                    id="email"
                    name="email"
                    placeholder="Email"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  type="submit"
                  color="primary"
                >
                  Create User
                </Button>
                <Button onClick={handleClose} color="secondary">
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>

    </Dialog>
  );
}
