/**
 * @file NotFound.tsx 404 NotFound page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Grid, Paper, Typography, Button } from "@mui/material";
import Title from "../../components/common/titles/Title";
import WithNav from "../../components/common/templates/WithNav";
import useStyles from "../../components/common/templates/style";
import { useNavigate } from "react-router-dom";
/**
 * Renders 404 NotFound page
 * @returns {React.Component} NotFound page
 */

 interface Props {
  authenticated: boolean;
}

export default function NotFound(props: Props) {
  const classes = useStyles();
  let navigate = useNavigate();
  const routeChange = () =>{ 
    let path = "/signin";
    navigate(path)
  };
  if (props.authenticated) {
    return (
      <WithNav>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.paper}>
              <Title>404 Not Found</Title>
              Sorry this resource cannot be found :(
            </Paper>
          </Grid>
        </Grid>
      </WithNav>
    );
  } else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={classes.paper} style={{fontSize: '1.5rem', textAlign: 'center'}}>
            <Typography component="h2" variant="h2" color="primary" gutterBottom>404 Not Found</Typography>
            Sorry this page cannot be found :(
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              style={{marginTop: '1.5rem', width: '50%', alignSelf: 'center', minWidth: '200px'}}
              onClick={() => routeChange()}
            >
              Return to Sign In
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
