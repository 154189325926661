/**
 * @file StepActions.tsx
 * @description StepActions component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Button } from "@mui/material";
import useStyles from "./styles";

interface Props {
  handleBack: () => void;
  handleSkip: () => void;
  handleSubmit: () => void;
  length: number;
  activeStep: number;
  optional: boolean;
}

export default function StepActions(props: Props) {
  const classes = useStyles();

  return (
    <>
      <Button
        disabled={props.activeStep === 0}
        onClick={props.handleBack}
        className={classes.button}
      >
        Back
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={props.handleSubmit}
        className={classes.button}
      >
        {props.activeStep === props.length - 1 ? "Save" : "Next"}
      </Button>
      {props.optional && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={props.handleSkip}
          className={classes.button}
        >
          Skip
        </Button>
      )}
    </>
  );
}
