/**
 * @file User.tsx User page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Grid, Paper, Typography } from "@mui/material";
import userService, {
  UserGroupType,
  UserReadSingle,
} from "../services/userService";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import OnLoadWithNav from "../components/common/templates/WithNav/OnLoadWithNav";
import OnErrorWithNav from "../components/common/templates/WithNav/OnErrorWithNav";
import useStyles from "../components/common/templates/style";
import ProfileCard from "../components/User/ProfileCard";
import GroupsTable from "../components/User/GroupsTable";
import { prettyParseGroupName } from "../utils";
/**
 * Renders User page
 * @param none
 * @returns {React.Component} User page
 */
export default function User() {
  const classes = useStyles();
  const { username } = useParams<{ username: string }>();

  const {
    data: user,
    isLoading: loadingUser,
    error: userError,
  } = useQuery<UserReadSingle>(["user", username], () =>
    userService.getSingle(username as string)
  );

  const fetchGroupsAndResolveName = async (): Promise<UserGroupType[]> => {
    let groups = await userService.getGroups(username as string);
    for await (const group of groups) {
      group.GroupName = await prettyParseGroupName(group.GroupName);
    }
    return groups;
  }

  const {
    data: groups,
    isLoading: loadingGroups,
    error: groupsError,
  } = useQuery<UserGroupType[]>(["groups", username], fetchGroupsAndResolveName);

  const isLoading: boolean = loadingUser || loadingGroups;
  const error = userError || groupsError;

  if (isLoading) return <OnLoadWithNav />;
  if (error) return <OnErrorWithNav error={error} />;

  return (
    <WithNav>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>User Details</Title>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <ProfileCard user={user as UserReadSingle} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom component="div">
                  <b>User Created:</b> {user?.UserCreateDate}
                </Typography>
                <Typography variant="subtitle1" gutterBottom component="div">
                  <b>Last Modified:</b> {user?.UserLastModifiedDate}
                </Typography>
                <Typography variant="subtitle1" gutterBottom component="div">
                  <b>ID: </b>
                  {user?.Username}
                </Typography>
                <Typography variant="subtitle1" gutterBottom component="div">
                  <b>Email Verified: </b>
                  {user?.EmailVerified.toString().toUpperCase()}
                </Typography>
                <Typography variant="subtitle1" gutterBottom component="div">
                  <b>MFA Setting: </b>
                  {user?.PreferredMfaSetting}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* //TODO: Implement UAM actions
         <Grid item xs={12}>
          <Paper>
            <div className={classes.paper}>
              <Typography variant="body2" color="text.secondary">
                Actions
              </Typography>
              <Stack spacing={2} direction="row">
                <Button size="small" color="primary">
                  Reset Password
                </Button>
                <Button size="small" color="secondary">
                  Disable
                </Button>
                <Button size="small" color="secondary">
                  Delete User
                </Button>
              </Stack>
            </div>
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>Assigned Groups</Title>
            <GroupsTable data={groups as UserGroupType[]} />
          </Paper>
        </Grid>
      </Grid>
    </WithNav>
  );
}
