/**
 * @file ManagePartners.tsx ManagePartners page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState } from "react";
import { Grid, Paper, Button } from "@mui/material";
import { useMutation, useQueryClient, useQuery } from "react-query";
import partnerService, { PartnerType } from "../services/partnerService";
import catalogService from "../services/catalogService";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import OnLoadWithNav from "../components/common/templates/WithNav/OnLoadWithNav";
import OnErrorWithNav from "../components/common/templates/WithNav/OnErrorWithNav";
import AlertSnackback from "../components/common/templates/feedback/AlertSnackbar";
import useStyles from "../components/common/templates/style";
import TransferList from "../components/common/management/TransferList";
import { invalidatePartnerQueries } from "../utils/invalidateQueries";
/**
 * Props
 * @typedef {{opcoId: string}} Props
 */
interface Props {
  opcoId: string;
}
/**
 * Renders ManagePartners page
 * @param props component props @see Props
 * @returns {React.Component} ManagePartners page
 */
export default function ManagePartners(props: Props) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [assigned, setAssigned] = useState<string[]>([]);
  const [success, setSuccess] = useState(false);

  const { mutateAsync: updateCatalog } = useMutation(catalogService.update, {
    onSuccess: () => {
      invalidatePartnerQueries(queryClient);
      setSuccess(true);
    },
  });

  const { mutateAsync: deleteCatalog } = useMutation(catalogService.delete, {
    onSuccess: () => {
      invalidatePartnerQueries(queryClient);
      setSuccess(true);
    },
  });

  const {
    data: partners,
    isLoading: loadingPartners,
    error: partnersError,
  } = useQuery<PartnerType[]>(["partners"], () => partnerService.getAll());

  const {
    data: assignedPartners,
    isLoading: loadingAssignedAggreagtors,
    error: assignedPartnersError,
  } = useQuery<PartnerType[]>(["assignedPartners", props.opcoId], () =>
    partnerService.getAll(props.opcoId)
  );

  /**
   * Handles submit of TransferList component @see TransferList
   * @param value List of assigned values (right column)
   * @returns {ReactQuery} Update/Delete query depending on how assigned values compare to original values
   */
  const handleSubmit = (values: string[]) => {
    let toChange = partners
      ?.filter(({ name: name1 }) =>
        values.some((name2: string) => name1 === name2)
      )
      .map((v) => v.id) as string[];
    let ids = assignedPartners?.map((v) => v.id) as string[];
    let toDelete: string[] = ids.filter((id) => !toChange.includes(id));
    let toModify: string[] = toChange.filter((id) => !ids.includes(id));
    if (toDelete.length > 0) {
      let data = {
        id: props.opcoId,
        type: "BUSINESS",
        associated_ids: toDelete,
      };
      deleteCatalog(data);
    }
    if (toModify.length > 0) {
      let data = {
        id: props.opcoId,
        type: "BUSINESS",
        associated_ids: toModify,
      };
      updateCatalog(data);
    }
  };

  const isLoading: boolean = loadingPartners || loadingAssignedAggreagtors;
  const error = partnersError || assignedPartnersError;

  if (isLoading) return <OnLoadWithNav />;
  if (error) return <OnErrorWithNav error={error} />;

  return (
    <WithNav>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>Manage Partners</Title>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <TransferList
              leftData={
                partners
                  ?.filter(
                    ({ id: id1 }) =>
                      !assignedPartners?.some(({ id: id2 }) => id2 === id1)
                  )
                  .map((v) => v.name) as string[]
              }
              rightData={assignedPartners?.map((v) => v.name) as string[]}
              setAssigned={setAssigned}
            />
            <Button
              color="primary"
              style={{ marginTop: "10px" }}
              onClick={() => handleSubmit(assigned)}
            >
              Save
            </Button>
          </Paper>

          <AlertSnackback
            message={"Partners updated!"}
            type="success"
            open={success}
            setOpen={setSuccess}
          />

        </Grid>
      </Grid>
    </WithNav>
  );
}
