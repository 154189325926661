
import { Chart } from 'chart.js/auto'
import { ReportData, ReportRow } from '../../services/reportService';
import { CHART_COLORS, destroyChartInstance, optionsPluginsTitle, VF_RED } from './chartUtils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useStyles from './styles';
import { Button } from '@mui/material';

interface Props {
  chartData: ReportData;
  chartName: string;
  label: string;
  metric: keyof ReportRow;
  // true if y-axis should be in percentage format
  yInPercentage?: boolean;
}

export default function LineChart(props: Props) {
  const classes = useStyles();
  let htmlExport: HTMLAnchorElement;

  // callback creates the chart on the canvas element
  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    destroyChartInstance(props.chartName);
    const ctx = canvas.getContext("2d");

    let datasets: { label: string; data: (number | undefined)[]; backgroundColor: string; fill: boolean; borderColor: string; tension: number; }[] = [];
    const campaignLevel: boolean = !!props.chartData.rows[0]?.campaign;
    const uniqueDates: string[] = Array.from(new Set(props.chartData.rows.map(row => row.date!)));

    if (campaignLevel) {
      // Campaign level ReportData, group metrics for each campaign by date

      const uniqueCampaigns: string[] = Array.from(new Set(props.chartData.rows.map(row => row.campaign!)));
      // Map from campaign name to an array containing the desired metrics of each date for the campaign
      let campaignDataMap = new Map<string, (number | undefined)[]>();

      for (const date of uniqueDates) {
        for (const campaign of uniqueCampaigns) {
          // For each campaign, store the value of the corresponding date.
          // Since the labels of the chart are the same for every campaign, 
          // we need to store 0 if there is no row of this campaign for this date.
          const foundRow = props.chartData.rows.find(row => row.date === date && row.campaign === campaign);
          const campaignData: (number | undefined)[] = campaignDataMap.get(campaign) || [];
          campaignData.push(foundRow && foundRow[props.metric] ? parseFloat(foundRow[props.metric]!) : undefined);
          campaignDataMap.set(campaign, campaignData);
        }
      }
      datasets = uniqueCampaigns.map((campaign, index) => {
        return {
          label: campaign,
          data: campaignDataMap.get(campaign)!,
          backgroundColor: CHART_COLORS[index],
          fill: false,
          borderColor: CHART_COLORS[index],
          tension: 0.1
        }
      });
    } else {
      // Brand level ReportData
      datasets = [
        {
          label: props.label,
          data: props.chartData.rows.map(row => row[props.metric] ? parseFloat(row[props.metric]!) : undefined),
          backgroundColor: VF_RED,
          fill: false,
          borderColor: VF_RED,
          tension: 0.1
        },
      ];
    }

    if (ctx) {
      Chart.register(ChartDataLabels); // Needed to enable "datalabels" in options.plugins
      const newChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: uniqueDates,
          datasets: datasets,
        },
        options: {
          locale: "en-US",
          spanGaps: true,
          interaction: {
            intersect: false,
          },
          plugins: {
            title: optionsPluginsTitle(props.label),
            legend: {
              display: campaignLevel
            },
            tooltip: {
              // Append "%" symbol to values in tooltip
              callbacks: props.yInPercentage 
                ? {
                  label: function (this, tooltipItem) {
                    return tooltipItem.dataset.label + ": " + tooltipItem.formattedValue + "%";
                  }
                }
                : {}
            },
            // Show the labels besides the datapoints
            datalabels: {
              anchor: 'end',
              align: 'right',
              color: 'black',
              // hide "0" labels
              formatter: function(value) {
                // If props.yInPercentage, append % symbol to the label
                const percentSymbol = props.yInPercentage ? "%" : "";
                return value === 0 ? "" : value + percentSymbol;
              }
            }
          },
          scales: props.yInPercentage 
            ? {
              y: {
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 10,
                  callback: function (value) {
                    return value + " %";
                  }
                },
              }
            }
            : {
              y: {
                min: 0,
              }
            },
          responsive: true,
          animation: {
            onComplete: function () {
              htmlExport = document.createElement('a');
              htmlExport.href = newChart.toBase64Image();
              htmlExport.download = props.chartName + ".png";
            },
          },
          layout: {
            // Without this, the last datalabels (of the last datapoints, on the right) are cut off from the chart
            padding: {
              right: 50,
            }
          },
        }
      });
    }
  };

  return (
    <>
      <div className={classes.exportChartButton}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => htmlExport.click()}
        >
          Export Chart
        </Button>
      </div>
      <div>
        <canvas id={props.chartName} ref={canvasCallback}></canvas>
      </div>
    </>
  );
};
