/**
 * @file validationSchema.ts Yup validation schema for Brands
 * @author Harry Rhodes
 * @exports Yup.object
 */
import * as Yup from "yup";
import { yupForAlias, yupForPaiContingent, yupTrimmedString } from "../../../utils/validationUtils";

/* eslint-disable no-template-curly-in-string */

export const validationSchema = Yup.object().shape({
  name: yupTrimmedString("Name")
    .min(2, "Name cannot be shorter than ${min} characters")
    .max(100, "Name cannot be longer than ${max} characters")
    .required("Required"),
  desc: yupTrimmedString("Description")
    .max(500, "Description cannot be longer than ${max} characters")
    .required("Required"),
  alias: yupForAlias,
  pai_contingent: yupForPaiContingent,
});
