/**
 * @file Partners.tsx Partners page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState } from "react";
import { Button, Grid, Paper } from "@mui/material";
import { useQuery } from "react-query";
import partnerService, { PartnerType } from "../services/partnerService";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import OnLoadWithNav from "../components/common/templates/WithNav/OnLoadWithNav";
import OnErrorWithNav from "../components/common/templates/WithNav/OnErrorWithNav";
import useStyles from "../components/common/templates/style";
import { useUserContext } from "../context/UserContext";
import { useMutation, useQueryClient } from "react-query";
import AlertSnackback from "../components/common/templates/feedback/AlertSnackbar";
import { invalidatePartnerQueries } from "../utils/invalidateQueries";
import PartnersTable from "../components/Partners/PartnersTable";
import AddPartner from "../components/Partners/AddPartner";

/**
 * Props
 * @typedef {{opcoId: string}} Props
 */
interface Props {
  opcoId?: string;
}
/**
 * Renders Partners page
 * @returns {React.Component} Partners page
 */
export default function Partners(props: Props) {
  const classes = useStyles();
  const { user } = useUserContext();
  const { role } = user;
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [openAddPartner, setOpenAddPartner] = useState(false);

  const {
    data: partners,
    isLoading: loadingPartners,
    error: partnersError,
  } = useQuery<PartnerType[]>(props?.opcoId ? ["assignedPartners", props.opcoId] : ["partners"], () =>
    partnerService.getAll(props?.opcoId)
  );

  const queryClient = useQueryClient();

  const { mutateAsync: createPartner } = useMutation(partnerService.create, {
    onSuccess: () => {
      setOpenAddPartner(false);
      setMsg("Partner created!");
      setSuccess(true);
      invalidatePartnerQueries(queryClient);
    }
  });

  if (loadingPartners) return <OnLoadWithNav />;
  if (partnersError) return <OnErrorWithNav error={partnersError} />;

  return (
    <WithNav>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>Partners</Title>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {role === "vodafone-admin" && (
              <div className={classes.buttons}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenAddPartner(true)}
                >
                  Add Partner
                </Button>
              </div>
            )}
            <PartnersTable
              data={partners!}
              setSuccessMessage={(msg) => {
                setMsg(msg);
                setSuccess(true);
              }}
              setErrorMessage={(msg) => {
                setMsg(msg);
                setError(true);
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      {role === "vodafone-admin" && ( 
        <AddPartner 
          createPartner={createPartner}
          open={openAddPartner}
          setOpen={setOpenAddPartner}
          setErrorMsg={(msg) => {
            setMsg(msg);
            setError(true); 
          }}
        />
      )}
      <AlertSnackback
        message={msg}
        type="success"
        open={success}
        setOpen={setSuccess}
      />
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </WithNav>
  );
}
