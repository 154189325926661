import { Fragment, useState } from "react";
import { Button, Avatar, Typography, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import FieldFW from "../../common/templates/forms/FieldFW";
import AlertSnackback from "../../common/templates/feedback/AlertSnackbar";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { resetPasswordSchema } from "./validationSchema";
import useStyles from "../styles";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { credentialsStorage } from "../../../utils/credentialStorage";
import { extractErrorMessage } from "../../../utils/utils";

interface NewPasswordType {
  code: string;
  password: string;
  confirmPassword: string;
}

interface Props {
  username: string;
  verify: boolean;
}
export default function ResetPasswordForm(props: Props) {
  const classes = useStyles();
  const { username, verify } = props;
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  let navigate = useNavigate();

  const path = "/signin";
  const routeChange = () => {
    navigate(path);
  };
  const routeChangeAfterPasswordReset = () => {
    navigate(path, {
      state: {
        msg: "Password has been successfully reset."
      },
    });
  };

  if (username === null || verify === null) {
    return (
      <Fragment>
        <div>
          <Avatar className={classes.errorAvatar}>
            <ErrorOutlineIcon />
          </Avatar>
          <Typography component="h1" variant="h3" color="primary">
            Error
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h5" gutterBottom>
              Something went wrong. Please try again
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => routeChange()}
            >
              Return to SignIn
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div>
          <Avatar className={classes.avatar}>
            <LockOpenOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create New Password
          </Typography>
        </div>
        <Formik
          validateOnChange={true}
          initialValues={{
            code: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={resetPasswordSchema}
          onSubmit={async (data: NewPasswordType, { setSubmitting }) => {
            setSubmitting(true);
            try {
              await Auth.forgotPasswordSubmit(username, data.code, data.password);
              credentialsStorage.removeItem("username");
              routeChangeAfterPasswordReset();
            } catch (err: any) {
              setMsg(extractErrorMessage(err));
              setError(true);
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <Grid container spacing={2}>
                {verify === true && (
                  <Grid item xs={12}>
                    <Typography>
                      A verification code has just been sent to the email
                      address associated with your account. Please enter it
                      below.
                    </Typography>
                    <FieldFW
                      type="text"
                      required
                      id="code"
                      name="code"
                      placeholder="Verification Code"
                      variant="outlined"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography>Enter a new password</Typography>
                  <FieldFW
                    type="password"
                    required
                    id="password"
                    name="password"
                    placeholder="Password"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldFW
                    type="password"
                    required
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => submitForm()}
                    disabled={isSubmitting}
                  >
                    Confirm
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={() => routeChange()}
                    disabled={isSubmitting}
                  >
                    Return to SignIn
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <AlertSnackback
          message={msg}
          type="error"
          open={error}
          setOpen={setError}
        />
      </Fragment>
    );
  }
}
