/**
 * @file styles.ts
 * @description Defines CSS styles for Navigation component
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: "100%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperPermanent: {
    padding: "0 8px",
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    minHeight: "100vh",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#ffffff",
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
}));
export default useStyles;
