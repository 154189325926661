/**
 * @file AddBrand.tsx AddBrand component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState } from "react";
import { BrandCreateType } from "../../../services/brandService";
import BrandInfo from "./BrandInfo";
import UserInfo, { UserInfoType } from "../../common/onboarding/UserInfo";
import Review from "./Review";
import partnerService, { PartnerType } from "../../../services/partnerService";
import { useQuery } from "react-query";
import StepperWrapper, {
  StepperContentType,
} from "../../common/onboarding/StepperWrapper";
import OnLoad from "../../common/templates/feedback/OnLoad";
import OnError from "../../common/templates/feedback/OnError";
import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { Transition } from "../../common/Transition";

interface Props {
  partnerId: string;
  createBrand: (brand: FormData) => Promise<any>;
  open: boolean;
  setOpen: (state: boolean) => void;
  setErrorMsg: (s: string) => void;
}

/**
 * Renders AddBrand component
 * @param props component props @see Props
 * @returns {React.Component} AddBrand component
 */
export default function AddBrand(props: Props) {
  const classes = useStyles();
  const { partnerId, createBrand, open, setOpen, setErrorMsg } = props;
  const [brand, setBrand] = useState<BrandCreateType>();
  const [target, setTarget] = useState<File>();
  const [user, setUser] = useState<UserInfoType>();

  const {
    data: partner,
    isLoading: loadingPartner,
    error: partnerError,
  } = useQuery<PartnerType>(["partner", partnerId], () =>
    partnerService.getSingle(partnerId)
  );

  const stepperData: StepperContentType[] = [
    {
      id: 1,
      name: "Brand Info",
      content: (
        <BrandInfo
          partnerId={partnerId}
          setBrand={setBrand}
          setTarget={setTarget}
        />
      ),
      optional: false,
    },
    {
      id: 2,
      name: "Assign Brand Manager",
      content: (
        <UserInfo
          setUser={setUser}
          userState={{ partnerAlias: partner?.alias as string }}
        />
      ),
      optional: false,
    },
    {
      id: 3,
      name: "Review",
      content: (
        <Review
          brand={brand as BrandCreateType}
          user={user as UserInfoType}
          target={target as File}
          createBrand={createBrand}
          setErrorMsg={setErrorMsg}
        />
      ),
      optional: false,
    },
  ];

  if (loadingPartner) return <OnLoad />;
  if (partnerError) return <OnError error={partnerError} />;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="secondary"
            onClick={() => setOpen(false)}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            color="secondary"
            className={classes.title}
          >
            Brand Designer
          </Typography>
        </Toolbar>
      </AppBar>
      <StepperWrapper data={stepperData} />
    </Dialog>
  );
}
