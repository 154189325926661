/**
 * @file validationSchema.ts Yup validation schema for a From Number
 * @author Rafael Mello
 * @exports Yup.object
 */
import * as Yup from "yup";
import {
  fromMinLength,
  fromMaxLength,
} from "../../../utils/msisdnUtils";

/* eslint-disable no-template-curly-in-string */

export const validationSchema = Yup.object().shape({
  from: Yup.string()
    .matches(/^\+[1-9][0-9]*$/, "From must be in format +1234567890")
    .min(fromMinLength, "From must be at least ${min} characters in length")
    .max(fromMaxLength, "From cannot be longer than ${max} characters")
    .required()
});
