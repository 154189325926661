/**
 * @file validationSchema.ts Form input validation for the Add User form
 * @author Rafael Mello
 * @exports validationSchema
 */
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  role: Yup
    .string()
    .required("Please select a role"),
  email: Yup
    .string()
    .email("Must be a valid email address")
    .required("Please insert an email")
});