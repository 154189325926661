/**
 * @file validationSchema.ts
 * @description Yup validation schema for UserInfo
 * @author Harry Rhodes
 * @exports Yup.object
 */
import * as Yup from "yup";

/* eslint-disable no-template-curly-in-string */

export const validationSchema = Yup.object().shape({
  new_user: Yup.string()
    .test(
      "See if an existing user exists",
      "A new or existing user must be specified",
      (value: any, ctx: Yup.TestContext) => {
        if (!value) {
          let user = ctx.parent;
          if (user.existing_user) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    )
    .test(
      "See if an existing user exists at the same time as a new user",
      "Only a new or existing user can be specified not both.",
      (value: any, ctx: Yup.TestContext) => {
        if (value) {
          let user = ctx.parent;
          if (user.existing_user) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    )
    .email("Must be a valid email address")
    .min(5, "Email address cannot be shorter than ${min} characters")
    .max(100, "Email address cannot be longer than ${max} characters"),
  existing_user: Yup.string()
    .nullable()
    .test(
      "See if a new user exists",
      "A new or existing user must be specified",
      (value: any, ctx: Yup.TestContext) => {
        if (!value) {
          let values = ctx.parent;
          if (values.new_user) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    )
    .test(
      "See if a new user exists at the same time as an existing user",
      "Only a new or existing user can be specified not both.",
      (value: any, ctx: Yup.TestContext) => {
        if (value) {
          let values = ctx.parent;
          if (values.new_user) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    ),
});
