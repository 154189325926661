import { SvgIcon, Grid, Box } from "@mui/material";
import useStyles from "../styles";

interface Props {
    svg: React.FunctionComponent;
    title: string;
    text: string;
  }

export default function SvgContent(props: Props) {
  const classes = useStyles();
  return (
    <Grid className={classes.svgContainer} container wrap="nowrap" direction="row" columnSpacing={1} alignItems="top">
      <Grid item>
        <SvgIcon className={classes.svgIcon}><props.svg /></SvgIcon> 
      </Grid>
      <Grid fontSize={15} item>
        <Box className={classes.svgContentTitle}>{props.title}</Box>
        <Box>{props.text}</Box>
      </Grid>
    </Grid>
  );
}