/**
 * @file styles.ts
 * @description Defines CSS styles for Review
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: 30,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  logo: {
    borderRadius: "50%",
    maxHeight: "200px",
  },
}));
export default useStyles;
