import { Fragment, useState } from "react";
import { Button, Avatar, Typography, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import FieldFW from "../../common/templates/forms/FieldFW";
import AlertSnackback from "../../common/templates/feedback/AlertSnackbar";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { changePasswordSchema } from "./validationSchema";
import useStyles from "../styles";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { extractErrorMessage } from "../../../utils/utils";

interface NewPasswordType {
  code: string;
  password: string;
  confirmPassword: string;
}

interface Props {
  setActiveStep: (step: number) => void;
  user?: CognitoUser;
}
export default function NewPasswordForm(props: Props) {
  const classes = useStyles();
  const { setActiveStep, user } = props;
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");

  return (
    <Fragment>
      <div>
        <Avatar className={classes.avatar}>
          <LockOpenOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create New Password
        </Typography>
      </div>
      <Formik
        validateOnChange={true}
        initialValues={{
          code: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={changePasswordSchema}
        onSubmit={async (data: NewPasswordType, { setSubmitting }) => {
          setSubmitting(true);
          try {
            const userResponse = await Auth.completeNewPassword(user, data.password);
            if (userResponse.challengeName === "MFA_SETUP") {
              setActiveStep(4);
            } else {
              window.location.reload();
            }
          } catch (err: any) {
            setMsg(extractErrorMessage(err));
            setError(true);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Enter a new password</Typography>
                <FieldFW
                  type="password"
                  required
                  id="password"
                  name="password"
                  placeholder="Password"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FieldFW
                  type="password"
                  required
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => submitForm()}
                  disabled={isSubmitting}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </Fragment>
  );
}
