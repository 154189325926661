/**
 * @file VFAdminRoutes.tsx
 * @description Defines routes for VF-Admin role.
 * @requries react-router-dom
 * @author Harry Rhodes
 * @exports BrowserRouter
 */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../../../../../pages/Dashboard";
import Aggregators from "../../../../../pages/Aggregators";
import Aggregator from "../../../../../pages/Aggregator";
import Endpoints from "../../../../../pages/Endpoints";
import OPCOs from "../../../../../pages/OPCOs";
import OPCO from "../../../../../pages/OPCO";
import Partners from "../../../../../pages/Partners";
import Partner from "../../../../../pages/Partner";
import Brand from "../../../../../pages/Brand";
import Assets from "../../../../../pages/Assets";
import NotFound from "../../../../../pages/error/NotFound";
import Campaign from "../../../../../pages/Campaign";
import Users from "../../../../../pages/Users";
import User from "../../../../../pages/User";
import Reports from "../../../../../pages/Reports";
import { isAggregatorsFeatureEnabled, isAssetsFeatureEnabled } from "../../../../../utils/featureToggle";
import ChartDesigner from "../../../../../pages/ChartDesigner";
import BrandDashboardWithNav from "../../../../../pages/BrandDashboardWithNav";

/**
 * Renders VFAdminRoutes
 * @param none
 * @returns {React.Component} VFAdminRoutes
 */
export default function VFAdminRoutes() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path={"/opcos"} element={<OPCOs />} />
        <Route path={"/opcos/:opcoId"} element={<OPCO />} />
        <Route path={"/opcos/:opcoId/partners"} element={<Partners />} />
        <Route path={"/opcos/:opcoId/partners/:partnerId"} element={<Partner />} />
        <Route path={"/opcos/:opcoId/partners/:partnerId/users"} element={<Users />} />
        <Route path={"/opcos/:opcoId/partners/:partnerId/users/:username"} element={<User />} />
        <Route path={"/opcos/:opcoId/partners/:partnerId/brands/:brandId"} element={<Brand />} />
        <Route path={ "/opcos/:opcoId/partners/:partnerId/brands/:brandId/campaigns/:campaignId"} element={<Campaign />} />
        <Route path={"/opcos/:opcoId/partners/:partnerId/brands/:brandId/users"} element={<Users />} />
        <Route path={"/opcos/:opcoId/partners/:partnerId/brands/:brandId/users/:username"} element={<User />} />
        <Route path={"/partners"} element={<Partners />} />
        <Route path={"/partners/:partnerId"} element={<Partner />} />
        <Route path={"/partners/:partnerId/brands/:brandId"} element={<Brand />} />
        <Route path={"/partners/:partnerId/brands/:brandId/campaigns/:campaignId"} element={<Campaign />} />
        <Route path={"/users"} element={<Users />} />
        <Route path={"/opcos/:opcoId/users"} element={<Users />} />
        <Route path={"/partners/:partnerId/users"} element={<Users />} />
        <Route path={"/partners/:partnerId/brands/:brandId/users"} element={<Users />} />
        <Route path={"/users/:username"} element={<User />} />
        <Route path={"/opcos/:opcoId/users/:username"} element={<User />} />
        <Route path={"/partners/:partnersId/users/:username"} element={<User />} />
        <Route path={"/partners/:partnerId/brands/:brandId/users/:username"} element={<User />} />
        <Route path={"/partners/:partnerId/brands/:brandId/dashboard"} element={<BrandDashboardWithNav />} />
        <Route path={"/partners/:partnerId/brands/:brandId/chartDesigner"} element={<ChartDesigner />} />
        <Route path={"/reports"} element={<Reports />} />
        {isAggregatorsFeatureEnabled() &&
          <>
            <Route path={"/opcos/:opcoId/aggregators"} element={<Aggregators />} />
            <Route path={"/opcos/:opcoId/aggregators/onboard"} element={<Aggregators clicked={true} />} />
            <Route path={"/opcos/:opcoId/aggregators/:aggregatorId"} element={<Aggregator />} />
            <Route path={"/opcos/:opcoId/aggregators/:aggregatorId/endpoints"} element={<Endpoints />} />
            <Route path={"/aggregators"} element={<Aggregators />} />
            <Route path={"/aggregators/onboard"} element={<Aggregators clicked={true} />} />
            <Route path={"/aggregators/:aggregatorId"} element={<Aggregator />} />
            <Route path={"/aggregators/:aggregatorId/endpoints"} element={<Endpoints />} />
          </>
        }
        {isAssetsFeatureEnabled() &&
          <>
            <Route path={"/opcos/:opcoId/partners/:partnerId/brands/:brandId/assets"} element={<Assets />} />
            <Route path={"/partners/:partnerId/brands/:brandId/assets"} element={<Assets />} />
          </>
        }
        <Route path="*" element={<NotFound authenticated={true} />} />
      </Routes>
    </BrowserRouter>
  );
}
