/**
 * @file styles.ts
 * @description Defines CSS styles for CampaignDesigner
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: "relative",
    background: "#ffffff",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
export default useStyles;
