/**
 * @file BrandManagerRoutes.tsx
 * @description Defines routes for Brand Manager role.
 * @requries react-router-dom
 * @author Harry Rhodes
 * @exports BrowserRouter
 */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../../../../../pages/Dashboard";
import Brand from "../../../../../pages/Brand";
import Assets from "../../../../../pages/Assets";
import Campaign from "../../../../../pages/Campaign";
import NotFound from "../../../../../pages/error/NotFound";
import Users from "../../../../../pages/Users";
import User from "../../../../../pages/User";
import Reports from "../../../../../pages/Reports";
import { isAssetsFeatureEnabled } from "../../../../../utils/featureToggle";
import ChartDesigner from "../../../../../pages/ChartDesigner";

/**
 * Props
 * @typedef {{partnerId: string, brandId: string}} Props
 */
interface Props {
  partnerId: string;
  brandId: string;
}
/**
 * Renders BrandManagerRoutes
 * @param props component props @see Props
 * @returns {React.Component} BrandManagerRoutes
 */
export default function BrandManagerRoutes(props: Props) {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Dashboard brandId={props.brandId} />} />
        {/* Brand Info Routes */}
        <Route path="/brandInfo" element={<Brand brandId={props.brandId} />} />
        <Route path={"/brandInfo/campaigns/:campaignId"} element={<Campaign brandId={props.brandId} />} />
        {/* Nominal Routes */}
        <Route path={"/campaigns/:campaignId"} element={<Campaign brandId={props.brandId} />} />
        <Route path={"/users"} element={<Users brandId={props.brandId} />} />
        <Route path={"/users/:username"} element={<User />} />
        <Route path={"/reports"} element={<Reports brandId={props.brandId} />} />
        <Route path={"/chartDesigner"} element={<ChartDesigner />} />
        {isAssetsFeatureEnabled() &&
          <>
            <Route path={"/brandInfo/assets"} element={<Assets brandId={props.brandId} />} />
            <Route path={"/assets"} element={<Assets brandId={props.brandId} />} />
          </>
        }
        <Route path="*" element={<NotFound authenticated={true} />} />
      </Routes>
    </BrowserRouter>
  );
}
