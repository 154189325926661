/**
 * @file ProfileMenu.tsx
 * @description ProfileMenu component
 * @author Harry Rhodes
 * @exports React.Component
 */
import {
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Theme,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import makeStyles from "@mui/styles/makeStyles";
import { useUserContext } from "../../../../../context/UserContext";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));
/**
 * Props
 * @typedef {{open: boolean}} Props
 */
interface Props {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  openSwitchRole: () => void;
}
/**
 * Renders ProfileMenu component
 * @param props component props @see Props
 * @returns {React.Component} ProfileMenu component
 */
export default function ProfileMenu(props: Props) {
  const classes = useStyles();
  const { user } = useUserContext();
  const open = Boolean(props.anchorEl);

  /**
   * Handle sign out operation
   */
  async function handleSignOut() {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  return (
    <Popover
      id="profile-menu"
      open={open}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <List
        component="nav"
        className={classes.root}
        aria-label="contacts"
        subheader={
          <ListSubheader component="div" color="primary">
            Account Info
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={user?.email} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <WorkIcon />
          </ListItemIcon>
          <ListItemText primary={"Role: " + user?.role} />
        </ListItem>
        <ListItem button onClick={props.openSwitchRole}>
          <ListItemIcon>
            <AutorenewIcon />
          </ListItemIcon>
          <ListItemText primary="Switch Role" />
        </ListItem>
        <ListItem button onClick={handleSignOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
      </List>
    </Popover>
  );
}
