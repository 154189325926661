/**
 * @file StepperWrapper.tsx
 * @description StepperWrapper component
 * @author Harry Rhodes
 * @exports React.Component
 */
import React, { useState, useRef, ReactElement } from "react";
import {
  Stepper,
  StepContent,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import StepActions from "../StepActions";
import { FormikValues } from "formik";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStyles from "./styles";

interface Props {
  data: StepperContentType[];
}

/**
 * Renders StepperWrapper component
 * @param props component props @see Props
 * @returns {React.Component} StepperWrapper component
 */

export interface StepperContentType {
  id: number;
  name: string;
  content: ReactElement;
  optional: boolean;
}

export default function StepperWrapper(props: Props) {
  const theme = useTheme();
  const classes = useStyles();
  const { data } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const formRef = useRef<FormikValues>();
  const breakpoint = useMediaQuery(theme.breakpoints.down("sm"));

  // const formikContext = useFormikContext();
  const handleSubmit = () => {
    formRef.current?.handleSubmit();
  };

  const isStepOptional = (step: number) => {
    return data[step].optional;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  interface StepProps {
    completed?: boolean;
  }
  interface LabelProps {
    optional?: React.ReactNode;
  }

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        orientation={breakpoint ? "vertical" : "horizontal"}
        className={classes.stepper}
      >
        {data.map((step, index) => {
          const stepProps: StepProps = {};
          const labelProps: LabelProps = {};
          if (step.optional === true) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={step.id} {...stepProps}>
              <StepLabel {...labelProps}>{step.name}</StepLabel>
              {breakpoint && (
                <StepContent>
                  {React.cloneElement(step.content, {
                    handleNext: handleNext,
                    innerRef: formRef,
                  })}
                </StepContent>
              )}
            </Step>
          );
        })}
      </Stepper>

      {activeStep === data.length ? (
        <div className={classes.containerCenter}>
          <div> </div>
        </div>
      ) : (
        !breakpoint && (
          <div className={classes.container}>
            <div>
              {React.cloneElement(data[activeStep].content, {
                handleNext: handleNext,
                innerRef: formRef,
              })}
            </div>
          </div>
        )
      )}
      <div className={classes.controls}>
        <StepActions
          handleBack={handleBack}
          handleSkip={handleSkip}
          handleSubmit={handleSubmit}
          length={data.length}
          activeStep={activeStep}
          optional={data[activeStep].optional}
        />
      </div>
    </div>
  );
}
