/**
 * @file styles.ts Defines CSS styles for SignIn Page
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import backgroundImg from "../SignIn/Assets/background.jpg";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  wrapper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  stepper: {
    padding: "0px",
  },
  paper: {
    padding: theme.spacing(4, 4, 2, 4),
    margin: theme.spacing(0, 0),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    boxShadow: "0px 2.5px 5px 0px #949698",
    minHeight: "50vh",
  },
  avatar: {
    margin: theme.spacing(1, 1, 1, 0),
    backgroundColor: theme.palette.secondary.main,
  },
  errorAvatar: {
    margin: theme.spacing(1, 1, 1, 0),
    backgroundColor: theme.palette.primary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default useStyles;
