/**
 * @file Subtitle.tsx
 * @description Subtitle component
 * @author Harry Rhodes
 * @exports React.Component
 */
import Typography from "@mui/material/Typography";

/**
 * @typedef Props
 * @property {string} children - Text for title
 **/
interface Props {
  children: string;
  primary?: boolean;
}
/**
 * Renders Subtitle component
 * @param props Custom props @see Props
 * @returns {React.Component} Title component
 */
const Subtitle = (props: Props) => (
  <Typography
    color={props.primary ? "primary" : "secondary"}
    gutterBottom
    variant="h6"
  >
    {props.children}
  </Typography>
);

export default Subtitle;
