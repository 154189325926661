/**
 * @file catalogService.ts Interface/client for interacting with local /catalogs API.
 * @requries ./axiosClient
 * @author Harry Rhodes
 */

import { client } from "./axiosClient";

/**
 * @typedef CatalogType
 * @property {string} id - id of object to change (either OPCO or MSISDN)
 * @property {string} type - Type of catalog to query
 * @property {string[]} associated_ids - List of ids to change

 */
export type CatalogType = {
  id: string;
  type: string;
  associated_ids: string[];
};

const service = {
  update: async (body: CatalogType) => {
    let res = await client.put("/catalogs/" + body.id, body);
    return res.data || [];
  },
  delete: async (body: CatalogType) => {
    let res = await client.delete("/catalogs/" + body.id, { data: body });
    return res.data || [];
  },
};

export default service;
