/**
 * @file Logo.tsx Logo component
 * @author Harry Rhodes
 * @exports React.Component
 */
import file from "../../../assets/images/vf-logo.png";
import useStyles from "./styles";
/**
 * Renders Logo component from HTML wrapper
 * @returns {React.Component} Logo component
 */
export default function Logo() {
  const classes = useStyles();
  return <img src={file} className={classes.logo} alt="Vf-Logo" />;
}
