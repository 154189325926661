/**
 * @file styles.ts
 * @description Defines CSS styles for LogoCropper component
 * @author Jay Patel
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "250px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      width: "300px",
    },
    [theme.breakpoints.between("md", "xl")]: {
      width: "500px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "500px",
    },
  },
  formContainer: {
    width: "100%",
  },
  cropContainer: {
    position: "relative",
    width: "100%",
    height: "200px",
    background: "#333",
    [theme.breakpoints.up("sm")]: {
      height: "300px",
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  sliderContainer: {
    display: "flex",
    flex: "1",
    alignItems: "center",
  },
  sliderLabel: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: "22px 0px",
    marginLeft: 16,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      margin: "0 16px",
    },
  },
}));

export default useStyles;
