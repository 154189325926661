/**
 * @file validationSchema.ts Yup validation schema for MSISDNs in Bulk Upload
 * @author Rafael Mello
 * @exports Yup.object
 */
import { createYupNumberSchema } from "../../../utils/validationUtils";

export const validationSchema = createYupNumberSchema(
  getErrorMessage("PAI"),
  getErrorMessage("From"),
);

function getErrorMessage(field: string) {
  return "Is the " + field + " number a prefix? If yes, please append a * to its value, otherwise the number is too short";
}
