/**
 * @file styles.ts
 * @description Defines CSS styles for WithNav wrapper component
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme: Theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    //backgroundColor: theme.palette.secondary.light,
  },
  location: {
    paddingLeft: "20px",
    paddingTop: "20px",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default useStyles;

// content, appbarspacer, container
