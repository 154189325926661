/**
 * @file CampaignReview.tsx CampaignReview component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { RefObject } from "react";
import { List, ListItem, ListItemText, Grid, Typography } from "@mui/material";
import { CampaignInfoType } from "../CampaignDesigner";
import {
  AntiSpoofingType,
  ApprovalStatus,
  AssignedMSISDNsType,
  CampaignType,
} from "../../../../services/campaignService";
import Title from "../../../common/titles/Title";
import { Formik } from "formik";
import useStyles from "./styles";
import { transformMsisdnForDisplaying } from "../../../../utils/msisdnUtils";
import { extractErrorMessage } from "../../../../utils/utils";

interface Props {
  brandLogo: string;
  campaignInfo: CampaignInfoType;
  campaignMSISDNs: AssignedMSISDNsType;
  saveCampaign: (c: CampaignType) => Promise<any>;
  featureAntiSpoofingEnabled: boolean;
  handleNext?: () => void;
  innerRef?: RefObject<any>;
  setErrorMsg: (s: string) => void;
}

/**
 * Component for the last step of campaign creation. 
 * Shows a summary of the entered details and assigned MSISDNs and lets you save the campaign.
 */
export default function CampaignReview(props: Props) {

  const classes = useStyles();
  const {
    campaignInfo,
    campaignMSISDNs,
    saveCampaign,
    setErrorMsg
  } = props;

  return (
    <div className={classes.container}>
      <Formik
        innerRef={props.innerRef}
        initialValues={{ null: null }}
        onSubmit={() => {
          let campaign: CampaignType = {
            ...campaignInfo,
            approval_status: ApprovalStatus.APPROVED,
          };
          if (campaignMSISDNs.msisdns) {
            campaign.msisdn_ids = campaignMSISDNs.msisdns.map(
              (v) => v.id
            ) as string[];
          }
          saveCampaign(campaign).catch((err: any) => {
            setErrorMsg(extractErrorMessage(err));
          });
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Title>Review Campaign</Title>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
            Campaign Details
          </Typography>
          <div>
            <List>
              <ListItem>
                <ListItemText
                  primary={campaignInfo.name}
                  secondary={"Name"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={campaignInfo.display_name}
                  secondary={"Display Name"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={campaignInfo.desc}
                  secondary={"Description"}
                />
              </ListItem>
              {props.featureAntiSpoofingEnabled == true && 
                <ListItem>
                  <ListItemText
                    primary={campaignInfo.anti_spoofing === AntiSpoofingType.DISABLED 
                      ? "Disabled" 
                      : AntiSpoofingType[campaignInfo.anti_spoofing as keyof typeof AntiSpoofingType]
                    }
                    secondary={"Anti-Spoofing"}
                  />
                </ListItem>
              }
              <ListItem>
                <ListItemText
                  primary={campaignInfo.reference_campaign ? "Yes" : "No"}
                  secondary={"Reference Campaign"}
                />
              </ListItem>
            </List>
          </div>
          <Typography variant="h6" className={classes.title}>
            Assigned Numbers
          </Typography>
          <div>
            <List>
              {campaignMSISDNs.msisdns.length > 0 ? (
                campaignMSISDNs.msisdns.map((v) => (
                  <ListItem key={v.id}>
                    <ListItemText primary={transformMsisdnForDisplaying(v)} />
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary={"None"} />
                </ListItem>
              )}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
