/**
 * @file styles.ts Defines CSS styles for OPCOsTable component
 * @author Rafael Mello
 */
import { SxProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const sxProps: Record<string, SxProps<Theme> | undefined> = {
  datagridToolbar: {
    '& .MuiDataGrid-overlayWrapper': {
      height: "3em" // for when no results are found after filtering
    },
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  datagrid: {
    marginTop: theme.spacing(1)
  },
}));

export default useStyles;