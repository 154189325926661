/**
 * @file LogoCropper.tsx
 * @description LogoCropper component
 * @author Jay Patel, Harry Rhodes
 * @exports React.Component
 */
import { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import { Area } from "react-easy-crop/types";
import { FormControl, Slider, Typography } from "@mui/material";
import useStyles from "./styles";
import Dropzone, { FileInputType } from "../../Assets/Dropzone";

/**
 * Props,
 * @typedef {{setTarget: callback}} Props
 */
interface Props {
  setTarget: (file: File) => void;
  setHasSelectedImage?: (hasSelectedImage: boolean) => void;
}

export default function LogoCropper(props: Props) {
  const [files, setFiles] = useState<FileInputType[]>();
  const [src, setSrc] = useState<string>();
  const [zoom, setZoom] = useState<number | number[]>(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState({ width: 300, height: 300 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  const classes = useStyles();

  const formats = ["image/jpeg", "image/png"];
  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
      if (!croppedAreaPixels) {
        return;
      }

      try {
        if (src) {
          let croppedImg = new Image();
          croppedImg.src = src;
          const canvas = document.createElement("canvas");
          canvas.width = 300;
          canvas.height = 300;
          const ctx = canvas.getContext("2d");
          ctx?.drawImage(
            croppedImg,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            300,
            300
          );
          canvas.toBlob((blob) => {
            const file = new File([blob as Blob], "cropped.jpg", {
              type: "image/jpeg",
            });
            props.setTarget(file);
            if (props.setHasSelectedImage) {
              props.setHasSelectedImage(true);
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, files]
  );

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 600) {
        setCropSize({ width: 200, height: 200 });
      } else {
        setCropSize({ width: 300, height: 300 });
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (files) {
      setSrc(files[0].src);
    }
  }, [files]);

  return (
    <div className={classes.mainContainer}>
      {src ? (
        <FormControl className={classes.formContainer}>
          <div className={classes.cropContainer}>
            <Cropper
              image={src}
              crop={crop}
              cropSize={cropSize}
              zoom={zoom as number}
              aspect={1}
              cropShape="round"
              restrictPosition={false}
              showGrid={false}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.controls}>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.025}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
          </div>
        </FormControl>
      ) : (
        <Dropzone
          limit={1}
          formats={formats}
          minHeight={300}
          minWidth={300}
          setFiles={setFiles}
        />
      )}
    </div>
  );
}
