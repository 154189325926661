/**
 * @file RadioSelect.tsx a custom RadioSelect component based on a Material UI components with Formik
 * @author Harry Rhodes
 * @exports React.Component
 */
import React from "react";
import { FieldAttributes, useField } from "formik";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
/**
 * Option
 * @typedef {{label: string, value: string|number}} Props
 */
type Option = {
  label: string;
  value: string | number;
};
/**
 * Props
 * @typedef {{options: Option[], disabled: boolean, FieldAttributes: FormikProps}} Props
 */
type Props = {
  options: Option[];
  disabled?: boolean;
  row?: boolean; // If true, display group of elements in a compact row
} & FieldAttributes<{}>;
/**
 * Renders RadioSelect component
 * @param props Custom props & Formik field attributes @see Formik @see Props
 * @returns {React.Component} RadioSelect component
 */
const RadioSelect: React.FC<Props> = ({ options, ...props }) => {
  const [field, meta] = useField<{}>(props);
  return (
    <RadioGroup row={props.row} aria-label={field.name} {...field}>
      {options.map((option) => (
        <FormControlLabel
          key={option.label}
          label={option.label}
          value={option.value}
          disabled={props.disabled}
          control={<Radio />}
        />
      ))}
    </RadioGroup>
  );
};
export default RadioSelect;
